import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
  @Input() modalTitle: string = '';
  @Input() modalWidth: string = 'sm';
  @Input() modalIsDismissible: boolean = true;
  @Input() modalHasActions: boolean = true;
  @Input() modalHasBackState: boolean = false;
  @Input() modalSteps: string[] = [];
  @Input() modalActiveStep: number = 0;

  @Output() closeTriggered: EventEmitter<any> = new EventEmitter();
  @Output() backwardClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  get modalTotalSteps(): number {
    return this.modalSteps.length;
  }

  get modalHasSteps(): boolean {
    return this.modalTotalSteps ? true : false;
  }

  get modalActiveTitle(): string {
    if (this.modalActiveStep + 1 > this.modalTotalSteps) {
      return 'Please check modal active step index';
    }
    return this.modalSteps[this.modalActiveStep];
  }
}
