import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-link-sent',
  templateUrl: './reset-link-sent.component.html',
  styleUrls: ['./reset-link-sent.component.scss']
})
export class ResetLinkSentComponent implements OnInit {

  email: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.email = this.route.snapshot.queryParams.email;
  }

}
