import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { UserRole } from 'src/app/models/api/user';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { EventBusService } from 'src/app/services/events/event-bus.service';
import { AmplitudeTrackerService } from 'src/app/services/events/amplitude-tracker.service';
import { filter } from 'rxjs/operators';
import { MatTooltip } from '@angular/material/tooltip';


@Component({
  selector: 'app-base-topbar',
  templateUrl: './base-topbar.component.html',
  styleUrls: ['./base-topbar.component.scss'],
})
export class BaseTopbarComponent implements OnInit {
  @ViewChild('tooltip') tooltip: MatTooltip;

  isAuthenticated: boolean = false;
  showSidebar: boolean = false;
  showTopBarDropdown: boolean = false;
  showOverlay: boolean = false;
  showSearchBar: boolean = false;
  showGrayScaleEffect: boolean = false;

  currentRoute: string | null = null;
  showApplicationTooltip = false;

  currentScreenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  productOptions = [
    {
      title: 'STUDY',
      options: [
        {
          navTitle: 'Degree Programs',
          navDescription: 'Quickly get your visa etc',
          navLink: '',
          navIcon: 'graduation-cap',
        },
        {
          navTitle: 'Short Courses',
          navDescription: 'Quickly get your visa and bla bla bla bla etc',
          navLink: '',
          navIcon: 'short-courses',
        },
        {
          navTitle: 'Visa Application',
          navDescription: 'Quickly get your visa',
          navLink: '',
          navIcon: 'visa-assistance',
        },
        {
          navTitle: 'Scholarships',
          navDescription: 'Quickly get your visa',
          navLink: '',
          navIcon: 'scholarship',
        },
        {
          navTitle: 'Loans',
          navDescription: 'Quickly get your visa',
          navLink: '',
          navIcon: 'loans',
        },
      ],
    },
    {
      title: 'CAREER',
      options: [
        {
          navTitle: 'Trainings',
          navDescription:
            'Access trainings for free and bla bla etcAccess trainings for free and bla bla',
          navLink: '',
          navIcon: 'training',
        },
        {
          navTitle: 'Internships',
          navDescription: 'Gain work experience',
          navLink: '',
          navIcon: 'internship',
        },
        {
          navTitle: 'UKCG',
          navDescription: 'Apply to be a caregiver',
          navLink: '',
          navIcon: 'ukcg',
        },
      ],
    },
    {
      title: 'SETTLE-IN',
      options: [
        {
          navTitle: 'Canada Immigration Consultation',
          navDescription: 'Access information on migrating to Canada',
          navLink: '',
          navIcon: 'canada',
        },
        {
          navTitle: 'Accomodation',
          navDescription: 'Easily find accomodation around your school',
          navLink: '',
          navIcon: 'accomodation',
        },
      ],
    },
  ];

  partnerOptions = [
    {
      title: '',
      options: [
        {
          navTitle: 'Agents',
          navDescription:
            'Registered and unregistered business agents agents agents agents agents',
          navLink: '',
          navIcon: 'agent',
        },
        {
          navTitle: 'Secondary Schools',
          navDescription:
            'High schools within and outside Nigeria Nigeria Nigeria Nigeria Nigeria',
          navLink: '',
          navIcon: 'school',
        },
        {
          navTitle: 'Higher Institutions',
          navDescription: 'Higher instititions within and outside Nigeria',
          navLink: '',
          navIcon: 'school',
        },
      ],
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventBusService: EventBusService,
    private notificationService: NotificationService,
    private authService: AuthService,
    public amplitudeTrackerService: AmplitudeTrackerService
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe((urlSegments) => {
      this.currentRoute = urlSegments.join('/');
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;
        this.showSearchBar = false;
      });

    this.showGrayScaleEffect = this.currentRoute === 'school' ? true : false;

    this.eventBusService.subscribeToEvent().subscribe((data) => {
      if (data === 'show-search-dialog') this.showSearchBar = true;
    });

    this.notificationService.applicationAdded.subscribe((_) => {
      if (screen.width > 959) {
        this.showApplicationTooltip = true;
        setTimeout(() => this.tooltip.show(0), 100);
        setTimeout(() => (this.showApplicationTooltip = false), 6000);
      } else {
        this.notificationService.showSuccess(
          'You can access your shortlisted programs from the ≡ menu'
        );
      }
    });
  }

  get isMobileView() {
    return this.currentScreenWidth < 860 ? true : false;
  }

  toggleSidebar() {
    if (this.isMobileView) {
      this.showSidebar = !this.showSidebar;
    } else {
      this.showTopBarDropdown = !this.showTopBarDropdown;
    }
  }

  closeDropdownMenu() {
    this.showTopBarDropdown = false;
  }

  isLoggedIn() {
    return this.authService.isAuthenticated();
  }

  isAgent() {
    return (
      this.isLoggedIn() && this.authService.getUserRole() === UserRole.Agent
    );
  }

  getProfileName() {
    return this.authService.getUserFullName();
  }

  getProfileImage() {
    return this.authService.getUserImage();
  }

  getProfileEmail() {
    return this.authService.getUserEmail();
  }

  getPageUrl() {
    return new URL(document.URL).pathname;
  }

  searchActionClicked() {
    const isHomePage = this.getPageUrl() === '/';

    if (!isHomePage || this.isMobileView) {
      this.showSearchBar = true;
    } else {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.eventBusService.emitEvent('focus');
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.showOverlay = window.scrollY > 50;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.currentScreenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }
}
