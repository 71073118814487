import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  passwordFieldType = 'password';
  error = false;
  isLoading = false;
  confirmed = false;

  userId: string;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      password: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    this.userId = params.userId;
    this.token = params.token;

    this.authService.confirmResetToken(this.userId, this.token).subscribe(
      response => {
        console.log(response);
        this.confirmed = true;
      },
      error => {
        console.error(error);
        this.error = true;
      });
  }

  reset() {
    const newPassword = this.form.get('password').value;

    this.authService.resetPassword(this.userId, newPassword).subscribe(response => {
      console.log(response);
      this.router.navigate(['/','session','log-in']);
    });
  }

}
