import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent, HttpEventType } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { filter, last, map, mergeMap, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  uploadFile(file): Observable<any> {
    const fileName = file.name;

    return this.getPreSignedUrl('put', fileName)
      .pipe(
        mergeMap(response => this.uploadFileToS3(response.url, file)),
      );
  }

  uploadFileV2(file, tempId, userId?: string): Observable<any> {
    const fileName = file.name;

    return this.getPreSignedUrl('put', fileName)
      .pipe(
        mergeMap(response => this.uploadFileToS3V2(response.url, file, tempId, userId)),
      );
  }

  getPreSignedUrl(type, fileName, userId?: string): Observable<PreSignedUrlResponse> {
    const params = {
      userId: userId ?? this.authService.getUserId(),
      type,
      fileName
    };

    return this.http.get<PreSignedUrlResponse>(`${environment.userApiDomain}/v1/file/pre-signed-url`, { params });
  }

  uploadFileToS3(presignedUrl: string, file: File): Observable<string> {
    return this.http.put<any>(presignedUrl, file, { reportProgress: true, headers: {"Content-Type": file.type} })
      .pipe(
        mergeMap<Observable<any>,Observable<string>>(_ => of(presignedUrl.substring(0, presignedUrl.indexOf('?'))))
      );
  }

  uploadFileToS3V2(presignedUrl: string, file: File, tempId, userId?: string): Observable<any> {
    return this.http.put<any>(presignedUrl, file, { reportProgress: true, observe: 'events', headers: {"Content-Type": file.type} })
    .pipe(
      switchMap(event => {
        switch(event.type) {
          case HttpEventType.UploadProgress: return of({tempId, progress: Math.round(event.loaded / event.total * 100)}); 
          case HttpEventType.Response: return of({tempId, progress: 100, uri: presignedUrl.substring(0, presignedUrl.indexOf('?'))});
          default: return of(event);
        }
      })
    );
  }

  uploadBase64Image(uploadData) {
    return this.http.post<{url:string}>('https://2gohpw6go0.execute-api.eu-west-2.amazonaws.com/default/upload-base64-images', uploadData);
  }

  uploadUrlImage(url: string) {
    return this.http.get<{url:string}>(`https://pih6h4bvi4.execute-api.eu-west-2.amazonaws.com/default/upload-url-image?url=${url}`);
  }
}

export class PreSignedUrlResponse {
  url: string;
}

export class FileUploadModel {
  name: string;
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}
