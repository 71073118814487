<div
  class="band"
  ngClass.lt-md="mobile"
  ngClass.md="tablet"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <mat-card
      class="pave-card pave-card--lighter"
      ngClass.lt-md="pave-card--none"
      ngClass.gt-md="pave-card--fattest"
    >
      <mat-card-content fxLayout="column">
        <app-email-validation
          [code]="code"
          [email]="email"
          [userId]="userId"
        ></app-email-validation>
      </mat-card-content>
    </mat-card>
  </div>
</div>
