import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from 'src/app/services/external/external.service';

@Component({
  selector: 'app-edit-visa-refusal',
  templateUrl: './edit-visa-refusal.component.html',
  styleUrls: ['./edit-visa-refusal.component.scss']
})
export class EditVisaRefusalComponent implements OnInit {
  @Input() countryList: Country[];
  form: FormGroup;
  filteredCOptions: Observable<Country[]>;

  previousData;
  previousIndex;
  
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditVisaRefusalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.countryList = data.countryList;
    this.previousData = data.previousData;
    this.previousIndex = data.previousIndex;
  }

  ngOnInit() {    
    this.form = this.formBuilder.group({
      country: [this.previousData?.country, [Validators.required, ValidCountry(this.countryList)]],
      stateOrProvince: [this.previousData?.stateOrProvince, Validators.required],
      reason: [this.previousData?.reason, Validators.required],
      date: [this.getLocalDate(this.previousData?.date), Validators.required]
    });    

    this.filteredCOptions = this.form.get('country').valueChanges
      .pipe(
        map(value => typeof value === 'string' ? value : value?.name),
        map(value => this._countryFilter(value))
      );
  }

  private _countryFilter(value: string): Country[] {
    if (!value) {
      value = '';
    }

    const filterValue = value.toLowerCase();

    return this.countryList?.filter(country => country.name.toLowerCase().includes(filterValue));
  }

  setCountry($event: MatAutocompleteSelectedEvent) {
    const country: Country = $event.option.value;
    this.form.controls.country.setValue(country.name);
  }

  displayFriendlyCountry(value: string | Country) {
    if (typeof value === 'string') {
      return value;
    }

    return value ? value.name : undefined;
  }

  noFutureDateFilter = (d: Date | null): boolean => {
    return d < new Date();
  }

  noPastDateFilter = (d: Date | null): boolean => {
    return !this.noFutureDateFilter(d);
  }

  formatDate(date: Date) {
    return date.toISOString().split('T')[0];
  }

  getLocalDate(dateString: string) {
    if (!dateString) return null;
    
    const date = new Date(dateString);
    const localDate = date.getTime() + (60 * 1000 * date.getTimezoneOffset());
    
    return new Date(localDate);
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.form.controls.date.setValue(this.formatDate(this.form.controls.date.value));
    console.log(this.form.value, this.form.getRawValue());
    
    this.dialogRef.close(this.form.value);
  }
}

export function ValidCountry(countries: Country[]) {
  return (control: AbstractControl) => {
    const selection: any = control.value;

    if (typeof selection === 'string') {
      const found = countries.some(c => c.name === selection);
      if (!found) {
        return {
          invalidSelection: true
        }
      }
    }

    return null;
  }
}
