import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStoreService } from 'src/app/services/local-store/local-store.service';
import { SplitService } from 'src/app/services/split/split.service';

enum UserAccountType {
  'studypath_agent' = 'operative-agent',
  'studypath_affiliate' = 'affiliate-agent',
  'studypath_admin' = 'admin',
}

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
})
export class AdminSidebarComponent implements OnInit {
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;
  @Output() updateMargins: EventEmitter<any> = new EventEmitter();

  agentMenuItems: {
    title: string;
    icon: string;
    link: string;
  }[] = [
    {
      title: 'Dashboard',
      icon: 'dashboard',
      link: 'dashboard',
    },
    {
      title: 'Referrals',
      icon: 'referrals',
      link: 'referrals',
    },
    {
      title: 'Transactions',
      icon: 'applications',
      link: 'transactions',
    },
  ];

  menuItems: {
    title: string;
    link: string;
    isInternalAdmin: boolean;
  }[] = [
    {
      title: 'Dashboard',
      link: 'dashboard',
      isInternalAdmin: false,
    },
    {
      title: 'Institutions',
      link: 'institutions',
      isInternalAdmin: false,
    },
    {
      title: 'Programs',
      link: 'courses',
      isInternalAdmin: false,
    },
    {
      title: 'Students',
      link: 'students',
      isInternalAdmin: false,
    },
    {
      title: 'Agents',
      link: 'agents',
      isInternalAdmin: false,
    },
    {
      title: 'Countries',
      link: 'countries',
      isInternalAdmin: false,
    },
    {
      title: 'Caregivers',
      link: 'caregivers',
      isInternalAdmin: false,
    },
    {
      title: 'Referrals',
      link: 'referrals',
      isInternalAdmin: false,
    },
    {
      title: 'Applications',
      link: 'applications',
      isInternalAdmin: false,
    },
    {
      title: 'Admins',
      link: 'admins',
      isInternalAdmin: true,
    },
    {
      title: 'Settings',
      link: 'settings',
      isInternalAdmin: false,
    },
  ];

  filteredMenu = [];

  minimizeSidebarElement: boolean = true;
  isMinimized: boolean = true;
  isMaximized: boolean = false;

  minimizeValue: number = 92;
  maximizeValue: number = 300;

  constructor(
    private authService: AuthService,
    private localStoreService: LocalStoreService,
    private splitService: SplitService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.checkModuleTreatment();
    await this.getMenuContent();
  }

  get getBaseURLPath() {
    return UserAccountType[this.authService.getUserRole()];
  }

  get userIsAdmin() {
    return this.authService.getUserRole().includes('admin') ? true : false;
  }

  getSidebarStoredState() {
    let sidebarState = this.localStoreService.getItem('sidebar_state') ?? 'min';

    if (sidebarState === 'max') {
      this.toggleSidebar();
    }
  }

  setSidebarStoredState(state: string): void {
    this.localStoreService.setItem('sidebar_state', state);
  }

  async getMenuContent() {
    let validState = [null, 'internal'];

    let adminType: string = await this.authService.getAdminType();
    let isAdmin = validState.includes(adminType) ? true : false;

    if (this.authService.getUserRole().includes('admin')) {
      if (isAdmin) {
        this.filteredMenu = this.menuItems;
      } else {
        this.filteredMenu = this.menuItems.filter(
          (item) => !item.isInternalAdmin
        );
      }
    } else {
      this.filteredMenu = this.agentMenuItems;
    }
  }

  checkModuleTreatment() {
    this.splitService.initSdk();

    this.splitService.sdkReady$.subscribe(
      () => {
        const country_treatment = this.splitService.getTreatment(
          'admin-country-module'
        );
        const application_treatment = this.splitService.getTreatment(
          'admin-application-module'
        );

        this.checkTreatment(country_treatment, 'Countries');
        this.checkTreatment(application_treatment, 'Applications');

        this.getSidebarStoredState();
      },
      (error) => {
        console.log('Error connecting to Split SDK', error);
      }
    );
  }

  updateDrawerContentMargins(width: number, state: boolean = true) {
    this.updateMargins.emit({ width, state });
  }

  onMouseEnter(event: any): void {
    if (this.isMinimized) {
      this.minimizeSidebarElement = false;
      this.updateDrawerContentMargins(this.maximizeValue, false);
    }
  }

  onMouseOut(event: any): void {
    if (!this.isMaximized) {
      this.minimizeSidebarElement = true;
      this.updateDrawerContentMargins(this.minimizeValue, false);
    }
  }

  toggleSidebar(): void {
    this.isMinimized = !this.isMinimized;
    this.isMaximized = !this.isMaximized;

    if (this.isMaximized) {
      this.sidebarMenu.nativeElement.classList.remove('minimize');
      this.updateDrawerContentMargins(this.maximizeValue);
    }

    if (this.isMinimized) {
      this.sidebarMenu.nativeElement.classList.add('minimize');
      this.updateDrawerContentMargins(this.minimizeValue);
    }

    // SET CHOOSEN SIDEAR STATE
    this.setSidebarStoredState(this.isMinimized ? 'min' : 'max');
  }

  checkTreatment(treatment: string, module: string) {
    treatment === 'on'
      ? (this.filteredMenu = this.menuItems)
      : this.filterOutOfMenuItems(module);
  }

  filterOutOfMenuItems(menuItem: string) {
    this.filteredMenu = this.filteredMenu.filter(
      (item) => item.title !== menuItem
    );
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}
