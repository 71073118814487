import { Injectable } from '@angular/core';
import {
  IAboutYourselfModalSaveClicked,
  IAboutYourselfModalShown,
  IApplicationPaymentAttempt,
  IApplicationsAddMoreProgramsClicked,
  IApplicationsApplyNowButtonClicked,
  IApplyAddMoreProgramsClicked,
  IApplyButtonClicked,
  IApplyCompleteApplicationClicked,
  IApplyIntakeSelectionConfirmed,
  IApplyLoanSelectionConfirmed,
  IApplyModalDismissed,
  ICheckoutCouponAddButtonClicked,
  ICheckoutCouponApplyAttempt,
  ICheckoutPayButtonClicked,
  ICheckoutPaymentGatewaySelectionConfirmed,
  ICheckoutProgramRemoved,
  IInstitutionClicked,
  ILoginModalDismissed,
  IProgramClicked,
} from 'src/app/models/amplitude';
import { environment } from 'src/environments/environment';
declare var amplitude: any;

@Injectable({
  providedIn: 'root',
})
export class AmplitudeTrackerService {
  trackOnProd: boolean = false;
  initiateTrackEvent: boolean;

  initialEntryTime: number;

  constructor() {
    this.initiateAmplitudeEventTracker();
    this.setPageEntryTime();
  }

  private initiateAmplitudeEventTracker() {
    this.initiateTrackEvent = this.trackOnProd
      ? true
      : environment.production
      ? false
      : true;
  }

  private setPageEntryTime() {
    this.initialEntryTime = new Date().getTime();
  }

  private getTimeUntilClick() {
    const timeClicked = new Date().getTime();
    return timeClicked - this.initialEntryTime;
  }

  private getSourcePageUrl() {
    const { pathname, search, hash } = window.location;
    return `${pathname}${search}${hash}`;
  }

  private trackEvent(amplitudeEventName: string, amplitudeEventSource: any) {
    if (this.initiateTrackEvent)
      amplitude.track(amplitudeEventName, amplitudeEventSource);
  }

  // !! Event Enabled
  // APPLICATION_PAYMENT_ATTEMPT
  ApplicationPaymentAttempt(sourceData: IApplicationPaymentAttempt) {
    this.trackEvent('APPLICATION_PAYMENT_ATTEMPT', sourceData);
  }

  // !! Event Enabled
  // APPLICATIONS_ADD_MORE_PROGRAMS_CLICKED
  ApplicationsAddMoreProgramsClicked(
    sourceData: IApplicationsAddMoreProgramsClicked
  ) {
    this.trackEvent('APPLICATIONS_ADD_MORE_PROGRAMS_CLICKED', sourceData);
  }

  // !! Event Enabled
  // APPLICATIONS_APPLY_NOW_BUTTON_CLICKED
  ApplicationsApplyNowButtonClicked(
    sourceData: IApplicationsApplyNowButtonClicked
  ) {
    this.trackEvent('APPLICATIONS_APPLY_NOW_BUTTON_CLICKED', sourceData);
  }

  // !! Event Enabled -- Missing a institution_id
  // APPLY_ADD_MORE_PROGRAMS_CLICKED
  ApplyAddMoreProgramsClicked(sourceData: IApplyAddMoreProgramsClicked) {
    this.trackEvent('APPLY_ADD_MORE_PROGRAMS_CLICKED', sourceData);
  }

  // !! Event Enabled
  // APPLY_BUTTON_CLICKED
  ApplyButtonClicked(sourceData: IApplyButtonClicked) {
    this.trackEvent('APPLY_BUTTON_CLICKED', {
      ...sourceData,
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled -- Missing a institution_id
  // APPLY_COMPLETE_APPLICATION_CLICKED
  ApplyCompleteApplicationClicked(
    sourceData: IApplyCompleteApplicationClicked
  ) {
    this.trackEvent('APPLY_COMPLETE_APPLICATION_CLICKED', sourceData);
  }

  // !! Event Enabled -- Missing a institution_id
  // APPLY_INTAKE_SELECTION_CONFIRMED
  ApplyIntakeSelectionConfirmed(sourceData: IApplyIntakeSelectionConfirmed) {
    this.trackEvent('APPLY_INTAKE_SELECTION_CONFIRMED', sourceData);
  }

  // !! Event Enabled -- Missing a institution_id
  // APPLY_LOAN_SELECTION_CONFIRMED
  ApplyLoanSelectionConfirmed(sourceData: IApplyLoanSelectionConfirmed) {
    this.trackEvent('APPLY_LOAN_SELECTION_CONFIRMED', sourceData);
  }

  // !! Event Enabled
  // APPLY_MODAL_DISMISSED
  ApplyModalDismissed(sourceData: IApplyModalDismissed) {
    this.trackEvent('APPLY_MODAL_DISMISSED', sourceData);
  }

  // !! Event Enabled
  // BOOK_SESSION_BUTTON_CLICKED
  BookSessionButtonClicked() {
    this.trackEvent('BOOK_SESSION_BUTTON_CLICKED', {
      source: this.getSourcePageUrl(),
      time_until_click: this.getTimeUntilClick(),
    });
  }

  // !! Event Enabled
  // CHECKOUT_COUPON_ADD_BUTTON_CLICKED
  CheckoutCouponAddButtonClicked(sourceData: ICheckoutCouponAddButtonClicked) {
    this.trackEvent('CHECKOUT_COUPON_ADD_BUTTON_CLICKED', sourceData);
  }

  // !! Event Enabled
  // CHECKOUT_COUPON_APPLY_ATTEMPT
  CheckoutCouponApplyAttempt(sourceData: ICheckoutCouponApplyAttempt) {
    this.trackEvent('CHECKOUT_COUPON_APPLY_ATTEMPT', sourceData);
  }

  // !! Event Enabled
  // CHECKOUT_PAY_BUTTON_CLICKED
  CheckoutPayButtonClicked(sourceData: ICheckoutPayButtonClicked) {
    this.trackEvent('CHECKOUT_PAY_BUTTON_CLICKED', sourceData);
  }

  // !! Event Enabled
  // CHECKOUT_PAYMENT_GATEWAY_SELECTION_CONFIRMED
  CheckoutPaymentGatewaySelectionConfirmed(
    sourceData: ICheckoutPaymentGatewaySelectionConfirmed
  ) {
    this.trackEvent('CHECKOUT_PAYMENT_GATEWAY_SELECTION_CONFIRMED', sourceData);
  }

  // !! Event Enabled
  // CHECKOUT_PROGRAM_REMOVED
  CheckoutProgramRemoved(sourceData: ICheckoutProgramRemoved) {
    this.trackEvent('CHECKOUT_PROGRAM_REMOVED', sourceData);
  }

  // !! Event Enabled
  // INSTITUTION_CLICKED
  InstitutionClicked(sourceData: IInstitutionClicked) {
    this.trackEvent('INSTITUTION_CLICKED', {
      ...sourceData,
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // LOGIN_EMAIL_OPTION_CLICKED
  LoginEmailOptionSelected() {
    this.trackEvent('LOGIN_EMAIL_OPTION_SELECTED', {
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // LOGIN_GOOGLE_OPTION_CLICKED
  LoginGoogleOptionSelected() {
    this.trackEvent('LOGIN_GOOGLE_OPTION_SELECTED', {
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // LOGIN_EMAIL_BUTTON_CLICKED
  LoginEmailButtonClicked(email: string) {
    this.trackEvent('LOGIN_EMAIL_BUTTON_CLICKED', {
      email,
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // LOGIN_GOOGLE_BUTTON_CLICKED
  LoginGoogleButtonClicked() {
    this.trackEvent('LOGIN_GOOGLE_BUTTON_CLICKED', {
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // LOGIN_MODAL_DISMISSED
  LoginModalDismissed(sourceData: ILoginModalDismissed) {
    this.trackEvent('LOGIN_MODAL_DISMISSED', {
      ...sourceData,
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // LOGIN_MODAL_SHOWN
  LoginModalShown() {
    this.trackEvent('LOGIN_MODAL_SHOWN', {
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // NAV_LOGIN_BUTTON_CLICKED
  NavLoginButtonClicked() {
    this.trackEvent('NAV_LOGIN_BUTTON_CLICKED', {
      source: this.getSourcePageUrl(),
      time_until_click: this.getTimeUntilClick(),
    });
  }

  // !! Event Enabled
  // ABOUT_YOURSELF_MODAL_SHOWN
  AboutYourselfModalShown(sourceData: IAboutYourselfModalShown) {
    this.trackEvent('ABOUT_YOURSELF_MODAL_SHOWN', {
      ...sourceData,
    });
  }

  // !! Event Enabled
  // ABOUT_YOURSELF_MODAL_DISMISSED
  AboutYourselfModalDismissed() {
    this.trackEvent('ABOUT_YOURSELF_MODAL_DISMISSED', {});
  }

  // !! Event Enabled
  // ABOUT_YOURSELF_MODAL_LATER_CLICKED
  AboutYourselfModalLaterClicked() {
    this.trackEvent('ABOUT_YOURSELF_MODAL_LATER_CLICKED', {});
  }

  // !! Event Enabled
  // ABOUT_YOURSELF_MODAL_SAVE_CLICKED
  AboutYourselfModalSaveClicked(sourceData: IAboutYourselfModalSaveClicked) {
    this.trackEvent('ABOUT_YOURSELF_MODAL_SAVE_CLICKED', {
      ...sourceData,
    });
  }

  // !! Event Enabled
  // NAV_REGISTER_BUTTON_CLICKED
  NavRegisterButtonClicked() {
    this.trackEvent('NAV_REGISTER_BUTTON_CLICKED', {
      source: this.getSourcePageUrl(),
      time_until_click: this.getTimeUntilClick(),
    });
  }

  // !! Event Enabled
  // NAV_SEARCH_BUTTON_CLICKED
  NavSearchButtonClicked() {
    this.trackEvent('NAV_SEARCH_BUTTON_CLICKED', {
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // PROGRAM_CLICKED
  ProgramClicked(sourceData: IProgramClicked) {
    this.trackEvent('PROGRAM_CLICKED', {
      ...sourceData,
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // PROGRAM_SEARCH_BUTTON_CLICKED
  ProgramSearchButtonClicked() {
    this.trackEvent('PROGRAM_SEARCH_BUTTON_CLICKED', {
      source: this.getSourcePageUrl(),
      time_until_click: this.getTimeUntilClick(),
    });
  }

  // !! Event Enabled
  // REGISTER_BUTTON_CLICKED
  RegisterButtonClicked() {
    this.trackEvent('REGISTER_BUTTON_CLICKED', {
      source: this.getSourcePageUrl(),
    });
  }

  // !! Event Enabled
  // NAV_MY_APPLICATIONS_CLICKED
  NavMyApplicationsClicked() {
    this.trackEvent('NAV_MY_APPLICATIONS_CLICKED', {
      source: this.getSourcePageUrl(),
    });
  }
}
