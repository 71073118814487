<div class="wrapper">
  <div class="container">
    <app-base-topbar></app-base-topbar>

    <div fxLayout="column">
      <div class="page-content-wrapper">
        <app-root></app-root>
      </div>
    </div>

    <app-base-footer></app-base-footer>
  </div>
</div>
