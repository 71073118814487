<div class="menu" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.gt-sm="32px" style="height: 100%; padding-top: 32px;">
    <div style="margin-left: 54px; color: inherit">Sections</div>
    <div *ngIf="showDescription()" style="margin: 16px; font-size: 14px;">
        Welcome! Please complete the following sections continue. Click on any to get started.
    </div>
    <div class="menu-link" [routerLink]="['/','caregiver-pathway','applicant-information']" (click)="openSection()" fxLayout="row" fxLayoutGap="8px" routerLinkActive="active-link">
        <mat-icon>radio_button_unchecked</mat-icon>
        <div>Applicant Information</div>
    </div>
    <div class="menu-link" [routerLink]="['/','caregiver-pathway','professional-experience']" (click)="openSection()" fxLayout="row" fxLayoutGap="8px" routerLinkActive="active-link">
        <mat-icon>radio_button_unchecked</mat-icon>
        <div>Professional Experience</div>
    </div>
    <div class="menu-link" [routerLink]="['/','caregiver-pathway','professional-references']" (click)="openSection()" fxLayout="row" fxLayoutGap="8px" routerLinkActive="active-link" [routerLinkActiveOptions]="{}">
        <mat-icon>radio_button_unchecked</mat-icon>
        <div>Professional References</div>
    </div>
    <div class="menu-link" [routerLink]="['/','caregiver-pathway','character-references']" (click)="openSection()" fxLayout="row" fxLayoutGap="8px" routerLinkActive="active-link">
        <mat-icon>radio_button_unchecked</mat-icon>
        <div>Character References</div>
    </div>
    <div class="menu-link" [routerLink]="['/','caregiver-pathway','disclaimer-signature']" (click)="openSection()" fxLayout="row" fxLayoutGap="8px" routerLinkActive="active-link">
        <mat-icon>radio_button_unchecked</mat-icon>
        <div>Disclaimer and Signature</div>
    </div>
</div>