import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SessionRoutingModule } from './session-routing.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LogInComponent } from './log-in/log-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { RegistrationConfirmationComponent } from './registration-confirmation/registration-confirmation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UsernameAvailableValidator, EmailAvailableValidator } from 'src/app/services/user/validators.service';
import { ResetLinkSentComponent } from './reset-link-sent/reset-link-sent.component';
import { SharedModule } from '../shared/shared.module';
import { AgentRegistrationComponent } from './agent-registration/agent-registration.component';
import { SocialSigninComponent } from './social-signin/social-signin.component';

@NgModule({
  declarations: [
    SignUpComponent,
    LogInComponent,
    ResetPasswordComponent,
    RequestResetPasswordComponent,
    RegistrationConfirmationComponent,
    UsernameAvailableValidator,
    EmailAvailableValidator,
    ResetLinkSentComponent,
    AgentRegistrationComponent,
    SocialSigninComponent,
  ],
  imports: [
    CommonModule,
    SessionRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SharedModule
  ],
  entryComponents: [SignUpComponent]
})
export class SessionModule { }
