<!-- [style.width.px]="drawerWidth" -->
<div class="wrapper">
  <mat-drawer-container class="container">
    <mat-drawer
      #drawer
      [mode]="'side'"
      [opened]="'true'"
      [style.width.px]="drawerWidth"
      class="side-drawer"
    >
      <app-admin-sidebar
        (updateMargins)="updateContentMargin($event)"
      ></app-admin-sidebar>
    </mat-drawer>

    <mat-drawer-content #content [style.marginLeft.px]="contentMarginLeft">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="padding: 28px 40px"
      >
        <h1 class="pave-text">{{ label }} {{ customLabel }}</h1>
        <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
          <div class="user-menu-name">{{ getProfileName() }}</div>
          <div class="user-menu-image"></div>
        </div>
      </div>

      <div style="padding: 28px 40px; min-width: min-content">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
