import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { BehaviorSubject, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuildDetailsService {

  private buildDetails: BuildDetails;
  private newBuildAvailable = new BehaviorSubject<boolean>(false);
  private scheduler = timer(0, 60000);

  constructor(
    private http: HttpClient
  ) { }

  getBuildVersion() {
    return this.buildDetails?.version;
  }

  hasUpdate() {
    return this.newBuildAvailable.value;
  }

  pollBuildDetails() {
    this.scheduler.subscribe(() => this.fetchBuildDetails());
  }

  fetchBuildDetails() {
    this.http.get<BuildDetails>('assets/build.json', { headers: new HttpHeaders({ 'Cache-Control': 'no-cache' }) }).subscribe(
      buildDetails => {
        if (!this.buildDetails) {
          console.log('setting fresh build details', buildDetails);          
          this.buildDetails = buildDetails;
          this.initDdLogs(this.buildDetails.version);
        } else {
          if (this.buildDetails.version !== buildDetails.version) {
            console.log('new build found', buildDetails);            
            this.newBuildAvailable.next(true);
          }
        }
      }
    );
  }

  initDdLogs(version) {

    datadogLogs.init({
      clientToken: 'pubafbeec412b36b51100a2d67b32c94e46',
      site: 'datadoghq.eu',
      service: 'web',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error','warn'],
      env: environment.envName ? environment.envName : environment.production ? 'prod' : 'unknown',
      version: version,
      trackSessionAcrossSubdomains: true,
      beforeSend: event => {
        if (environment.envName === 'local') return false;
        if (event.http?.status_code === 0) return false;
        
        const {user_accessToken, user_refreshToken, ls} = localStorage;
        const {ss} = sessionStorage;
        event.localStorage = ls,
        event.sessionStorage = ss
      }
    });}
}

export interface BuildDetails {
  version: string;
}