<!-- Init --> 
<div *ngIf="showLoadingState" class="email-verifying-container">
  <div class="email-verification-image"></div>
  <mat-progress-bar
    mode="indeterminate"
    color="primary"
    style="width: 100%; border-radius: 8px; margin: 8px"
  ></mat-progress-bar>
</div>

<!-- Verified -->
<div *ngIf="verified" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
  <div class="email-verified-image" ngClass.lt-md="mobile"></div>
  <div class="email-verified-message">
    Your email address has been successfully verified!
  </div>
  <button *ngIf="this.isLoggedIn" class="pave-btn pave-btn-primary" (click)="continueAfterVerification()">
    Continue
  </button>
  <button *ngIf="!this.isLoggedIn" class="pave-btn pave-btn-primary" [routerLink]="['/','session','log-in']">
    Login
  </button>
</div>

<!-- Failed Verification -->
<div *ngIf="showFailedState" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
  <div class="verification-failure-image" ngClass.lt-md="mobile"></div>
  <div class="verification-failure-message">
    Your verification code has expired. Please click on the button below to send another code to your email.
  </div>
  <button class="pave-btn pave-btn-primary" (click)="resendCode()">
    Resend code
  </button>
</div>

<!-- Retry Verification -->
<form 
  *ngIf="showVerifyState"
  [formGroup]="form" fxLayout="column" fxLayoutAlign="center center"
  id="verify-email"
>
  <div class="email-verification-image"></div>

  <div *ngIf="!verifying" fxLayout="column" fxLayoutGap="32px">
    <label style="text-align: center">
      A verification code has been sent to your email address. Please copy the code and paste in the boxes below.
    </label>

    <code-input
      [codeLength]="6"
      [initialFocusField]="0"
      (codeCompleted)="onCodeCompleted($event)">
    </code-input>

    <div fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="16px">
      <div style="text-align: center">
        Didn't get the code? <span *ngIf="timeUntilCanResendCode!==0">Try resending in 0:{{timeUntilCanResendCode | number:'2.0' }}</span>
      </div>
      <button
        mat-raised-button
        [disabled]="timeUntilCanResendCode!==0"
        [loading]="resending"
        (click)="resendCode()"
        color="primary"
        class="pave-button"
        style="margin-top: 8px; height: 48px; width: 14em;"
      >
        Resend Code
      </button>
    </div>
  </div>
</form>