<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="0px"
  fxLayoutGap.gt-sm="10%"
>
  <div class="auth-bg" fxHide.lt-md ngClass.lt-md="mobile"></div>

  <div fxLayout="column" fxLayoutGap="16px">
    <div>
      <mat-card>
        <form
          [formGroup]="form"
          (submit)="validateUserRegistration()"
          id="register"
        >
          <mat-card-header fxLayout="column">
            <mat-card-title>
              <div style="font-weight: 600">Hello,</div>
              <p style="font-weight: 400; font-size: 16px">
                Please register to continue
              </p>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content fxLayout="column" fxLayoutGap="16px">
            <mat-form-field appearance="outline">
              <mat-label>Full Name</mat-label>
              <input
                matInput
                formControlName="fullName"
                name="name"
                placeholder="Firstname Lastname"
              />
              <mat-error
                >full name required (ex: Fela Anikulapo-Kuti)</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Email Address</mat-label>
              <input
                matInput
                formControlName="email"
                type="email"
                name="email"
              />
              <!--appEmailAvailableValidator-->
              <!--mat-spinner [diameter]="20" class="validator__spinner" *ngIf="email.pending"></mat-spinner>
              <mat-icon class="validator__success" *ngIf="email.valid">check_circle_outline</mat-icon-->
              <mat-error
                *ngIf="
                  email.hasError('required') ||
                  email.hasError('email') ||
                  email.hasError('invalidEmail')
                "
                >email not valid</mat-error
              >
              <mat-error *ngIf="email.hasError('taken')"
                >email already registered</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input
                [type]="passwordFieldType"
                matInput
                formControlName="password"
                name="password"
              />
              <mat-icon
                class="visibility-toggle"
                *ngIf="passwordFieldType === 'text'"
                matSuffix
                (click)="passwordFieldType = 'password'"
                >visibility</mat-icon
              >
              <mat-icon
                class="visibility-toggle"
                *ngIf="passwordFieldType === 'password'"
                matSuffix
                (click)="passwordFieldType = 'text'"
                >visibility_off</mat-icon
              >
              <mat-error
                >minimum of 8 characters, including a special
                character?</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Referral Code</mat-label>
              <input
                matInput
                formControlName="referredBy"
                name="referralCode"
                placeholder="Referral Code (Optional)"
              />
            </mat-form-field>

            <button
              mat-raised-button
              [loading]="loading"
              color="primary"
              style="text-transform: uppercase; line-height: 50px"
            >
              register
            </button>
          </mat-card-content>
        </form>
      </mat-card>

      <p fxLayoutAlign="center center" style="font-size: smaller">
        Have an account? &nbsp;<a
          [routerLink]="['/', 'session', 'log-in']"
          fxLayoutAlign="center center"
          >LOGIN <mat-icon>arrow_forward</mat-icon></a
        >
      </p>
    </div>
  </div>
</div>
