import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required]]
    });
  }

  ngOnInit() { }

  reset() {
    const email = this.form.get('email').value;
    this.authService.requestResetPassword(email).subscribe(response => {
      console.log(response);
      this.router.navigate(['/','session','reset-link-sent'], {queryParams: {email}});
    });
  }

}
