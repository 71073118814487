import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-base-topbar-dropdown',
  templateUrl: './base-topbar-dropdown.component.html',
  styleUrls: ['./base-topbar-dropdown.component.scss'],
})
export class BaseTopbarDropdownComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {}

  get isOperativeAgent() {
    return this.authService.getUserRole() === UserRole.Agent;
  }

  getProfileName() {
    return this.authService.getUserFullName();
  }

  getProfileImage() {
    return this.authService?.getUserImage();
  }

  getProfileEmail() {
    return this.authService.getUserEmail();
  }

  logOutUser() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}
