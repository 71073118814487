<div class="base-sidebar-menu wt-100 ht-100">
  <!-- USER INFO -->
  <div class="navbar-top mgb-20">
    <div class="top-row mgb-2 pointer" (click)="goToMainMenu()">
      <mat-icon>arrow_back</mat-icon>
      <div class="footer-fill f-size-12-5 fw-medium">Back</div>
    </div>

    <div class="f-size-16 fw-medium pave-text">{{ optionTitle }}</div>
  </div>

  <!-- NAV ITEMS -->
  <div class="nav-item-list">
    <div class="nav-item" *ngFor="let option of optionData">
      <div class="nav-item-title pdl-12">
        {{ option.title }}
      </div>

      <div>
        <div class="nav-item-data pointer" *ngFor="let nav of option.options">
          <img
            [src]="
              '../../../../../assets/images/nav-icons/' + nav.navIcon + '.png'
            "
            [alt]="nav.navTitle"
          />
          <div class="nav-text">{{ nav.navTitle }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
