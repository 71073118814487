import { Injectable } from '@angular/core';
import { GeoInformation } from '../external/external.service';
import { NotificationService } from '../notification/notification.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  getAvailableSavedCurrencies,
  isCurrencyAvailable,
} from 'src/app/modules/shared/currency-selector/currencies';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {}

  getCurrency() {
    const currency = localStorage.getItem('currency');

    if (!currency) {
      console.log('currency not set');
      this.setDefaultCurrency();
    }

    return currency;
  }

  getAvailableCurrencies() {
    try {
      return getAvailableSavedCurrencies();

      // return Object.keys(JSON.parse(ratios).rates);
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  setDefaultCurrency(currency = null, fromError = false) {
    if (fromError) {
      this.notificationService.showError(
        'Could not convert currencies. Resetting'
      );
    }

    if (!currency) {
      try {
        const geo: GeoInformation = JSON.parse(localStorage.getItem('geo'));

        currency = isCurrencyAvailable(geo?.currency?.code)
          ? geo?.currency?.code
          : 'USD';
      } catch (e) {
        console.log('error while loading stored geo information', e);
      }
    }

    localStorage.setItem('currency', currency);
  }

  getCouponCode(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.applicationApiDomain}/v1/coupon`
    );
  }

  createCouponCode(data): Observable<any> {
    return this.http.post<any>(
      `${environment.applicationApiDomain}/v1/coupon`,
      data
    );
  }
}
