import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AcademicInformation, AcademicQualification } from 'src/app/models/api/user';
import { UserService } from 'src/app/services/user/user.service';
import { Country, ExternalService } from 'src/app/services/external/external.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QualificationRowComponent } from './qualification-row/qualification-row.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { EditQualificationRowComponent } from './edit-qualification-row/edit-qualification-row.component';
import { EditOtherCertificationComponent } from './edit-other-certification/edit-other-certification.component';
import { EditLanguageCertificationComponent } from './edit-language-certification/edit-language-certification.component';
import { EditDeleteDialogComponent } from 'src/app/modules/shared/edit-delete-dialog/edit-delete-dialog.component';

@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.scss']
})
export class AcademicComponent implements OnInit {
  @Input() studentId: string;
  @Input() countryCodeMap: Map<string, Country>;
  @Input() countryList: Country[];
  @Output() reloaded = new EventEmitter();

  hasLanguageCertifications = false;
  languageCertifications: any[];
  hasOtherCertifications = false;
  otherCertifications: any[];
  educationHistory: AcademicQualification[]; 
  
  @ViewChildren('anchor') private _anchors: ElementRef[];

  get anchors() {
    return this._anchors.map(a => {
      const fields: string[] = a.nativeElement.attributes.fields.value.split(',');
      const completeMap = fields.map(f => this[f]?.length > 0);

      return {
        name: a.nativeElement.innerHTML,
        complete: completeMap.every(v => v)
      }
    });
  }

  get anchorPositions() {
    return this._anchors.map(a => {
      return {
        parentStub: 'academicInformation',
        stub: a.nativeElement.innerHTML.replace(' ',''),
        pos: a.nativeElement.getBoundingClientRect().top
      }
    });
  }

  constructor(
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchAcademicInformation();
  }

  fetchAcademicInformation() {
    this.userService.getAcademicInformation(this.studentId).subscribe(academicInformation => {
      academicInformation.educationHistory = academicInformation.educationHistory.map(h => {
        h.country = h.country || null,
        h.grade = {
          gradeAttainable: h.gradeAttainable || null,
          gradeAttained: h.gradeAttained || null
        }
        delete h.gradeAttained;
        delete h.gradeAttainable;

        return h;
      });

      this.educationHistory = academicInformation.educationHistory;
      this.reloaded.emit(true);
    });

    this.userService.getLanguageCertifications(this.studentId).subscribe(languageCertifications => {
      this.languageCertifications = languageCertifications;
      this.hasLanguageCertifications = this.languageCertifications.length > 0;
      setTimeout(() => this.reloaded.emit(true), 100);
    });

    this.userService.getOtherCertifications(this.studentId).subscribe(otherCertifications => {
      this.otherCertifications = otherCertifications;
      this.hasOtherCertifications = this.otherCertifications.length > 0;
      setTimeout(() => this.reloaded.emit(true), 100);
    });
  }

  languageSelectionChanged(event) {
    if (event.value === true) {
      if (this.languageCertifications.length === 0) {
        this.addLanguageCertification(null);
      }
    } else {
      this.languageCertifications = [];
    }
  }

  otherSelectionChanged(event) {
    if (event.value === true) {
      if (this.otherCertifications.length === 0) {
        this.addOtherCertification(null);
      }
    } else {
      this.otherCertifications = [];
    }
  }

  mobileEducationHistoryRowClicked(educationHistory, target: HTMLElement) {
    if (!target.classList.contains('mobile')) {
      return;
    }
    
    this.dialog.open(EditDeleteDialogComponent, {
      width: '80vw',
      autoFocus: false,
      panelClass: 'edit-delete-dialog'
    }).afterClosed().subscribe(action => {
      if (action === 'edit') {
        this.editEducationHistoryRecord(educationHistory);
      } else if (action === 'delete') {
        this.deleteEducationHistoryRecord(educationHistory);
      }
    });
  }

  addEducationHistoryRecord(record=null) {
    this.dialog.open(EditQualificationRowComponent, {
      data: {studentId: this.studentId, record},
      width: '88%',
      maxWidth: '560px'
    }).afterClosed().subscribe(saved => {
      if (saved) this.fetchAcademicInformation();
    });
  }

  editEducationHistoryRecord(qualification: AcademicQualification) {
    this.dialog.open(EditQualificationRowComponent, {
      data: {studentId: this.studentId, qualification},
      width: '88%',
      maxWidth: '560px'
    }).afterClosed().subscribe(saved => {
      if (saved) this.fetchAcademicInformation();
    });;
  }

  deleteEducationHistoryRecord(qualification: AcademicQualification) {
    this.userService.removeUserAcademicQualification(qualification.id, this.studentId).subscribe(_ => {
      this.fetchAcademicInformation();
    });
  }

  mobileLanguageCertificationRowClicked(index, certification, target: HTMLElement) {
    if (!target.classList.contains('mobile')) {
      return;
    }
    
    this.dialog.open(EditDeleteDialogComponent, {
      width: '80vw',
      autoFocus: false,
      panelClass: 'edit-delete-dialog'
    }).afterClosed().subscribe(action => {
      if (action === 'edit') {
        this.addLanguageCertification(certification);
      } else if (action === 'delete') {
        this.deleteLanguageCertification(index);
      }
    });
  }

  addLanguageCertification(certification) {
    this.dialog.open(EditLanguageCertificationComponent, {
      data: {studentId: this.studentId, certification},
      width: '88%',
      maxWidth: '560px'
    }).afterClosed().subscribe(saved => {
      if (saved) this.fetchAcademicInformation();
    });
  }
  
  deleteLanguageCertification(index: number) {
    this.languageCertifications.splice(index, 1);
    this.userService.saveLanguageCertifications(this.languageCertifications, this.studentId).subscribe(
      _ => {
        this.hasLanguageCertifications = this.languageCertifications.length > 0;
        setTimeout(() => this.reloaded.emit(true), 100);
      },
      error => {
        console.error(error);
        this.fetchAcademicInformation();
      }
    );
  }


  mobileOtherCertificationRowClicked(index, certification, target: HTMLElement) {
    if (!target.classList.contains('mobile')) {
      return;
    }
    
    this.dialog.open(EditDeleteDialogComponent, {
      width: '80vw',
      autoFocus: false,
      panelClass: 'edit-delete-dialog'
    }).afterClosed().subscribe(action => {
      if (action === 'edit') {
        this.addOtherCertification(certification);
      } else if (action === 'delete') {
        this.deleteOtherCertification(index);
      }
    });
  }

  addOtherCertification(certification=null) {
    this.dialog.open(EditOtherCertificationComponent, {
      data: {studentId: this.studentId, certification},
      width: '88%',
      maxWidth: '560px'
    }).afterClosed().subscribe(saved => {
      if (saved) this.fetchAcademicInformation();
    });
  }
  
  
  deleteOtherCertification(index: number) {
    this.otherCertifications.splice(index, 1);
    this.userService.saveOtherCertifications(this.otherCertifications, this.studentId).subscribe(
      _ => {
        this.hasOtherCertifications = this.otherCertifications.length > 0;
        setTimeout(() => this.reloaded.emit(true), 100);
      },
      error => {
        console.error(error);
        this.fetchAcademicInformation();
      }
    );
  }

  noFutureDateFilter = (d: Date | null): boolean => {
    return d < new Date();
  }

  displayFriendlyDegreeName(value: string) {
    const map = {
      highSchool: 'High School',
      undergraduateDiploma: 'Undergraduate Diploma',
      bachelors: 'Bachelors (or equivalent)',
      postgraduateDiploma: 'Postgraduate Diploma',
      masters: 'Masters (or equivalent)',
      doctorate: 'Doctorate (or equivalent)'
    }

    return map[value];
  }

  scrollToView(fragment: string) {
    const anchor = this._anchors.map(a => a.nativeElement).find(a => a.innerHTML.replace(' ', '').toLocaleLowerCase() === fragment);
    window.scrollTo({top: window.scrollY + anchor.getBoundingClientRect().top - 88, behavior: 'smooth'});
  }

}
