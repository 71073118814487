<div class="social-group">
  <!-- EMAIL SIGNIN -->
  <div class="social-action" (click)="handleEmailSignIn()">
    <img
      src="../../../../assets/images/signin-email-icon.png"
      alt=""
      class="social-icon"
    />
    <div class="social-text">Continue with email</div>
  </div>

  <!-- GOOGLE SIGNIN -->
  <div class="social-action" (click)="signInWithGoogle()">
    <img
      src="../../../../assets/images/signin-google-icon.png"
      alt=""
      class="social-icon"
    />
    <div class="social-text">Continue with Google</div>
  </div>
</div>
