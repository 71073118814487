import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fullscreen-layout',
  templateUrl: './fullscreen-layout.component.html',
  styleUrls: ['./fullscreen-layout.component.scss'],
})
export class FullscreenLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
