<div fxLayout="column" fxLayoutGap="32px">
    <div class="text-web-subtitle-m">Other Certification</div>
        <form class="other-form" fxLayout="column" [formGroup]="form" fxLayoutGap="20px" id="profile-other">
        <mat-form-field>
            <mat-select formControlName="type" placeholder="Select Examination">
                <mat-option value="GRE">GRE</mat-option>
                <mat-option value="GMAT">GMAT</mat-option>
                <mat-option value="JAMB">JAMB</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field (click)="picker.open()">
            <input [matDatepicker]="picker"  [matDatepickerFilter]="noFutureDateFilter" matInput readonly placeholder="Date of Examination" formControlName="date">
            <mat-icon matSuffix>event</mat-icon>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Overall Test Score" formControlName="score">
        </mat-form-field>

        <div fxLayout="row" fxFlexAlign="end" fxLayoutGap="16px">
            <button mat-dialog-close class="pave-button" mat-button color="warn">Cancel</button>
            <button [loading]="saving" [disabled]="!form.valid" class="pave-button" mat-raised-button color="primary" (click)="saveOtherCertifications()">
                Save
            </button>
        </div>
    </form>
</div>