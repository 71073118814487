import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  CourseApplication,
  CourseApplicationCart,
  NewCourseApplication,
} from 'src/app/models/api/application';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  addApplication(
    studentId: string,
    application: NewCourseApplication,
  ): Observable<CourseApplication> {
    application.userId = studentId || this.authService.getUserId() || null;
    return this.http.post<CourseApplication>(
      `${environment.applicationApiDomain}/v1/applications`,
      application,
    );
  }

  removeApplication(applicationId: string): Observable<CourseApplication> {
    return this.http.delete<CourseApplication>(
      `${environment.applicationApiDomain}/v1/applications/cancel/${applicationId}`,
    );
  }

  getApplicationsForUser(userId?: string): Observable<CourseApplication[]> {
    if (!userId) {
      userId = this.authService.getUserId();
    }

    return this.http.get<CourseApplication[]>(
      `${environment.applicationApiDomain}/v1/applications/user/${userId}`,
    );
  }

  getAccessibleApplications(): Observable<{
    applications: CourseApplication[];
  }> {
    return this.http.get<{ applications: CourseApplication[] }>(
      `${environment.applicationApiDomain}/v1/applications`,
    );
  }

  createApplicationCart(applicationIds: string[]) {
    const userId = this.authService.getUserId();
    const data = {
      userId,
      applicationIds,
    };

    return this.http.post<CourseApplicationCart>(
      `${environment.applicationApiDomain}/v1/applications/cart`,
      data,
    );
  }

  addApplicationsToCart(applicationIds: string[]) {
    const userId = this.authService.getUserId();
    const data = {
      userId,
      applicationIds,
    };

    return this.http.post<CourseApplicationCart>(
      `${environment.applicationApiDomain}/v1/applications/cart`,
      applicationIds,
    );
  }

  removeApplicationsFromCart(applicationIds: string[]) {
    const userId = this.authService.getUserId();

    if (applicationIds.length === 0) {
      return of(null);
    }

    // return this.http.delete<CourseApplicationCart>(`${environment.applicationApiDomain}/v1/applications/cart/${applicationId}`);
    return this.http.request<CourseApplicationCart>(
      'DELETE',
      `${environment.applicationApiDomain}/v1/applications/cart`,
      {
        headers: new HttpHeaders({ 'Content-type': 'application/json' }),
        body: applicationIds,
      },
    );
  }

  getApplicationCart(cartId: string, currencies: string[]) {
    const userId = this.authService.getUserId();
    return this.http.get<CourseApplicationCart>(
      `${environment.applicationApiDomain}/v1/applications/cart/${userId}?cartCurrencies=${currencies}`,
    );
  }

  addCouponToApplicationCart(cartId: string, couponCode: string) {
    const userId = this.authService.getUserId();
    return this.http.put(
      `${environment.applicationApiDomain}/v1/applications/cart/addCoupon?userId=${userId}&couponCode=${couponCode}`,
      null,
    );
  }

  removeCouponFromApplicationCart(cartId: string, couponCode: string) {
    const userId = this.authService.getUserId();
    return this.http.delete(
      `${environment.applicationApiDomain}/v1/applications/cart/removeCoupon?userId=${userId}&couponCode=${couponCode}`,
      {},
    );
  }

  markApplicationCartAsPaid(paymentPayload: any) {
    const userId = this.authService.getUserId();
    const data = {
      userId,
      ...paymentPayload,
    };

    return this.http.post(
      `${environment.applicationApiDomain}/v1/applications/cart/checkout`,
      data,
    );
  }

  fetchExchangeRates(): Observable<any> {
    return this.http.get<any>(
      `${environment.applicationApiDomain}/v1/convert-currency?sourceCurrency=USD&amount=1.0`,
    );
  }
}
