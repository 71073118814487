import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserRole } from './models/api/user';
import { FeedbackComponent } from './modules/shared/feedback/feedback.component';
import { AuthService } from './services/auth/auth.service';
import { BuildDetailsService } from './services/background/build-details.service';
import { SplitService } from './services/split/split.service';
declare var hsq: Function;
declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private routerSubscription: Subscription;
  developmentMode = true;

  constructor(
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
    private authService: AuthService,
    private buildDetailService: BuildDetailsService,
    private route: ActivatedRoute,
    private splitService: SplitService,
  ) {
    // this.authService.refreshAccessTokenForUser().subscribe(userProfile => {
    //   console.log('load user', userProfile);
    // });
  }

  ngOnInit() {
    // this.splitService.initSdk(localStorage.getItem('user_userId') ?? null);
    // console.log("SDK Ready", this.splitService.sdkReady$)
  }

  ngAfterViewInit() {
    this.developmentMode = !environment.production;

    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const root = this.router.routerState.snapshot.root;
        const leaf = this.getLeafRoute(root);
        this.titleService.setTitle(leaf.data.title || this.title);

        // console.log('navigating', leaf.data.title || this.title, document.title, localStorage.getItem('user_email'), event.urlAfterRedirects);

        if (localStorage.getItem('user_email')) {
          hsq([
            'identify',
            {
              id: localStorage.getItem('user_userId'),
              firstName: localStorage.getItem('user_firstName'),
              lastName: localStorage.getItem('user_lastName'),
              email: localStorage.getItem('user_email'),
            },
          ]);
        }

        if (leaf.data.title) {
          hsq(['setPath', event.urlAfterRedirects]);
          hsq(['trackPageView']);
        }

        if (this.authService.getUserRole() !== UserRole.Admin) {
          // console.log('setting page load', this.authService.getUserRole());

          gtag('set', 'page', event.urlAfterRedirects);
          gtag('send', 'pageview');
        }

        this.buildDetailService.fetchBuildDetails();

        if (this.buildDetailService.hasUpdate()) {
          console.log('new version found, will reload');
          window.location.href = event.url;
          window.location.reload();
        }
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription?.unsubscribe();
    }
  }

  async downloadScreenShot(isPositiveFeedback) {
    this.openDialog(isPositiveFeedback);
    // const canvas = await html2canvas(document.body, {allowTaint: false});

    // canvas.toBlob(blob => this.openDialog(isPositiveFeedback, blob),'image/jpeg', 0.2);
  }

  openDialog(isPositiveFeedback) {
    // const url = URL.createObjectURL(blob);
    // let downloadLink = document.createElement('a');
    // downloadLink.setAttribute('download', `CanvasAsImage${isPositiveFeedback ? "+" : "-"}.png`);
    // downloadLink.setAttribute('href', url);
    // downloadLink.click();

    this.dialog.open(FeedbackComponent, {
      data: { isPositiveFeedback },
    });
  }

  getLeafRoute(root: ActivatedRouteSnapshot) {
    if (root.children?.length > 0) {
      return this.getLeafRoute(root.children[0]);
    }

    return root;
  }

  title = 'Pave: Your career and study abroad guide';
}
