import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';
import { AuthService } from './services/auth/auth.service';
import { NotificationService } from './services/notification/notification.service';
import { MaterialModule } from './modules/material/material.module';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { SearchService } from './services/search/search.service';
import {
  PathLocationStrategy,
  LocationStrategy,
  CurrencyPipe,
} from '@angular/common';
import { SidebarComponent } from './layouts/home-layout/sidebar/sidebar.component';
import { LogInComponent } from './modules/session/log-in/log-in.component';
import { SessionModule } from './modules/session/session.module';
import { SearchBarComponent } from './modules/shared/search-bar/search-bar.component';
import { SharedModule } from './modules/shared/shared.module';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { FullscreenLayoutComponent } from './layouts/fullscreen-layout/fullscreen-layout.component';
import { FlexibleLayoutComponent } from './layouts/flexible-layout/flexible-layout.component';
import { ActionableDialogComponent } from './modules/shared/actionable-dialog/actionable-dialog.component';
import { ExchangeRateService } from './services/background/exchange-rate.service';
import { RadioDialogComponent } from './modules/shared/radio-dialog/radio-dialog.component';
import { ExternalService } from './services/external/external.service';
import { FeedbackComponent } from './modules/shared/feedback/feedback.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminSidebarComponent } from './layouts/admin-layout/sidebar/admin-sidebar.component';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { GoogleMapsModule } from '@angular/google-maps';
import { CaregiverPathwayLayoutComponent } from './layouts/caregiver-pathway-layout/caregiver-pathway-layout.component';
import { BuildDetailsService } from './services/background/build-details.service';
import { FlutterwaveModule } from 'flutterwave-angular-v3';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';

export function pollBuildDetails(buildDetailsService: BuildDetailsService) {
  return () => buildDetailsService.pollBuildDetails();
}

export function fetchGeoInformation(externalService: ExternalService) {
  return () =>
    new Promise<boolean>((resolve) => {
      externalService.getGeoInformation().subscribe(
        (geo) => {
          /* Setting the geo information in the local storage. */
          localStorage.setItem('geo', JSON.stringify(geo));
          resolve(true);
        },
        // Gracefully recover in an instance of an error
        () => resolve(true)
      );
    });
}

export function fetchInitialExchangeRates(
  exchangeRateService: ExchangeRateService
) {
  return () => exchangeRateService.fetchExchangeRates();
}

export function pollExchangeRates(exchangeRateService: ExchangeRateService) {
  return () => exchangeRateService.pollExchangeRates();
}

export const MY_FORMATS = {
  useUtc: true,
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    DashboardLayoutComponent,
    SessionLayoutComponent,
    AdminLayoutComponent,
    AdminSidebarComponent,
    SidebarComponent,
    FullscreenLayoutComponent,
    FlexibleLayoutComponent,
    CaregiverPathwayLayoutComponent,
    BaseLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    SessionModule,
    SharedModule,
    GoogleMapsModule,
    FlutterwaveModule,
  ],
  providers: [
    AuthService,
    NotificationService,
    SearchService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: fetchGeoInformation,
      deps: [ExternalService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: fetchInitialExchangeRates,
      deps: [ExchangeRateService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: pollExchangeRates,
      deps: [ExchangeRateService],
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    CurrencyPipe,
  ],
  entryComponents: [
    LogInComponent,
    SearchBarComponent,
    ActionableDialogComponent,
    RadioDialogComponent,
    FeedbackComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
