<div
  class="menu-item menu-item-connector"
  *ngIf="hasMenuOptions; else simpleMenuItem"
>
  <div class="item">{{ menuItem }}</div>
  <mat-icon>expand_more</mat-icon>

  <!-- NAV MENU -->
  <div
    class="menu-stack-wrapper smooth-animation"
    [ngClass]="pinnedNavbar && 'menu-scrolled'"
    *ngIf="menuOptions.length"
  >
    <div class="menu-stack-content rounded-20 surface-bg">
      <app-base-topbar-menu
        *ngFor="let option of menuOptions"
        [menuPayload]="option"
      ></app-base-topbar-menu>
    </div>
  </div>
</div>

<ng-template #simpleMenuItem>
  <a class="menu-item" [routerLink]="menuItemLink">
    <div class="item">{{ menuItem }}</div>
  </a>
</ng-template>
