<div
  class="base-topbar"
  [ngClass]="{
    'topbar-overlay': showOverlay,
    'grayscale-effect': showGrayScaleEffect
  }"
>
  <div class="pave-container">
    <!-- LEFT NAVBAR PANE -->
    <div class="navbar--left">
      <!-- BRAND LOGO -->
      <a class="brand-logo" [routerLink]="['/']">
        <img
          src=".././../../../assets/images/pave-logo-dark.png"
          alt="pave-logo"
          class="wt-100 ht-auto"
        />
      </a>

      <!-- BRAND MENU LIST -->
      <div class="brand-menu-list" *ngIf="false">
        <app-base-topbar-navitem
          menuItem="Products"
          [hasMenuOptions]="true"
          [menuOptions]="productOptions"
        ></app-base-topbar-navitem>

        <app-base-topbar-navitem
          menuItem="About"
          [menuItemLink]="['/', 'admin']"
        ></app-base-topbar-navitem>

        <app-base-topbar-navitem
          menuItem="Community"
          [menuItemLink]="['/', 'contact']"
        ></app-base-topbar-navitem>

        <app-base-topbar-navitem
          menuItem="Partner with us"
          [hasMenuOptions]="true"
          [menuOptions]="partnerOptions"
        ></app-base-topbar-navitem>
      </div>
    </div>

    <!-- RIGHT NAVBAR PANE -->
    <div class="navbar--right">
      <ng-container *ngIf="!isMobileView && isLoggedIn()">
        <div
          class="nav-item pave-text pointer"
          #tooltip="matTooltip"
          (click)="amplitudeTrackerService.NavMyApplicationsClicked()"
          [routerLink]="['/', 'user', 'applications']"
          routerLinkActive="active-link"
          [matTooltipDisabled]="!showApplicationTooltip"
          [matTooltip]="
            'You can access your shortlisted programs\nfrom here at any time'
          "
          matTooltipPosition="below"
          matTooltipClass="pave-tooltip pave-dark-tooltip bottom-arrow-dark-tooltip"
        >
          My Applications
        </div>

        <div class="divider"></div>
      </ng-container>

      <div
        class="search-wrapper pointer"
        (click)="
          amplitudeTrackerService.NavSearchButtonClicked();
          searchActionClicked()
        "
      >
        <img
          src="../../../../assets/images/search.png"
          alt="search-icon"
          class="search-icon"
        />
      </div>

      <!-- HAMBURGER MENU -->
      <div class="hamburger-menu pointer" (click)="toggleSidebar()">
        <div class="menu menu-top"></div>
        <div class="menu"></div>
        <div class="menu menu-bottom"></div>
      </div>

      <ng-container *ngIf="isLoggedIn(); else unAuthenticatedView">
        <div
          class="user-auth-card"
          [matMenuTriggerFor]="isMobileView ? null : userMenu"
          (click)="isMobileView ? toggleSidebar() : null"
        >
          <!-- USER IMAGE -->
          <div class="user-image">
            <img
              *ngIf="getProfileImage() !== 'null'"
              [src]="getProfileImage() | presignedView | async"
              [alt]="getProfileName()"
            />
            <mat-icon *ngIf="getProfileImage() === 'null'">person</mat-icon>
          </div>

          <!-- HAMBURGER MENU -->
          <mat-icon>menu</mat-icon>
        </div>
      </ng-container>

      <!-- TOPBAR DROPDOWN -->
      <mat-menu #userMenu="matMenu" class="customize" [xPosition]="'before'">
        <app-base-topbar-dropdown></app-base-topbar-dropdown>
      </mat-menu>
    </div>
  </div>
</div>

<!-- BASE SIDEBAR -->
<app-base-sidebar
  *ngIf="showSidebar"
  [isLoggedIn]="isLoggedIn()"
  [productOptions]="productOptions"
  [partnerOptions]="partnerOptions"
  [userFullName]="getProfileName()"
  [userEmail]="getProfileEmail()"
  [userAvatar]="getProfileImage()"
  (closeTriggered)="toggleSidebar()"
></app-base-sidebar>

<!-- SEARCH DIALOG -->
<app-search-dialog
  *ngIf="showSearchBar"
  [pageSource]="currentRoute"
  (closeTriggered)="showSearchBar = false"
></app-search-dialog>

<ng-template #unAuthenticatedView>
  <div
    class="pave-btn pave-btn-neutral login-btn"
    [routerLink]="['/', 'session', 'log-in']"
    [queryParams]="{ returnUrl: getPageUrl() }"
    (click)="amplitudeTrackerService.NavLoginButtonClicked()"
  >
    Login
  </div>
  <button
    class="pave-btn pave-btn-primary register-btn"
    [routerLink]="['/', 'session', 'sign-up']"
    [queryParams]="{ returnUrl: getPageUrl() }"
    (click)="amplitudeTrackerService.NavRegisterButtonClicked()"
  >
    Register
  </button>
</ng-template>
