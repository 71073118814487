import { Component, OnInit, Optional, Inject, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, map, filter, groupBy, flatMap, mergeMap, toArray, tap, reduce, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SchoolService } from 'src/app/services/school/school.service';
import { Discipline, Location, LocationSearchResponse } from 'src/app/models/api/search';
import { Observable, of, zip } from 'rxjs';
import { group } from '@angular/animations';
import { SearchService } from 'src/app/services/search/search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Input() isMinimal = true;
  @Input() showLocationInput: boolean = true;
  @Input() source;

  courseParam = 'program';
  locationParam = 'country';
  form: FormGroup;
  filteredLocations: LocationSearchResponse[];
  filteredPrograms: LocationSearchResponse[];

  filteredAutocomplete: string[];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private schoolService: SchoolService,
    @Optional() public dialogRef: MatDialogRef<SearchBarComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data
  ) {
    this.form = this.formBuilder.group({
      level: [null, []],
      program: [null, []],
      location: [null, []],
      budget: [null, []]
    });

    if (data) {
      this.source = data.source;
    }
  }

  ngOnInit() {
    const groupBy = (data: Location[] | Discipline[], keyFn) => data.reduce((agg, item) => {
      const group: string = keyFn(item);
      agg[group] = [...(agg[group] || []), item];
      return agg;
    }, {});

    this.form.controls.location.valueChanges.pipe(
      debounceTime(300),
      switchMap(locationString => this.searchService.getLocations({ name: locationString?.toLocaleLowerCase() }))
    ).subscribe(locations => {
      const locationsByType = groupBy(locations, location => location.type);
      this.filteredLocations = Object.keys(locationsByType).filter(k => ['Country', 'school'].includes(k)).map(k =>
        ({ type: k, results: locationsByType[k].map(k => k.name) })
      );
    });

    this.form.controls.program.valueChanges.pipe(
      tap(q => { if (!q) this.filteredAutocomplete = [] }),
      filter(q => q),
      flatMap(queryString => this.searchService.getAutocompleteResults(queryString?.trim().toLocaleLowerCase()))
    )
      .subscribe(response => {
        this.filteredAutocomplete = response.data.map(d => d['_id']);

        // const programsByType = groupBy(disciplines, discipline => discipline.type);
        // if (programsByType['course']) {
        //   const courseList: Discipline[] = [programsByType['course'][0]];

        //   for (let i=1; i<programsByType['course'].length-1; i++) {
        //     console.log(programsByType['course'][i]);

        //     if (!programsByType['course'][i].name.includes(courseList[courseList.length-1].name)) {
        //       courseList.push(programsByType['course'][i]);
        //     }
        //   }

        //   programsByType['course'] = courseList;
        // }

        // this.filteredPrograms =  Object.keys(programsByType).map(k => 
        //   ({type: k, results: programsByType[k].map(k => k.name)})
        // );
      });
  }

  doSearch() {
    if (!this.form.valid) {
      // console.log('invalid form');
      return;
    }

    const queryParams = this.form.value;

    if (queryParams.budget) {
      [queryParams.minAmount, queryParams.maxAmount] = queryParams.budget.split("-");
      delete queryParams.budget;
    }

    const tCourse = queryParams.program;
    delete queryParams.program;
    queryParams[this.courseParam] = tCourse;

    const tLocation = queryParams.location;
    delete queryParams.location;
    queryParams[this.locationParam] = tLocation;

    this.router.navigate(['/', 'search'], { queryParams: { ...queryParams, source: this.source } });

    if (this.dialogRef !== null) {
      this.dialogRef.close();
    }
  }

  programSelectionChanged() {
    this.courseParam = 'program';
    // if (type === 'course') {
    //   this.courseParam = 'program';
    // } else if (type === 'department') {
    //   this.courseParam = 'department';
    // }
  }

  locationSelectionChanged(type: string) {
    if (type === 'country') {
      this.locationParam = 'country';
    } else if (type === 'school') {
      this.locationParam = 'institution';
    }
  }

}
