import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent implements OnInit {
  @Input() pageSource: string;
  @Output() closeTriggered = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  closeDialog() {
    this.closeTriggered.emit();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeTriggered.emit();
    }
  }
}
