import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, flatMap } from 'rxjs/operators';
import { UserRegistrationRequest, UserRole } from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStoreService } from 'src/app/services/local-store/local-store.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  UserRole = UserRole;

  form: FormGroup;
  passwordFieldType = 'password';
  loading = false;
  role = UserRole.Student;

  emailPattern =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z0-9.-]+)?$/;
  supportedTLDs = 'com|org|net|edu|gov|co|io|name|info|biz|me|team';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private localStoreService: LocalStoreService,
    private notificationService: NotificationService,
  ) {
    this.form = this.formBuilder.group(
      {
        fullName: [null, [Validators.required, ValidNameMatcher]],
        email: [
          null,
          [
            Validators.required,
            Validators.email,
            this.customEmailValidator.bind(this),
          ],
        ],
        username: [null, [Validators.required, Validators.minLength(3)]],
        password: [null, [Validators.required, Validators.minLength(8)]],
        repeat: [null, []],
        referredBy: [null, []],
      },
      {
        // validator: this.checkPasswords
      },
    );
  }

  ngOnInit() {
    const referralCode = this.route.snapshot.queryParams.referralCode;

    if (referralCode) {
      this.form.get('referredBy').setValue(referralCode);
    }

    this.form.get('email').valueChanges.subscribe((value) => {
      const usernameValue = this.form.get('username').value;
      const noUsernameSet = this.form.get('username').pristine;

      if (noUsernameSet || !usernameValue || value.startsWith(usernameValue)) {
        this.form.get('username').setValue(value.split('@')[0]);
        this.form.get('username').markAsTouched();
      }
    });
  }

  extractTLD(email: string) {
    const domain = email?.split('@')[1];
    const tld = domain && domain.split('.').pop();
    return tld || null;
  }

  customEmailValidator(control: AbstractControl): ValidationErrors | null {
    const email = control.value as string;

    let isValidEmailPattern = this.emailPattern.test(email);
    let isValidEmailTLD = this.supportedTLDs
      .split('|')
      .includes(this.extractTLD(email));

    if (!isValidEmailPattern) return { invalidEmail: true };
    else if (!isValidEmailTLD) return { invalidEmail: true };
    else return null;
  }

  validateUserRegistration() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const registrationRequest: UserRegistrationRequest = {
      ...this.form.value,
    };
    const fullName = this.form.get('fullName').value ?? '';
    const splitFullName = fullName.trim().split(' ');

    if (splitFullName.length < 2) {
      this.form.markAllAsTouched();
      return;
    }

    registrationRequest.firstName = splitFullName[0];
    registrationRequest.lastName = splitFullName[splitFullName.length - 1];
    registrationRequest.role = this.role;

    // Get utm parms
    if (
      Object.keys(this.localStoreService.getItem('utmParams') ?? {}).length > 1
    )
      registrationRequest.referredBy = this.getUTMParams();
    else registrationRequest.referredBy = this.form.value.referredBy;

    this.register(registrationRequest);
  }

  getUTMParams() {
    const { time_expiry, utm_campaign } =
      this.localStoreService.getItem('utmParams');

    if (!utm_campaign) return null;

    const current_timestamp = new Date().getTime();
    const validity_period = this.getDifferenceInDays(
      current_timestamp,
      time_expiry,
    );

    return validity_period <= 3 ? utm_campaign : null;
  }

  getDifferenceInDays(currentTimestamp: number, prevTimestamp: number) {
    // Convert timestamps to Date objects
    const currentTime: any = new Date(currentTimestamp);
    const prevTime: any = new Date(prevTimestamp);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(currentTime - prevTime);

    // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24),
    );

    return differenceInDays;
  }

  removeUserItemsFromLocalStorage() {
    const keys = Object.keys(localStorage);

    keys
      .filter((key) => key.startsWith('user_'))
      .forEach((key) => localStorage.removeItem(key));
  }

  register(registrationRequest: any) {
    this.removeUserItemsFromLocalStorage();

    this.loading = true;

    this.authService
      .registerUser(registrationRequest)
      .pipe(
        flatMap(() =>
          this.authService.login({
            username: registrationRequest.email,
            password: registrationRequest.password,
          }),
        ),
        finalize(() => {
          this.loading = false;
          this.localStoreService.removeItem('utmParams');
        }),
        catchError((err) => {
          if (err.status === 409) {
            this.notificationService
              .showError(
                'An account with this email already exists. To continue, please login',
                5000,
                'Login',
              )
              .subscribe((_) => {
                this.router.navigateByUrl('/session/log-in');
              });
          }

          if (
            err.status === 400 &&
            err.error.message.includes('requires email validation')
          ) {
            const { message } = err.error;
            const userEmail = registrationRequest.email;

            this.authService.handleUserEmailValidation(message, userEmail);
          }

          if (
            err.status === 400 &&
            err.error.message.includes('username already exists')
          ) {
            this.notificationService.showError(err.error.message);
          }

          // A user with this username already exists
          throw err;
        }),
      )
      .subscribe(() => {
        const returnUrl = this.route.snapshot.queryParams.returnUrl;
        this.router.navigateByUrl(returnUrl);
      });
  }

  checkPasswords(form: FormGroup) {
    const pass = form.controls.password.value;
    const confirmPass = form.controls.repeat.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  get email() {
    return this.form.get('email');
  }
  get username() {
    return this.form.get('username');
  }
}

export function ValidNameMatcher(control: AbstractControl) {
  const splitFullName = (control.value ?? '').trim().split(' ');
  if (
    splitFullName.length < 2 ||
    splitFullName[0].length < 1 ||
    splitFullName[splitFullName.length - 1].length < 1
  ) {
    return {
      invalidName: true,
    };
  }

  return null;
}
