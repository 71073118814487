<div
  class="sidebar"
  #sidebarMenu
  [ngClass]="minimizeSidebarElement ? 'minimize' : ''"
  (mouseenter)="onMouseEnter($event.target)"
  (mouseleave)="onMouseOut($event.target)"
>
  <div class="sidebar-container" fxFill fxLayout="column">
    <!-- HEADER LOGO -->
    <div class="header-container">
      <a
        class="header_logo header-logo-shrink"
        [routerLink]="['/', getBaseURLPath, 'dashboard']"
      ></a>
    </div>

    <div
      class="sidebar-menu-items"
      fxLayout="column"
      fxFlex
      fxLayoutAlign="space-between"
    >
      <div class="top-section">
        <ng-container *ngFor="let menu of filteredMenu">
          <div
            class="sidebar-menu-item"
            [ngClass]="userIsAdmin ? menu.link : menu.icon"
            [routerLink]="['/', getBaseURLPath, menu.link]"
            [routerLinkActive]="'active'"
          >
            <div class="text">
              {{ menu.title }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="bottom">
        <div class="view-state pointer" (click)="toggleSidebar()">
          {{ isMinimized ? 'Keep maximized' : 'Allow minimize' }}
        </div>

        <div class="sidebar-menu-item signout" (click)="logout()">
          <div class="text">Sign out</div>
        </div>
      </div>
    </div>
  </div>
</div>
