import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { ApplicationService } from '../application/application.service';
import { regenerateCurrencyListFromBEData } from 'src/app/modules/shared/currency-selector/currencies';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateService {
  private scheduler = timer(0, 60_000 * 60 * 30);

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService
  ) {}

  pollExchangeRates() {
    this.scheduler.subscribe(() =>
      this.fetchExchangeRates()
        .then((_) => {
          // console.log('fetched rates', localStorage.getItem('currencyRatios'));
        })
        .catch((error) => {
          console.log('failed polling rates', error);
        })
    );
  }

  fetchExchangeRates(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.applicationService.fetchExchangeRates().subscribe(
        (response) => {
          const currencyList = regenerateCurrencyListFromBEData(
            response?.rates ?? {}
          );
          localStorage.setItem('currencyRatios', JSON.stringify(currencyList));
          resolve(true);
        },
        (error) => {
          console.log('error loading initial rates', error);
          resolve(false);
        }
      );
    });
  }
}
