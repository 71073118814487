<div>
  <div class="container" fxLayout="column" fxFlex>
    <div class="header">
      <div class="padded" fxLayout="row" fxLayoutAlign="space-between center">
        <a class="header_logo" [routerLink]="['/']"></a>
      </div>
    </div>
  </div>
</div>

<div class="wrapper" fxLayout="column" fxLayoutAlign="center center">
  <div class="container" fxLayout="column" fxLayoutAlign=" center">
    <app-root></app-root>
  </div>
</div>