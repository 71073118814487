import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { CaregiverPathwayLayoutComponent } from './layouts/caregiver-pathway-layout/caregiver-pathway-layout.component';
import { FlexibleLayoutComponent } from './layouts/flexible-layout/flexible-layout.component';
import { FullscreenLayoutComponent } from './layouts/fullscreen-layout/fullscreen-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { AgentOperativeGuardService } from './services/guards/agent-operative-guard/agent-operative-guard.service';
import { AgentRouteGuard } from './services/guards/agent-route-guard/agent-route-guard.service';
import { AuthGuardService } from './services/guards/auth-guard/auth-guard.service';
import { ReloadGuard } from './services/guards/reload-guard/reload-guard.service';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AgentRouteGuard],
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule,
      ),
  },
  {
    path: 'old',
    component: HomeLayoutComponent,
    canActivate: [ReloadGuard],
    canActivateChild: [ReloadGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: 'search',
    component: HomeLayoutComponent,
    canActivate: [ReloadGuard, AgentOperativeGuardService, AgentRouteGuard],
    canActivateChild: [ReloadGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/search/search.module').then((m) => m.SearchModule),
      },
    ],
  },
  {
    path: 'school',
    component: HomeLayoutComponent,
    canActivate: [ReloadGuard, AgentRouteGuard],
    canActivateChild: [ReloadGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/school/school.module').then((m) => m.SchoolModule),
      },
    ],
  },
  {
    path: 'country',
    component: FlexibleLayoutComponent,
    canActivate: [ReloadGuard, AgentRouteGuard],
    canActivateChild: [ReloadGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/country/country.module').then(
            (m) => m.CountryModule,
          ),
      },
    ],
  },
  // {
  //   path: 'services/student',
  //   component: HomeLayoutComponent,
  //   canActivate: [ReloadGuard],
  //   canActivateChild: [ReloadGuard],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./modules/student/student.module').then(m => m.StudentModule)
  //     }
  //   ]
  // },
  // {
  //   path: 'user/messaging',
  //   component: FullscreenLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./modules/messaging/messaging.module').then(m => m.Mess)
  //     }
  //   ]
  // },
  {
    path: 'user',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'messaging',
        component: FullscreenLayoutComponent,
        loadChildren: () =>
          import('./modules/messaging/messaging.module').then(
            (m) => m.MessagingModule,
          ),
      },
      {
        path: '',
        component: FlexibleLayoutComponent,
        loadChildren: () =>
          import('./modules/user/user.module').then((m) => m.UserModule),
      },
    ],
  },
  {
    path: 'contact',
    data: {
      title: 'Contact Us',
    },
    canActivate: [ReloadGuard],
    canActivateChild: [ReloadGuard],
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/contact/contact.module').then(
            (m) => m.ContactModule,
          ),
      },
    ],
  },
  {
    path: 'session',
    canActivate: [ReloadGuard],
    canActivateChild: [ReloadGuard],
    component: SessionLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/session/session.module').then(
            (m) => m.SessionModule,
          ),
      },
    ],
  },
  {
    path: 'information',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/information/information.module').then(
            (m) => m.InformationModule,
          ),
      },
    ],
  },
  {
    path: 'operative-agent',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: FlexibleLayoutComponent,
        loadChildren: () =>
          import('./modules/agent-operative/agent-operative.module').then(
            (m) => m.AgentOperativeModule,
          ),
      },
    ],
  },

  {
    path: 'affiliate-agent',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: AdminLayoutComponent,
        loadChildren: () =>
          import('./modules/agent-affiliate/agent-affiliate.module').then(
            (m) => m.AgentAffiliateModule,
          ),
      },
    ],
  },

  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: 'demo',
    // component: FlexibleLayoutComponent,
    canActivate: [ReloadGuard],
    canActivateChild: [ReloadGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/utility-demo/utility-demo.module').then(
            (m) => m.UtilityDemoModule,
          ),
      },
    ],
  },
  {
    path: 'caregiver',
    pathMatch: 'full',
    redirectTo: 'caregiver-pathway',
  },
  {
    path: 'caregiver-pathway',
    component: CaregiverPathwayLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/caregiver-pathway/caregiver-pathway.module').then(
            (m) => m.CaregiverPathwayModule,
          ),
      },
    ],
  },
];

const routerOptions: ExtraOptions = {
  // useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 96],
  preloadingStrategy: PreloadAllModules,
  relativeLinkResolution: 'corrected',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
