import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SearchBarComponent } from 'src/app/modules/shared/search-bar/search-bar.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BuildDetailsService } from 'src/app/services/background/build-details.service';
import { UserModule } from 'src/app/modules/user/user.module';
import { UserRole } from 'src/app/models/api/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
  animations: [
    trigger('toggleMenu', [
      state('closed', style({
        height: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      })),
      state('open', style({
        height: 'auto',
      })),
      transition('closed=>open', animate('300ms')),
      transition('open=>closed', animate('300ms'))
    ])
  ]
})
export class HomeLayoutComponent implements OnInit {

  isSticky = false;
  isLoggedInStatic = false;
  isMobileMenuOpen = false;
  isMobileNotificationsOpen = false;
  isDesktopSearchOpen = false;

  isLandingPage = false;
  isSchoolPage = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 80;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private buildDetailsService: BuildDetailsService,
    private elementRef: ElementRef,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isLoggedInStatic = this.authService.isAuthenticated();    
    this.isLandingPage = this.getPageUrl() === '/';
    this.isSchoolPage = this.getPageUrl().includes('/school/');
  }

  isLoggedIn() {
    return this.authService.isAuthenticated();
  }

  isAgent() {
    return this.isLoggedIn() && this.authService.getUserRole() === UserRole.Agent;
  }

  logout() {
    this.authService.logout();

    if (this.isMobileMenuOpen) {
      this.isMobileMenuOpen = false;
    }
    
    this.router.navigateByUrl('/');
  }

  openDesktopSearch() {
    this.isDesktopSearchOpen = true;
    this.elementRef.nativeElement.style.setProperty('--header-size', '248px');
  }

  closeDesktopSearch() {
    this.isDesktopSearchOpen = false;
    this.elementRef.nativeElement.style.setProperty('--header-size', '128px');
  }

  scrollToSearch() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  openSearch() {
    const dialogRef = this.dialog.open(SearchBarComponent, {
      width: '100%'
    });
  }

  getProfileName() {
    return this.authService.getUserFirstName().charAt(0).toUpperCase();
  }

  getProfileImage() {
    return this.authService.getUserImage();
  }

  getPageUrl() {
    return new URL(document.URL).pathname;
  }

  get isDevelopmentBuild() {
    return !environment.production;
  }
  
  get buildVersion() {
    return this.buildDetailsService.getBuildVersion();
  }

}
