import { Component, OnInit, Input, HostListener } from '@angular/core';
import { menuOptionsType } from 'src/app/models/common';

@Component({
  selector: 'app-base-topbar-navitem',
  templateUrl: './base-topbar-navitem.component.html',
  styleUrls: ['./base-topbar-navitem.component.scss'],
})
export class BaseTopbarNavitemComponent implements OnInit {
  @Input() menuItem: string;
  @Input() menuItemLink: string[];
  @Input() hasMenuOptions: boolean = false;
  @Input() menuOptions: menuOptionsType[];

  pinnedNavbar: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.pinnedNavbar = window.scrollY > 50;
  }
}
