<div
  class="search-preview-card rounded-bottom-30 smooth-animation"
  [ngClass]="searchPosition === 'relative' && 'search-full-page'"
>
  <ng-container *ngIf="!isLoading; else loadingView">
    <ng-container *ngIf="searchResult.length; else emptyView">
      <div
        class="search-row pointer"
        *ngFor="let search of searchResult"
        (click)="emitSelectedFilter(search)"
      >
        <div class="search-result">
          {{ search.name }}
        </div>
        <div
          class="search-category rounded-24"
          [ngClass]="search.type + '-category'"
        >
          {{ search.type }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingView>
  <div class="loading-row">
    <img
      src="../../../../../assets/images/spinner-icon.svg"
      alt="spinner icon"
    />
    <div class="text f-size-13 footer-fill">Loading Search</div>
  </div>
</ng-template>

<ng-template #emptyView>
  <div class="empty-row">
    <div class="text f-size-13 footer-fill">No Result Found</div>
  </div>
</ng-template>
