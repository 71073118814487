<div fxLayout="column" class="band" fxLayoutAlign="center center" fxLayoutGap="16px" ngClass.lt-md="mobile">
  <div class="forgot-password-icon"></div>
  <h2 style="letter-spacing: -0.03em;">Forgot Password</h2>
  <div style="text-align: center;">
    Please enter the email associated with your Pave account. 
    We will send a link to this email address to reset your password.
  </div>
  
  <form [formGroup]="form" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center" id="request-reset-password" (submit)="reset()">
    <mat-form-field>
      <mat-label>Enter your email</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>

    <div fxLayout="column" fxLayoutGap="16px" fxLayout="column" fxLayoutAlign=" center">
      <button mat-raised-button color="primary" class="studypath-button">Reset Password</button>
      <button mat-button color="primary" class="studypath-button" [routerLink]="['/','session','log-in']">Return to Login</button>
    </div>
  </form>
</div>