import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cg-menu',
  templateUrl: './cg-menu.component.html',
  styleUrls: ['./cg-menu.component.scss']
})
export class CgMenuComponent implements OnInit {

  @Output() sectionOpened: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  openSection() {
    this.sectionOpened.emit();
  }
  
  getPageUrl() {
    return new URL(document.URL).pathname;
  }

  showDescription() {
    return this.getPageUrl().includes('/continue');
  }
}
