import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FooterLinkDialogComponent } from 'src/app/modules/shared/footer-link-dialog/footer-link-dialog.component';

@Component({
  selector: 'app-base-footer',
  templateUrl: './base-footer.component.html',
  styleUrls: ['./base-footer.component.scss'],
})
export class BaseFooterComponent implements OnInit {
  selectedCurrency: any;
  currentYear = new Date();

  serviceLinks = [
    {
      title: 'Pay for tuition',
      link: 'https://tuition.pavehq.com/',
    },
    {
      title: 'Trainings',
      link: '',
    },
    // {
    //   title: 'CIC',
    //   link: '',
    // },
    {
      title: 'Visa',
      link: '',
    },
    {
      title: 'Accomodation',
      link: '',
    },
    // {
    //   title: 'UKCG',
    //   link: '',
    // },
    {
      title: 'Internships',
      link: '',
    },
  ];

  quickLinks = [
    {
      title: 'Terms and Conditions',
      link: '/information/terms-and-conditions',
    },
    {
      title: 'Privacy Policy',
      link: '/information/privacy-policy',
    },
    {
      title: 'Cookies Policy',
      link: '/information/cookie-policy',
    },
    // {
    //   title: 'In-Country Marketing',
    //   link: '',
    // },
  ];

  supportLinks = [
    {
      title: 'FAQs',
      link: '/information/faqs',
    },
    {
      title: 'Contact Us',
      link: '/contact',
    },
  ];

  currentScreenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  constructor(
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  get isMobileView() {
    return this.currentScreenWidth < 860 ? true : false;
  }

  getModalDetail() {
    return {
      serviceLinks: {
        title: 'Services',
        links: this.serviceLinks,
      },
      quickLinks: {
        title: 'Quicklinks',
        links: this.quickLinks,
      },
      supportLinks: {
        title: 'Support',
        links: this.supportLinks,
      },
    };
  }

  triggerModalView(linkType: string) {
    if (this.isMobileView) {
      this.dialog.open(FooterLinkDialogComponent, {
        width: '500px',
        data: {
          title: this.getModalDetail()[linkType].title,
          description: this.getModalDetail()[linkType].description,
          dataset: this.getModalDetail()[linkType].links,
        },
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.currentScreenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }
}
