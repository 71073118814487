import { CdkTreeNode, FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatTree, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { share } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification/notification.service';

interface MenuNode {
  name: string;
  stub?: string;
  link?: string;
  fragment?: string;
  children?: MenuNode[];
  complete?: boolean;
}

/** Flat node with expandable and level information */
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  stub: string;
  complete: boolean;
}

@Component({
  selector: 'app-profile-sidebar',
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.scss']
})
export class ProfileSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild("matTree", {static: true}) matTree: MatTree<any, any>;
  @Input() anchorPositions;
  @Output() nodeClicked = new EventEmitter<string>();

  sourceRoute;
  sourceRouter;
  relativePath;
  activeFragment = this.route.fragment.pipe(share());
  activeFragmentSync = '';
  activeParentFragmentSync = '';

  saving = false;
  saved = false;

  private _transformer = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      stub: node.stub,
      link: node.link,
      fragment: node.fragment,
      complete: node.complete
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  get percentageComplete() {
    const bools = [];
    this.dataSource.data.forEach(node => {
      node.children.forEach(child => bools.push(child.complete));
    });

    return bools.filter(b => b).length / bools.length * 100;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    @Optional() private dialogRef: MatDialogRef<ProfileSidebarComponent> 
  ) {
    this.dataSource.data = [];
  }

  ngOnInit() {
    this.relativePath = (this.sourceRouter || this.router).url.split('/').slice(0,-1).reduce((a,b)=> a+'/'+b);
    console.log('dialog route', this.sourceRoute, this.router, this.route, this.relativePath);
    if (this.sourceRouter) {
      this.router = this.sourceRouter;
    }

    
    this.notificationService.saving.subscribe(value => {
      this.saving = value;
      if (!value) {
        this.saved = true;
        setTimeout(() => this.saved = false, 3000);
      }
    });
  }
  
  ngAfterViewInit() {
    this.activeFragment.subscribe(fragment => {
      this.updateFragment(fragment, true);
    });
  }
  
  toggleTreeNode(node: CdkTreeNode<any, any>) {
    const opened = this.matTree.treeControl.isExpanded(node)
    this.matTree.treeControl.collapseAll();
    if (!opened) this.matTree.treeControl.expand(node);
  }

  updateFragment(fragment, scroll=true) {
    if (!fragment || !this.matTree.treeControl) return;

    this.activeFragmentSync = fragment;
    this.matTree.treeControl.collapseAll();

    if (fragment.startsWith('personal')) {
      this.activeParentFragmentSync = 'personalInformation';
      this.matTree.treeControl.expand(this.treeControl.dataNodes.find(n => n.stub === 'personalInformation'));
      if (scroll) this.nodeClicked.emit(fragment);
    } else if (fragment.startsWith('academic')) {
      this.activeParentFragmentSync = 'academicInformation';
      this.matTree.treeControl.expand(this.treeControl.dataNodes.find(n => n.stub === 'academicInformation'));
      if (scroll) this.nodeClicked.emit(fragment);
    } else if (fragment.startsWith('supporting')) {
      this.activeParentFragmentSync = 'supportingDocuments';
      this.matTree.treeControl.expand(this.treeControl.dataNodes.find(n => n.stub === 'supportingDocuments'));
      if (scroll) this.nodeClicked.emit(fragment);
    }
  }

  updateData(replacementNode) {
    const index = this.dataSource.data.findIndex(a => a.stub.startsWith(replacementNode.stub));
    this.dataSource.data[index] = replacementNode;
    this.dataSource.data = this.dataSource.data;
    this.updateFragment(this.activeFragmentSync, false);
  }

  checkDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  
  hasChild = (_: number, node: FlatNode) => node.expandable;
}
