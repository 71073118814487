

<button fxHide.gt-md mat-icon-button mat-dialog-close class="close-button">
  <mat-icon>close</mat-icon>
</button>
<div fxHide.lt-md class="saving-bar" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center">
  <mat-spinner *ngIf="saving" diameter="16" strokeWidth="2" color="primary"></mat-spinner>
  <div *ngIf="saving" class="text-mobile-smallest-r">Saving</div>
  <mat-icon *ngIf="saved" class="success" style="font-size: 16px; width: 16px; height: 16px">check_circle</mat-icon>
  <div *ngIf="saved" class="text-mobile-smallest-r">Saved</div>
</div>
<div fxLayout="column" fxLayoutGap="32px" ngStyle.lt-md="padding:64px 16px">
  <div fxHide.gt-sm fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign=" center">
      <div class="text-mobile-body-2-m">Profile Strength:</div>
      <div fxFlex fxLayout="column">
        <div class="success">{{percentageComplete | number:'0.0-0'}}%</div>
        <mat-progress-bar class="with-track" mode="determinate" [value]="percentageComplete" color="primary"></mat-progress-bar>
      </div>
    </div>
    <div class="text-mobile-smallest-r">
      A complete profile helps us process your applications faster, and allows us make better program and career recommendations for you
    </div>
    <mat-divider></mat-divider>
  </div>
  <div class="profile-links" fxLayout="column">
    <mat-tree #matTree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <mat-icon class="node-complete-status" [class.complete]="node.complete === true" [class.incomplete]="!node.complete">
          {{ node.complete ? 'check_circle' : 'error' }}
        </mat-icon>
        <a [routerLink]="[this.relativePath, node.link]" class="tree-anchor" [class.active]="activeFragmentSync?.includes(node.stub)" [fragment]="node.fragment" (click)="checkDialog()">
          {{node.name}}
        </a>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <div class="tree-header" [class.active]="activeParentFragmentSync?.includes(node.stub)" fxFlex fxLayout="row" fxLayoutAlign="space-between center" (click)="toggleTreeNode(node)">
          <div fxLayout="row" fxLayoutAlign=" center">
            <mat-icon class="node-complete-status parent" [class.complete]="node.complete === true" [class.incomplete]="!node.complete">
              {{ node.complete ? 'check_circle' : 'error' }}
            </mat-icon>
            {{node.name}}
          </div>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
          </mat-icon>
        </div>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>