<div class="wrapper">
    <div class="container" fxLayout="column" ngClass.gt-sm="desktop">
        <div class="header" fxLayout="row" fxLayout=" center" [ngClass]="{'landing-header': isLanding() && !isSticky}">
            <div class="header-content" fxLayout="row" fxLayoutAlign="space-between center">      
                <a class="header_logo"></a>
                
                <div fxLayout="column" fxLayoutAlign="center center" [ngClass]="{'error': errorSaving, 'success': !errorSaving}">
                    <mat-progress-spinner *ngIf="saving || errorSaving" [ngClass]="{'error': errorSaving}" mode="indeterminate" diameter="18" strokeWidth="2"></mat-progress-spinner>
                    <div *ngIf="saving && !errorSaving" style="font-size: x-small;">Saving</div>
                    <div *ngIf="errorSaving" style="font-size: x-small;">Trying to save</div>
                </div>
            </div>
        </div>
      
        <div class="inner-container" fxLayout="row" [ngClass]="{'landing-header': isLanding()}">
            <div fxFlex="30" class="sidebar" fxHide.lt-md [fxHide]="hideSidebar()">
                <app-cg-menu></app-cg-menu>
            </div>
            <div  fxLayout="column" fxLayoutAlign.lt-md="start" fxLayoutAlign.gt-sm="center" class="page-content-wrapper" [ngClass]="{'landing-header': isLanding()}">
                <div *ngIf="!hideMenu()" fxHide.gt-sm fxLayout="row" fxLayoutAlign=" center" style="margin-bottom: 16px;">
                    <button mat-icon-button (click)="opened=true">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <div style="text-transform: capitalize;">
                        {{sectionName}}
                    </div>
                </div>
                <div class="content" fxLayout="column" fxLayoutAlign="center" [ngClass]="{'landing-header': isLanding()}">
                    <app-root></app-root>
                </div>
            </div>
        </div>
    </div>
</div>


<mat-sidenav-container class="sidenav-container" style="background: transparent;" [ngClass]="{'opened': opened}">
    <mat-sidenav style="width: 80%;" #sidenav mode="over" [(opened)]="opened" (opened)="opened=true" (closed)="opened=false" autoFocus="false" fixedInViewport="true">
        <app-cg-menu (sectionOpened)="sidenav.close()"></app-cg-menu>
    </mat-sidenav>
</mat-sidenav-container>
