import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum Layouts {
  HomeLayout,
  FullscreenLayout,
}

@Component({
  selector: 'app-flexible-layout',
  templateUrl: './flexible-layout.component.html',
  styleUrls: ['./flexible-layout.component.scss'],
})
export class FlexibleLayoutComponent implements OnInit, OnDestroy {
  Layouts = Layouts;
  layout: Layouts;
  termination = new Subject<boolean>();

  constructor(private router: Router) {}

  ngOnInit() {
    const routeData = this.getCurrentRouteData();
    this.layout = routeData.layout || Layouts.HomeLayout;

    // subscribe to page load event
    this.router.events.pipe(takeUntil(this.termination)).subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const baseComponent = this.getBaseComponent(data.state.root.firstChild);
        this.layout = baseComponent.data.layout || Layouts.HomeLayout;
      }
    });
  }

  ngOnDestroy() {
    this.termination.next(true);
    this.termination.complete();

    // ON EXIT OFF PAGE
    if (!window.performance.navigation.type) {
      localStorage.removeItem('active-flexible-layout');
    }
  }

  getBaseComponent(component: ActivatedRouteSnapshot) {
    if (component.firstChild) {
      return this.getBaseComponent(component.firstChild);
    }

    return component;
  }

  private getCurrentRouteData(): any {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data;
  }
}
