<div class="topbar-dropdown rounded-16 ht-auto">
  <div class="user-info-block">
    <div class="image-wrapper">
      <img
        *ngIf="getProfileImage() !== 'null'"
        [src]="getProfileImage() | presignedView | async"
        alt=""
      />
      <mat-icon *ngIf="getProfileImage() === 'null'">person</mat-icon>
    </div>

    <div>
      <div class="full-name pave-text">{{ getProfileName() }}</div>
      <div class="email pave-text">
        {{ getProfileEmail() }}
      </div>
    </div>
  </div>

  <div class="nav-item" routerLink="/user/profile">Profile</div>

  <div
    *ngIf="isOperativeAgent"
    class="nav-item"
    routerLink="/operative-agent/dashboard"
  >
    Dashboard
  </div>

  <div class="nav-item" (click)="logOutUser()">Logout</div>
</div>
