import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../notification/notification.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    request = this.addAuth(authService, request);

    return next.handle(request).pipe(
      tap((event) => console.log('event', event, request)),
      // retry(1),
      // tap(_ => console.log('try xxxxxxxxxxx', request)),
      // flatMap(_ => next.handle(this.addAuth(authService, request))),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          console.log('catch event error err', err);
          console.log('catch event error request', request);

          switch (err.status) {
            case 401: {
              console.log('seems like an auth error');

              const referer = err.headers.has('referer')
                ? err.headers.get('referer').replace(/^.*\/\/[^\/]+/, '')
                : '';

              if (
                !err.url.includes('refresh-token') &&
                authService.getAccessToken()
              ) {
                console.log('seems like auth is unavailble or expired');

                return authService.refreshAccessTokenForUser().pipe(
                  tap((_) => console.log('doing refresh')),
                  switchMap((_) =>
                    next.handle(this.addAuth(authService, request))
                  ),
                  catchError((error) => {
                    const wasLoggedIn = authService.getAccessToken();

                    localStorage.removeItem('user_accessToken');
                    // authService.logout();
                    // this.router.navigateByUrl(referer);
                    // this.router.navigate(['/', 'session', 'log-in'], {
                    //   queryParams: {
                    //     returnUrl: referer,
                    //     message: wasLoggedIn ? "session expired" : "auth blocked"
                    //   }
                    // });

                    request = this.removeAuth(request);

                    return next.handle(request);
                  })
                );
              } else {
                const wasLoggedIn = authService.getAccessToken();

                // this.router.navigateByUrl(referer);
                this.router.navigate(['/', 'session', 'log-in'], {
                  queryParams: {
                    returnUrl: referer,
                    message: wasLoggedIn ? 'session expired' : 'auth blocked',
                  },
                });
              }

              return throwError(err);
            }
            case 403:
              throw new Error('not authorized');
            case 404:
              throw err;
            case 409:
              throw err;

            case 400: {
              console.log('ref error', err, request);

              if (!request.url.includes('refresh-token')) {
                // this.notificationService.showError(err.error.message || err.error.error);
              } else {
                console.log('refresh token invalid. clear auth and retry');
                authService.logout();
              }

              throw err;
            }
            case 500: {
              console.error(
                '500 err',
                err.status,
                err.message,
                err.error.error
              );
              this.notificationService.showError(
                err.error.message || err.error.error
              );
              throw new Error('server error');
            }
            case 0:
              throw err;
            default:
              console.error('default err int', err.status, err.message);
              this.notificationService.showError(
                'network error. are you connected to the internet?'
              );
              throw new Error(err.error);
          }
        }
      })
    );
  }

  addAuth(authService: AuthService, request: HttpRequest<any>) {
    const accessToken = authService.getAccessToken();

    if (!accessToken || this.excludedUri(request)) {
      return request;
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return request;
  }

  removeAuth(request) {
    request = request.clone({
      headers: request.headers.delete('Authorization'),
    });

    return request;
  }

  excludedUri(request: HttpRequest<any>) {
    return (
      [
        'https://restcountries.eu/rest/v2/all',
        'http://www.geoplugin.net/json.gp',
      ].includes(request.url) ||
      request.params.has('X-Amz-Algorithm') ||
      request.urlWithParams.includes('?X-Amz-Algorithm=') ||
      request.urlWithParams.includes('hooks.slack.com') ||
      request.urlWithParams.includes('wikipedia') ||
      request.urlWithParams.includes('amazonaws') ||
      request.urlWithParams.includes('lambda-url') ||
      request.urlWithParams.includes('refresh-token')
    );
  }
}
