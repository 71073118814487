<div class="topbar-menu">
  <div class="menu-stack-title mgb-12" *ngIf="menuPayload.title">
    {{ menuPayload.title }}
  </div>

  <div class="menu-stack-options">
    <a class="menu-option" *ngFor="let option of menuPayload.options">
      <div class="menu-title pave-text">{{ option.navTitle }}</div>
      <div class="menu-description">{{ option.navDescription }}</div>
    </a>
  </div>
</div>
