<app-modal-dialog
  [modalTitle]="data.title"
  [modalHasActions]="false"
  (closeTriggered)="closeDialog()"
>
  <!-- BODY AREA -->
  <div modal-dialog-body>
    <div class="dialog-body">
      <div class="description-text">
        {{
          data.title === 'Support'
            ? 'Here are ways to find support'
            : 'Here are services we render'
        }}
      </div>

      <div class="link-items">
        <a
          [href]="link.link"
          class="link-item rounded-8 link-no-underline"
          *ngFor="let link of data.dataset"
          >{{ link.title }}</a
        >
      </div>
    </div>
  </div>
</app-modal-dialog>
