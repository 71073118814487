import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocationSearchResponse } from 'src/app/models/api/search';
import { School, SchoolStatus } from 'src/app/models/courses';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  constructor(private http: HttpClient) {}

  searchLocations(input: string): Observable<LocationSearchResponse[]> {
    return this.http.get<LocationSearchResponse[]>(
      `${environment.apiDomain}/search/locations`,
      { params: { input } }
    );
  }

  getSchool(id: string): Observable<School> {
    return this.http.get<School>(
      `${environment.institutionApiDomain}/v1/school/${id}`
    );
  }

  getSchoolByStub(id: string): Observable<School> {
    return this.http.get<School>(
      `${environment.institutionApiDomain}/v1/school/stub/${id}`
    );
  }

  getSchools(filter: any = {}, display: any = {}): Observable<any> {
    let params = {};

    Object.assign(params, filter, display);

    return this.http.get<any>(`${environment.institutionApiDomain}/v1/school`, {
      params,
    });
  }

  getSchoolsSummary(): Observable<School[]> {
    return this.http.get<School[]>(
      `${environment.institutionApiDomain}/v1/school/summary`
    );
  }

  editSchool(id: string, school: School): Observable<School> {
    return this.http.patch<School>(
      `${environment.institutionApiDomain}/v1/school/${id}`,
      school
    );
  }

  editSchoolStatus(id: string, data: any): Observable<School> {
    return this.http.put<School>(
      `${environment.institutionApiDomain}/v1/school/${id}`,
      data
    );
  }

  createSchool(school: any): Observable<School> {
    return this.http.post<School>(
      `${environment.institutionApiDomain}/v1/school`,
      school
    );
  }

  changeSchoolStatus(id: string, status: SchoolStatus): Observable<School> {
    return this.http.put<School>(
      `${environment.institutionApiDomain}/v1/school/${id}`,
      { status }
    );
  }

  createSchoolIntakeGroup(
    schoolId: string,
    schoolData: School
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.institutionApiDomain}/v1/rpc/query-institution-update-effect?type=intake-groups&institutionId=${schoolId}`,
      schoolData
    );
  }

  getIntakeGroupEffect(intakeIds: string): Observable<any> {
    return this.http.get<any[]>(
      `${environment.institutionApiDomain}v1/course?intakeGroupIds=${intakeIds}`
    );
  }
}
