import { Component, Input, OnInit } from '@angular/core';
import { ExternalService } from 'src/app/services/external/external.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import {
  isCurrencyAvailable,
  getCurrencyData,
  CurrencyDataType,
} from './currencies';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
})
export class CurrencySelectorComponent implements OnInit {
  @Input() dark = false;

  selectedCurrency;
  availableCurrencies: CurrencyDataType[];

  constructor(
    private externalService: ExternalService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.selectedCurrency = this.settingsService.getCurrency();
    this.availableCurrencies = this.settingsService.getAvailableCurrencies();

    if (!isCurrencyAvailable(this.selectedCurrency)) {
      this.settingsService.setDefaultCurrency('USD');
      this.selectedCurrency = this.settingsService.getCurrency();
    }
  }

  getCountryFlag(currencyCode: string): string {
    const countryCode = getCurrencyData(currencyCode, 'country_code');
    return `https://d3w0ryh3z5o7ae.cloudfront.net/country-flag/${countryCode}.svg`;
  }

  currencyChanged($event) {
    this.settingsService.setDefaultCurrency($event.value);
    window.location.reload();
    // this.router.navigate(['./'], { relativeTo: this.route, skipLocationChange: true });
  }
}
