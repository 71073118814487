import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-footer-link-dialog',
  templateUrl: './footer-link-dialog.component.html',
  styleUrls: ['./footer-link-dialog.component.scss'],
})
export class FooterLinkDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FooterLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      description: string;
      dataset: any[];
    }
  ) {
    this.data = data;
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }
}
