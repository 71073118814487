import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LogInComponent } from './log-in/log-in.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { RegistrationConfirmationComponent } from './registration-confirmation/registration-confirmation.component';
import { ResetLinkSentComponent } from './reset-link-sent/reset-link-sent.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AgentRegistrationComponent } from './agent-registration/agent-registration.component';

const routes: Routes = [
  {
    path: 'sign-up',
    data: {
      title: 'Sign Up'
    },
    component: SignUpComponent
  },
  {
    path: 'log-in',
    data: {
      title: 'Log In'
    },
    component: LogInComponent
  },
  {
    path: 'request-reset-password',
    data: {
      title: 'Reset Password'
    },
    component: RequestResetPasswordComponent
  },
  {
    path: 'reset-link-sent',
    data: {
      title: 'Reset Link Sent'
    },
    component: ResetLinkSentComponent
  },
  {
    path: 'reset-password',
    data: {
      title: 'Reset Password'
    },
    component: ResetPasswordComponent
  },
  {
    path: 'registration-confirmation',
    data: {
      title: 'Registered'
    },
    component: RegistrationConfirmationComponent
  },
  {
    path: 'agent-registration',
    component: AgentRegistrationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SessionRoutingModule { }
