<div fxLayout="column" style="position: relative;">
    <button mat-icon-button (click)="close()" class="close-button">
        <mat-icon>close</mat-icon>
    </button>

    <div class="drag-area" fxLayout="column" fxLayoutAlign="center center">
        <button mat-button class="bordered-drag-area" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" [disabled]="!canUpload" (click)="fileInput.click()">
            <mat-icon>cloud_upload</mat-icon>
            <div class="text-mobile-smallest-r">
                <span class="bright-text">Browse</span>
                <span> files to upload</span>
            </div>
        </button>
    </div>
    <div class="content" fxLayout="column" fxLayoutGap="16px">
        <div>{{type.name}}</div>
        <div *ngFor="let doc of docs" class="pave-card text-mobile-smallest-r" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
            <div fxFlex fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px" style="overflow: hidden;">
                <mat-icon>upload_file</mat-icon>
                <div fxFlex fxLayout="column" fxLayoutGap="4px">
                    <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" 
                        [ngStyle]="{'text-decoration': doc.sourceUri ? 'underline' : 'none', 'cursor': doc.sourceUri ? 'pointer' : 'default'}" 
                        (click)="previewFile(doc)"
                    >
                        {{doc.fileName}}
                    </div>
                    <mat-progress-bar *ngIf="doc.uploading" mode="determinate" [value]="doc.progress" color="primary"></mat-progress-bar>
                </div>
            </div>
            <button *ngIf="doc.sourceUri" mat-icon-button class="delete-button" (click)="deleteFile(doc.id)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <input #fileInput hidden type="file" multiple (change)="fileChange($event.target.files)" name="file" accept=".pdf,.png,.jpg,.jpeg,.doc,.docx,.txt">
</div>