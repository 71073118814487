import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FormsModule } from '@angular/forms';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrderByPipe } from './pipe/order-by.pipe';
import { CurrencyViewPipe } from './pipe/currency-view.pipe';
import { UploadRowComponent } from './upload-row/upload-row.component';
import { ActionableDialogComponent } from './actionable-dialog/actionable-dialog.component';
import { PresignedViewPipe } from './pipe/presigned-view.pipe';
import { CurrencySelectorComponent } from './currency-selector/currency-selector.component';
import { RadioDialogComponent } from './radio-dialog/radio-dialog.component';
import { MatButtonLoadingDirective } from './directive/mat-button-loading.directive';
import { FeedbackComponent } from './feedback/feedback.component';
import { CropImageDialogComponent } from './crop-image-dialog/crop-image-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GradeInputComponent } from './grade-input/grade-input.component';
import { ResultsComponent } from './results/results.component';
import { FilterComponent } from './results/filter/filter.component';
import { NouisliderModule } from 'ng2-nouislider';
import { CourseResultRowComponent } from './results/course-result-row/course-result-row.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { UserInfoCaptureComponent } from './user-info-capture/user-info-capture.component';
import { CgNavigatorComponent } from './cg-navigator/cg-navigator.component';
import { CgMenuComponent } from './cg-menu/cg-menu.component';
import { FilterPipe } from './pipe/filter.pipe';
import { EditDeleteDialogComponent } from './edit-delete-dialog/edit-delete-dialog.component';
import { CustomNotificationSnackbarComponent } from './custom-notification-snackbar/custom-notification-snackbar.component';
import { ReferDialogComponent } from './refer-dialog/refer-dialog.component';
import { MultiselectDropdownComponent } from './multiselect-dropdown/multiselect-dropdown.component';
import { QuillModule } from 'ngx-quill';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { AcademicComponent } from './student-profile/academic/academic.component';
import { DocumentsComponent } from './student-profile/documents/documents.component';
import { PersonalComponent } from './student-profile/personal/personal.component';
import { Platform } from '@angular/cdk/platform';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { CustomDateAdapter, MY_FORMATS } from 'src/app/app.module';
import { ProfileComponent } from './student-profile/profile.component';
import { ProfileSidebarComponent } from './student-profile/profile-sidebar/profile-sidebar.component';
import { EditVisaRefusalComponent } from './student-profile/personal/edit-visa-refusal/edit-visa-refusal.component';
import { EditQualificationRowComponent } from './student-profile/academic/edit-qualification-row/edit-qualification-row.component';
import { EditOtherCertificationComponent } from './student-profile/academic/edit-other-certification/edit-other-certification.component';
import { QualificationRowComponent } from './student-profile/academic/qualification-row/qualification-row.component';
import { EditLanguageCertificationComponent } from './student-profile/academic/edit-language-certification/edit-language-certification.component';
import { UploadDialogComponent } from './student-profile/documents/upload-dialog/upload-dialog.component';
import { CreateStudentModalComponent } from './create-student-modal/create-student-modal.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { PavePageLoaderComponent } from './pave-page-loader/pave-page-loader.component';
import { ApplicationIntakeDialogComponent } from './results/application-intake-dialog/application-intake-dialog.component';
import { PaveRadioCardComponent } from './pave-radio-card/pave-radio-card.component';
import { ReminderCardComponent } from './results/reminder-card/reminder-card.component';
import { ApplicationSuccessDialogComponent } from './results/application-success-dialog/application-success-dialog.component';
import { ApplicationEmptyIntakeDialogComponent } from './results/application-empty-intake-dialog/application-empty-intake-dialog.component';
import { ApplicationActivityComponent } from './application/application-activity/application-activity.component';
import { ApplicationMessageInputComponent } from './application/application-message-input/application-message-input.component';
import { ApplicationInformationComponent } from './application/application-information/application-information.component';
import { ApplicationChecklistComponent } from './application/application-checklist/application-checklist.component';
import { ApplicationMessageComponent } from './application/application-message/application-message.component';
import { ApplicationStatusModalComponent } from './application/application-status-modal/application-status-modal.component';
import { StudySearchInputComponent } from './study-search-input/study-search-input.component';
import { SearchPreviewCardComponent } from './search-preview-card/search-preview-card.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { FooterLinkDialogComponent } from './footer-link-dialog/footer-link-dialog.component';
import { BaseTopbarComponent } from './base-topbar/base-topbar.component';
import { BaseFooterComponent } from './base-footer/base-footer.component';
import { BaseTopbarNavitemComponent } from './base-topbar/base-topbar-navitem/base-topbar-navitem.component';
import { BaseTopbarMenuComponent } from './base-topbar/base-topbar-menu/base-topbar-menu.component';
import { BaseSidebarComponent } from './base-sidebar/base-sidebar.component';
import { BaseSidebarNavitemComponent } from './base-sidebar/base-sidebar-navitem/base-sidebar-navitem.component';
import { BaseSidebarReferralBlockComponent } from './base-sidebar/base-sidebar-referral-block/base-sidebar-referral-block.component';
import { BaseSidebarMenuComponent } from './base-sidebar/base-sidebar-menu/base-sidebar-menu.component';
import { BaseTopbarDropdownComponent } from './base-topbar/base-topbar-dropdown/base-topbar-dropdown.component';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  declarations: [
    SearchBarComponent,
    UploadRowComponent,
    OrderByPipe,
    FilterPipe,
    CurrencyViewPipe,
    ActionableDialogComponent,
    PresignedViewPipe,
    CurrencySelectorComponent,
    RadioDialogComponent,
    MatButtonLoadingDirective,
    FeedbackComponent,
    CropImageDialogComponent,
    GradeInputComponent,
    ResultsComponent,
    CourseResultRowComponent,
    FilterComponent,
    HeaderComponent,
    FooterComponent,
    UserInfoCaptureComponent,
    CgNavigatorComponent,
    CgMenuComponent,
    EditDeleteDialogComponent,
    CustomNotificationSnackbarComponent,
    ReferDialogComponent,
    MultiselectDropdownComponent,
    TextEditorComponent,
    AcademicComponent,
    DocumentsComponent,
    PersonalComponent,
    ProfileSidebarComponent,
    ProfileComponent,
    QualificationRowComponent,
    EditVisaRefusalComponent,
    EditQualificationRowComponent,
    EditLanguageCertificationComponent,
    EditOtherCertificationComponent,
    UploadDialogComponent,
    CreateStudentModalComponent,
    ModalDialogComponent,
    PavePageLoaderComponent,
    ApplicationIntakeDialogComponent,
    PaveRadioCardComponent,
    ReminderCardComponent,
    ApplicationSuccessDialogComponent,
    ApplicationEmptyIntakeDialogComponent,
    ApplicationActivityComponent,
    ApplicationMessageInputComponent,
    ApplicationInformationComponent,
    ApplicationChecklistComponent,
    ApplicationMessageComponent,
    ApplicationStatusModalComponent,
    StudySearchInputComponent,
    SearchPreviewCardComponent,
    SearchDialogComponent,
    FooterLinkDialogComponent,
    BaseTopbarComponent,
    BaseFooterComponent,
    BaseTopbarNavitemComponent,
    BaseTopbarMenuComponent,
    BaseSidebarComponent,
    BaseSidebarNavitemComponent,
    BaseSidebarReferralBlockComponent,
    BaseSidebarMenuComponent,
    BaseTopbarDropdownComponent,
    EmailValidationComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    RouterModule,
    NouisliderModule,
    FormsModule,
    CodeInputModule,
    QuillModule.forRoot(),
  ],
  exports: [
    SearchBarComponent,
    UploadRowComponent,
    CurrencySelectorComponent,
    CropImageDialogComponent,
    OrderByPipe,
    FilterPipe,
    CurrencyViewPipe,
    PresignedViewPipe,
    MatButtonLoadingDirective,
    GradeInputComponent,
    ResultsComponent,
    FilterComponent,
    HeaderComponent,
    UserInfoCaptureComponent,
    CgMenuComponent,
    CgNavigatorComponent,
    MultiselectDropdownComponent,
    QuillModule,
    TextEditorComponent,
    ModalDialogComponent,
    PavePageLoaderComponent,
    ApplicationActivityComponent,
    ApplicationMessageInputComponent,
    ApplicationInformationComponent,
    ApplicationChecklistComponent,
    StudySearchInputComponent,
    SearchPreviewCardComponent,
    SearchDialogComponent,
    BaseTopbarComponent,
    BaseFooterComponent,
    BaseTopbarNavitemComponent,
    BaseTopbarMenuComponent,
    BaseSidebarComponent,
    BaseSidebarNavitemComponent,
    BaseSidebarReferralBlockComponent,
    BaseSidebarMenuComponent,
    BaseTopbarDropdownComponent,
    EmailValidationComponent
  ],
  providers: [
    CurrencyViewPipe,
    AcademicComponent,
    DocumentsComponent,
    PersonalComponent,
    ProfileComponent,
    ProfileSidebarComponent,
  ],
})
export class SharedModule {}
