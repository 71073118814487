<mat-form-field appearance="outline" [ngClass]="{ dark: dark }">
  <mat-select
    class="currency-selector"
    panelClass="lower-panel"
    [(value)]="selectedCurrency"
    (selectionChange)="currencyChanged($event)"
  >
    <mat-select-trigger>
      <div class="flex-container">
        <div class="flag-container">
          <img class="flag" [src]="getCountryFlag(selectedCurrency)" />
        </div>
        <div class="mgl-8">{{ selectedCurrency }}</div>
      </div>
    </mat-select-trigger>

    <mat-option
      *ngFor="let currency of availableCurrencies; let i = index"
      [value]="currency.currency_code"
    >
      <div class="flex-container">
        <div class="flag-container">
          <img class="flag" [src]="getCountryFlag(currency.currency_code)" />
        </div>
        <div class="mgl-8">{{ currency.currency_code }}</div>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
