import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserRole } from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AgentOperativeGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const isApproved = this.isOperativeAgentApproved();

    if (!isApproved) {
      const baseAllowedRoute = '/operative-agent/agent-onboarding/complete';
      const alternateAllowedRoute =
        '/operative-agent/agent-onboarding/account-info';

      if (![baseAllowedRoute, alternateAllowedRoute].includes(state.url)) {
        if (this.authService.getAgentStatus() === 'INVITED') {
          this.router.navigate([alternateAllowedRoute]);
          return false;
        }

        this.router.navigate([baseAllowedRoute]);
        return false;
      }
      return true;
    }
  }

  private isOperativeAgentApproved(): boolean {
    if (!this.authService.isAuthenticated()) {
      return true;
    }

    const agentType = this.authService.getUserRole();
    const agentBusinessStatus =
      this.authService?.getAgentStatus() ??
      this.authService.getBusinessStatus();

    if (agentType === UserRole.Agent) {
      return agentBusinessStatus?.toLowerCase() === 'active';
    }

    return true;
  }
}
