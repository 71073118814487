import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-base-sidebar-menu',
  templateUrl: './base-sidebar-menu.component.html',
  styleUrls: ['./base-sidebar-menu.component.scss'],
})
export class BaseSidebarMenuComponent implements OnInit {
  @Input() optionTitle: string;
  @Input() optionData: any;

  @Output() onBackBtnClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  goToMainMenu() {
    this.onBackBtnClick.emit();
  }
}
