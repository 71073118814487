export enum UserRole {
  Student = 'studypath_student',
  Agent = 'studypath_agent',
  Affiliate = 'studypath_affiliate',
  Dsr = 'studypath_dsr',
  Admin = 'studypath_admin',
}

export enum UserRouteAccountType {
  'studypath_agent' = 'operative-agent',
  'studypath_affiliate' = 'affiliate-agent',
  'studypath_admin' = 'admin',
}

export interface UserRegistrationRequest {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  password: string;
  referredBy?: string;
  agentId: string;
  role: string;
}

export interface UserRegistrationResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
}

export interface UserLoginRequest {
  username: string;
  password: string;
}

export interface UserLoginResponse {
  userId: string;
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  refreshExpiresIn: number;
  scope: string;
  tokenType: string;
}

// todo: deprecated
export interface UserProfile {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  imageUrl?: string;
  role: string;
  referralCode: string;
  status?: string;

  // admin
  type?: string;
  team?: string;
  institutionIds?: string[];

  // agent
  businessId?: number;
  businessStatus?: string;

  applicationTokens;
}

export interface UserAccountInfo {
  id?: string;
}

export interface UserPersonalInformation {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  gender?: string;
  dateOfBirth?: string;
  maritalStatus?: string;
  nationality?: string;
  passportNumber?: string;
  passportIssueDate?: string;
  passportExpiryDate?: string;
  email?: string;
  phoneNumber?: string;
  alternatePhoneNumber?: string;
  sponsor?: string;
  address?: string;
  countryOfResidence?: string;
  stateOrProvince?: string;
  city?: string;
  postalCode?: string;
  imageUrl?: string;
  visaRefusals: [
    {
      id: string;
      country: string;
      stateOrProvince: string;
      reason: string;
      date: string;
    },
  ];
  educationHistory?: AcademicQualification[];
}

export interface AcademicInformation {
  educationHistory: AcademicQualification[];
  englishExamination: {
    type: string;
    date: Date;
    overallTestScore: string;
  };
  languageCertifications: [
    {
      type: string;
      date: Date;
      score: string;
    },
  ];
  otherExamination: {
    type: string;
    date: Date;
    overallTestScore: string;
  };
}

export interface AcademicCertification {
  type: string;
  date: Date;
  score: string;
}

export interface AcademicQualification {
  id: string;
  schoolName: string;
  schoolAddress?: string;
  resumptionDate?: Date;
  graduationDate?: Date;
  degreeAwarded: string;
  course: string;
  country: string;
  cgpa: string;
  gradeAttained: string;
  gradeAttainable: string;
  gradeType: string;
  grade: {
    gradeAttained: string;
    gradeAttainable: string;
  };
  languageOfInstruction: string;
}

export interface SupportingDocument {
  id?: string;
  type?: string;
  description?: string;
  url?: string;
  value?: string;

  fileName: string;
  sourceUri: string;
}

export interface DocumentInformation {
  universityTranscript: [SupportingDocument];
  waecOrNeco: [SupportingDocument];
  passportPhotograph: [SupportingDocument];
  researchProposal: [SupportingDocument];
  birthCertificate: [SupportingDocument];
  universityCertificate: [SupportingDocument];
  highSchoolCertificate: [SupportingDocument];
  cv: SupportingDocument;
  internationalPassport: SupportingDocument;
  referees: [SupportingDocument];
  otherDocuments: [SupportingDocument];
}

export interface UserAgent {
  alternatePhoneNumber: string;
  email: string;
  emailConfirmed: boolean;
  firstName: string;
  hubspotId: string;
  id: string;
  imageUrl: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  referralCode: string;
  referredBy: string;
  role: string;
}
