import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserRole } from 'src/app/models/api/user';

@Injectable({
  providedIn: 'root',
})
export class AgentRouteGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAuthenticated = this.authService.isAuthenticated();
    const userRole = this.authService.getUserRole();
    const url: string = state.url;

    // CHECK IF USER IS LOGGED IN AND IS AFFILIATE AGENT
    if (isAuthenticated && userRole === UserRole.Affiliate) {
      // Check if the URL starts with '/affiliate-agent'
      if (url.startsWith('/affiliate-agent')) {
        return true;
      } else {
        // Redirect to affiliate dashboard page
        this.router.navigate(['/affiliate-agent/dashboard']);
        return false;
      }
    } else {
      return true;
    }
  }
}
