<div class="band" ngClass.lt-md="mobile" ngClass.md="tablet" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="0px" fxLayoutGap.gt-sm="10%">
    <div class="content" fxLayout="column" fxLayoutAlign="center center">
        <div class="logo-with-check"></div>
        <h2 style="letter-spacing: -0.03em;">Password Reset Link Sent</h2>
        <div style="text-align: center; padding: 0px 32px; max-width: 560px;">
            A password rest link with instructions on how to reset your password 
            has been sent to your email {{email}}. 
            Check your inbox and click on the link provided.
        </div>
    </div>
</div>