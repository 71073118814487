import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadService } from 'src/app/services/upload/upload.service';
import { UserService } from 'src/app/services/user/user.service';
import { ExternalService, Country } from 'src/app/services/external/external.service';
import { Observable, of, pipe } from 'rxjs';
import { share } from 'rxjs/operators';
import { AcademicQualification, UserProfile, SupportingDocument, UserPersonalInformation } from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AcademicComponent } from './academic/academic.component';
import { PersonalComponent } from './personal/personal.component';
import { DocumentsComponent } from './documents/documents.component';
import { ProfileSidebarComponent } from './profile-sidebar/profile-sidebar.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild("personal") personal: PersonalComponent;
  @ViewChild("academic") academic: AcademicComponent;
  @ViewChild("documents") documents: DocumentsComponent;
  @ViewChild("sidebar", { static: false }) sidebar: ProfileSidebarComponent;
  
  isMobile;
  studentId;
  hasSetStudentScope = false;

  activeFragment = this.route.fragment.pipe(share());
  personalInformation: UserPersonalInformation;

  anchorData = [];
  anchorPositions = [];

  userProfile: UserProfile;
  countries: Observable<Country[]>;
  countryList: Country[];
  countryCodeMap: Map<string, Country>;

  saving = false;
  saved = false;
  
  get percentageComplete() {
    if (!this.sidebar) return 0;
    
    const bools = [];
    this.sidebar.dataSource.data.forEach(node => {
      node.children.forEach(child => bools.push(child.complete));
    });

    return bools.filter(b => b).length / bools.length * 100;
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    for (let a of this.personal.anchorPositions.concat(this.academic.anchorPositions).concat(this.documents.anchorPositions)) {      
      if (a.pos > 0) {
        const fragment = a.parentStub + '-' + a.stub;
        if (this.sidebar.activeFragmentSync !== fragment) this.sidebar.updateFragment(fragment, false);
        break;
      }
    }    
  }

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private authService: AuthService,
    private uploadService: UploadService,
    private externalService: ExternalService,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationService: NotificationService,
    private titleService: Title,
    private boObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router
  ) {
    boObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ]).subscribe(result => {
      if (this.isMobile === undefined) this.isMobile = result.matches;
      if (this.isMobile !== result.matches) {
        window.location.reload();
      }
    });
  }

  ngOnInit() {
    this.setStudentScope();
    this.externalService.getCountries().subscribe(
      countries => {
        this.countryCodeMap = new Map(countries.map(c => [c.alpha3Code, c]));
        this.countryList = countries;
      }
    );

    this.notificationService.saving.subscribe(value => {
      this.saving = value;
      if (!value) {
        this.saved = true;
        setTimeout(() => this.saved = false, 3000);
      }
    });

    this.getPersonalInformation();
  }

  setStudentScope() {
    if (this.route.snapshot.data.forStudent) {
      this.studentId = this.route.snapshot.params.studentId;
    }

    this.hasSetStudentScope = true;
  }
  
  ngAfterViewInit() {
    this.sidebar.dataSource.data = [
      this.generateAnchorTree('Personal Information', 'personal', this.personal),
      this.generateAnchorTree('Academic Information', 'academic', this.academic),
      this.generateAnchorTree('Supporting Documents', 'documents', this.documents)
    ];

    setTimeout(() => {
      this.activeFragment.subscribe(fragment => {
        this.scrollToFragment(fragment);
      });
      this.scrollToView(this.route.snapshot.fragment);
    }, 500);

    this.changeDetectorRef.detectChanges();
  }

  getPersonalInformation() {
    this.userService.getPersonalInformation(this.studentId).subscribe(personalInformation => {
      this.personalInformation = personalInformation;
    });
  }

  fileChange(files: File[]) {
    const file = files[0];

    this.uploadService.uploadFile(file).subscribe(imageUrl => {
      this.userService.updateUserPersonalProfile({ imageUrl }).subscribe(_ => {
        this.authService.setUserImageUrl(imageUrl);
        this.getPersonalInformation();
      });
    });
  }

  subscribeToReload(componentRef) {
    componentRef.studentId = this.studentId;

    componentRef.reloaded.subscribe(() => {
      this.setStudentScope();

      if (componentRef instanceof PersonalComponent) {
        this.sectionReloaded('Personal Information', 'personal', componentRef);
      } else if (componentRef instanceof AcademicComponent) {
        this.sectionReloaded('Academic Information', 'academic', componentRef);
      } else if (componentRef instanceof DocumentsComponent) {
        this.sectionReloaded('Supporting Documents', 'documents', componentRef);
      }
    });
  }

  unsubscribe() {
    // console.log('attempt unsubscribe');    
  }

  sectionReloaded(sectionName: string, sectionLink: string, componentRef=null) {
    if (!this[sectionLink]) return;

    componentRef = componentRef || this[sectionLink];
    const replacementNode = this.generateAnchorTree(sectionName, sectionLink, componentRef);
    this.sidebar.updateData(replacementNode);
    this.changeDetectorRef.detectChanges();
  }

  scrollToFragment(data) {
    if (!this.personal || !this.academic || !this.documents) return;
    const splitFragment = data.split('-');

    if (splitFragment[0] === 'personalInformation') {
      this.personal.scrollToView(splitFragment[1].toLocaleLowerCase());
    } else if (splitFragment[0] === 'academicInformation') {
      this.academic.scrollToView(splitFragment[1].toLocaleLowerCase());
    } else if (splitFragment[0] === 'supportingDocuments') {
      this.documents.scrollToView(splitFragment[1].toLocaleLowerCase());
    }
  }

  generateAnchorTree(name: string, link: string, componentRef) {
    let stub = name.split(' ')[0].toLocaleLowerCase();

    if (name.split(' ').length > 1) {
      stub += name.split(' ')[1];
    }

    return {
      name,
      stub,
      children: componentRef.anchors.map(a => { return {
        name: a.name,
        stub: a.name.replace(' ',''),
        link,
        fragment: stub + '-' + a.name.replace(' ',''),
        complete: a.complete
      }}),
      complete: componentRef.anchors.every(a => !!a.complete)
    }
  }

  openSidebarDialog() {
    let dialogRef = this.dialog.open(ProfileSidebarComponent, {
      width: '92vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: 'fullscreen-dialog',
      autoFocus: false,
      position: {left: '0', right: '0', bottom: '0', top: '0'}
    });
    dialogRef.componentInstance.dataSource.data = this.sidebar.dataSource.data;
    dialogRef.componentInstance.sourceRouter = this.router;
    // dialogRef.componentInstance.updateData(this.generateAnchorTree('Personal Information', 'personal'));
  }

  getPageName(fragment: string) {
    return this.titleService.getTitle().split("|")[1]?.trim();
    // if (!fragment) return this.titleService.getTitle().split("|")[1].trim();
    // if (fragment.startsWith('personal')) return 'Personal Information';
    // if (fragment.startsWith('academic')) return 'Academic Information';
    // if (fragment.startsWith('supporting')) return 'Supporting Documents';
  }

  scrollToView(fragment: string) {
    if (!fragment) return;
    
    const cards = document.getElementsByTagName('mat-card-subtitle');

    const filteredCards = [].filter.call(
      cards,
      e => e.innerHTML.replace(' ','') === fragment.split('-')[1]
    );

    const anchor = filteredCards[this.isMobile ? 0 : 1];
    window.scrollTo({top: window.scrollY + anchor.getBoundingClientRect().top - 88, behavior: 'smooth'});
  }
}
