import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: `
  button[mat-button][loading], button[mat-raised-button][loading], button[mat-icon-button][loading],
  button[mat-fab][loading], button[mat-mini-fab][loading], button[mat-stroked-button][loading],
  button[mat-flat-button][loading]`
})
export class MatButtonLoadingDirective implements OnChanges {
  private spinnerFactory: ComponentFactory<MatSpinner>;
  private spinner: ComponentRef<MatSpinner>;

  @Input() loading: boolean;
  @Input() disabled: boolean;

  constructor(
    private matButton: MatButton,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatSpinner);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.loading) {
      return;
    }

    if (changes.loading.currentValue) {
      this.matButton._elementRef.nativeElement.classList.add('mat-loading');
      console.log(this.matButton._elementRef.nativeElement.classList);
      this.matButton.disabled = true;
      this.createSpinner();
    } else if (!changes.loading.firstChange) {
      this.matButton._elementRef.nativeElement.classList.remove('mat-loading');
      this.matButton.disabled = this.disabled;
      this.destroySpinner();
    }
  }
  
  createSpinner() {
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
      this.spinner.instance.diameter = 20;
      if (this.matButton._elementRef.nativeElement.classList.value.includes('mat-button')) {
        this.spinner.instance._elementRef.nativeElement.classList.add('mat-loading--primary');
      }
      this.renderer.appendChild(this.matButton._elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement);
    }
  }

  destroySpinner() {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }

}
