// todo: fix envs
export const environment = {
  envName: 'prod',
  uiDomain: 'https://pavehq.com',
  apiDomain: 'https://ec2-3-139-68-231.us-east-2.compute.amazonaws.com/api',
  userApiDomain: 'https://api.pavehq.com/ies-user',
  authApiDomain: 'https://api.pavehq.com/ies-passport',
  institutionApiDomain: 'https://api.pavehq.com/institution',
  applicationApiDomain: 'https://api.pavehq.com/application',
  notificationApiDomain: 'https://api.pavehq.com/notification',
  programSearchServiceDomain:
    'https://k2qatpymppprzbndia7uv7ysqu0ybrdc.lambda-url.eu-west-2.on.aws',
  currencyServiceDomain:
    'https://dd3xt3d3isu4cydezxtdlf6cvu0bbocm.lambda-url.eu-west-2.on.aws',
  paystackPublicKey: '',
  seerBitPublicKey: 'SBPUBK_QM06QDMISO6YUWGRGAIFWNXMONMXKZCW',
  hubSpotApiKey: 'eu1-8217-b0f4-413a-9c86-445477d6bc00',
  hubSpotAccountId: '25229802',
  splitAPIKey: '6vo0n610dr6gnmj9n219qtelt9olcuj9nl25',
  googleMapAPIKey: 'AIzaSyCeO1wI1G85QN2eYive7DwstXwwBTpWNQc',
  production: true,
  flutterwavePublicKey: 'FLWPUBK-8a3e7aa580040cc3ea525e66e144e798-X',
  googleClientId: '172739502926-o78vp6bs2k9jekn86krdtq7oouekp3db.apps.googleusercontent.com',
};
