import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-preview-card',
  templateUrl: './search-preview-card.component.html',
  styleUrls: ['./search-preview-card.component.scss'],
})
export class SearchPreviewCardComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() searchResult = [];
  @Input() searchPosition: string = 'absolute';

  @Output() filterSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  emitSelectedFilter(selectedValue: any) {
    this.filterSelected.emit(selectedValue);
  }
}
