<div class="search-dialog smooth-animation" (keydown)="onKeyDown($event)">
  <!-- CLOSE DIALOG -->
  <div class="close-dialog pointer" (click)="closeDialog()">
    <mat-icon>clear</mat-icon>
  </div>

  <div
    class="search-container wt-100 ht-auto"
    (click)="$event.stopPropagation()"
  >
    <app-study-search-input
      [searchSource]="pageSource"
      [isFocus]="true"
      searchPosition="relative"
      (click)="$event.stopPropagation()"
    ></app-study-search-input>
  </div>
</div>
