<div fxFlex fxLayout="column" fxLayoutGap="64px">
    <section fxFlex fxLayout="column" fxLayoutGap="16px">
        <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['educationHistory']">Education History</mat-card-subtitle>        
        <div *ngIf="educationHistory?.length > 0" fxLayout="column" fxLayoutGap="16px" ngStyle.gt-md="max-width: 540px">
            <div fxLayout="column" fxLayoutGap="16px" *ngFor="let educationHistory of educationHistory || []; last as isLast">
                <div #card class="pave-card pave-card--darker education-row" fxLayout="column" fxLayoutGap="8px" ngClass.lt-lg="mobile" (click)="mobileEducationHistoryRowClicked(educationHistory, card)">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="column" style="overflow: hidden">
                            <div class="text-mobile-medium-m row-one row-one-value">{{educationHistory.schoolName?.length > 1 ? educationHistory.schoolName : '---'}}</div>
                            <div class="text-mobile-smallest-r row-two">{{educationHistory.course?.length > 1 ? educationHistory.course : '---'}}</div>
                        </div>
                        <div fxHide.gt-md fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center">
                            <div></div>
                            <mat-icon>more_vert</mat-icon>
                        </div>
                    </div>
                    <div fxHide.lt-lg fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutGap="32px">
                            <div fxLayout="column">
                                <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Level</div>
                                <div class="text-mobile-smallest-r">{{displayFriendlyDegreeName(educationHistory.degreeAwarded) || '---'}}</div>
                            </div>
                            <div fxLayout="column">
                                <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Start Date</div>
                                <div class="text-mobile-smallest-r">{{(educationHistory.resumptionDate | date) || '---'}}</div>
                            </div>
                            <div fxLayout="column">
                                <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">End Date</div>
                                <div class="text-mobile-smallest-r">{{(educationHistory.graduationDate | date) || '---'}}</div>
                            </div>
                        </div>
                        <div>
                            <button mat-icon-button (click)="editEducationHistoryRecord(educationHistory)">
                                <mat-icon style="font-size: 16px;">edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" (click)="deleteEducationHistoryRecord(educationHistory)">
                                <mat-icon style="font-size: 16px;">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxHide.gt-md class="row-three text-mobile-smallest-r" fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="column">
                            <div style="font-size: smaller;">Level</div>
                            <div class="row-three-value">{{displayFriendlyDegreeName(educationHistory.degreeAwarded) || '---'}}</div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="16px">
                            <div fxLayout="column">
                                <div style="font-size: smaller;">Start date</div>
                                <div class="row-three-value">{{(educationHistory.resumptionDate | date) || '---'}}</div>
                            </div>
                            <div fxLayout="column">
                                <div style="font-size: smaller;">End date</div>
                                <div class="row-three-value">{{(educationHistory.graduationDate | date) || '---'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="educationHistory?.length > 0" mat-stroked-button color="primary" class="pave-button" (click)="addEducationHistoryRecord()" ngStyle.gt-md="max-width: 540px">
            <mat-icon>add</mat-icon>
            <span style="margin-left: 8px;">Add More</span>
        </button>
        <div *ngIf="educationHistory?.length === 0" class="entry-empty-state" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center" (click)="addEducationHistoryRecord()" ngStyle.gt-sm="max-width: 240px">
            <mat-icon style="font-size: 32px">add_circle</mat-icon>
            <span style="font-size: 14px">Add Education History</span>
        </div>
    </section>
    
    <section fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center">
            <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['languageCertifications']">Language Certifications</mat-card-subtitle>
            <mat-icon color="accent" matTooltip="Example: IELTS, TOEFL ..." style="font-size: 14px; height: 32px" #lTooltip="matTooltip" (click)="lTooltip.toggle()">help</mat-icon>
        </div>
        <div *ngIf="languageCertifications?.length > 0" fxLayout="column" fxLayoutGap="16px" ngStyle.gt-md="max-width: 540px">
            <div fxLayout="column" fxLayoutGap="16px" *ngFor="let certification of languageCertifications || []; index as i; last as isLast">
                <div #card class="pave-card pave-card--darker" fxLayout="column" fxLayoutGap="8px" ngClass.lt-lg="mobile" (click)="mobileLanguageCertificationRowClicked(i, certification, card)">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="text-mobile-medium-m">{{certification.type}}</div>
                        <div fxHide.gt-md fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center">
                            <div>{{certification.score}}</div>
                            <div>{{certification.date | date}}</div>
                            <mat-icon>more_vert</mat-icon>
                        </div>
                    </div>
                    <div fxHide.lt-lg fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutGap="32px">
                            <div fxLayout="column">
                                <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Score</div>
                                <div class="text-mobile-smallest-r">{{certification.score}}</div>
                            </div>
                            <div fxLayout="column">
                                <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Exam Date</div>
                                <div class="text-mobile-smallest-r">{{certification.date | date}}</div>
                            </div>
                        </div>
                        <div>
                            <button mat-icon-button (click)="addLanguageCertification(certification)">
                                <mat-icon style="font-size: 16px;">edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" (click)="deleteLanguageCertification(i)">
                                <mat-icon style="font-size: 16px;">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="languageCertifications?.length > 0" mat-stroked-button color="primary" class="pave-button" (click)="addLanguageCertification()" ngStyle.gt-sm="max-width: 540px">
            <mat-icon>add</mat-icon>
            <span style="margin-left: 8px;">Add More</span>
        </button>
        <div *ngIf="languageCertifications?.length === 0" fxLayout="column" fxLayoutGap="16px">
            <div>Do you have a valid language certification?</div>
            <mat-radio-group fxLayout="row" fxLayoutGap="16px" [(ngModel)]="hasLanguageCertifications" [ngModelOptions]="{standalone: true}" (change)="languageSelectionChanged($event)">
                <mat-radio-button class="pave-radio" [value]="true">Yes</mat-radio-button>
                <mat-radio-button class="pave-radio" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </section>
    
    <section fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center">
            <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['otherCertifications']">Other Certifications</mat-card-subtitle>
            <mat-icon color="accent" matTooltip="Example: JAMB, GMAT, GRE ..." style="font-size: 14px; height: 32px" #oTooltip="matTooltip" (click)="oTooltip.toggle()">help</mat-icon>
        </div>
        <div *ngIf="otherCertifications?.length > 0" fxLayout="column" fxLayoutGap="16px" ngStyle.gt-md="max-width: 540px">
            <div fxLayout="column" fxLayoutGap="16px" *ngFor="let certification of otherCertifications || []; index as i; last as isLast">
                <div #card class="pave-card pave-card--darker" fxLayout="column" fxLayoutGap="8px" ngClass.lt-lg="mobile" (click)="mobileOtherCertificationRowClicked(i, certification, card)">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="text-mobile-medium-m">{{certification.type}}</div>
                        <div fxHide.gt-md fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center">
                            <div>{{certification.score}}</div>
                            <div>{{certification.date | date}}</div>
                            <mat-icon>more_vert</mat-icon>
                        </div>
                    </div>
                    <div fxHide.lt-lg fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutGap="32px">
                            <div fxLayout="column">
                                <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Score</div>
                                <div class="text-mobile-smallest-r">{{certification.score}}</div>
                            </div>
                            <div fxLayout="column">
                                <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Exam Date</div>
                                <div class="text-mobile-smallest-r">{{certification.date | date}}</div>
                            </div>
                        </div>
                        <div>
                            <button mat-icon-button (click)="addOtherCertification(certification)">
                                <mat-icon style="font-size: 16px;">edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" (click)="deleteOtherCertification(certification)">
                                <mat-icon style="font-size: 16px;">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="otherCertifications?.length > 0" mat-stroked-button class="pave-button" (click)="addOtherCertification()" ngStyle.gt-sm="max-width: 540px">
            <mat-icon>add</mat-icon>
            <span style="margin-left: 8px;">Add More</span>
        </button>
        <div *ngIf="otherCertifications?.length === 0" fxLayout="column" fxLayoutGap="16px">
            <div>Do you have a valid non-language certification?</div>
            <mat-radio-group fxLayout="row" fxLayoutGap="16px" [(ngModel)]="hasOtherCertifications" [ngModelOptions]="{standalone: true}" (change)="otherSelectionChanged($event)">
                <mat-radio-button class="pave-radio" [value]="true">Yes</mat-radio-button>
                <mat-radio-button class="pave-radio" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </section>
</div>

<ng-template #loading>
    <div class="loader" style="min-height: 500px;"></div>
</ng-template>