import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss'],
})
export class EmailValidationComponent implements OnInit {
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  @Input() autoDismiss = false;
  @Input() code: string;
  @Input() email: string;
  @Input() userId: string;
  @Output() onVerificationComplete? = new EventEmitter<boolean>();

  verified: Boolean;
  verifying = true;
  attemptedRetry = false;
  timeUntilCanResendCode = 0;
  resending = false;
  isLoggedIn = false;
  form: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private authService: AuthService,
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      code: ['', [Validators.required]],
      userId: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    const { code, email, userId } = {
      code: this.code || '',
      email: this.email || this.authService.getUserEmail(),
      userId: this.userId || this.authService.getUserId(),
    };

    if (email && code.length > 0) {
      // attempting to verify code
      this.form.setValue({ email, code, userId });
      this.verify();
    } else if (email) {
      // requesting code
      this.form.setValue({ email, code, userId });
      this.resendCode();
    } else {
      // user is not logged in
      // todo: handle
      this.verifying = false;
      this.verified = false;
    }
  }

  get showLoadingState() {
    return !this.verified && this.verifying;
  }

  get showFailedState() {
    return this.verified === false && !this.attemptedRetry;
  }

  get showVerifyState() {
    return this.verified === false && this.attemptedRetry && !this.verifying;
  }

  verify() {
    this.verifying = true;
    this.authService.validateCode(this.form.getRawValue()).subscribe(
      (_) => {
        this.triggerEmailVerifySuccessActions();
      },
      (error) => {
        this.verifying = false;
        this.verified = false;

        switch (error.status) {
          case 400: {
            this.notificationService.showError('Invalid code');
            break;
          }
          default: {
            this.notificationService.showError(
              'Something went wrong. Please try again',
            );
          }
        }
      },
    );
  }

  onCodeCompleted(code: string) {
    this.form.controls.code.setValue(code);
    this.verify();
  }

  resendCode() {
    this.attemptedRetry = true;
    this.timeUntilCanResendCode = 30;

    const resendCheck = setInterval(() => {
      this.timeUntilCanResendCode = Math.max(
        0,
        this.timeUntilCanResendCode - 1,
      );
      if (this.timeUntilCanResendCode === 0) clearInterval(resendCheck);
    }, 1000);

    this.authService.requestCode(this.form.value.userId).subscribe(
      (_) => {
        this.verifying = false;
        this.verified = false;
      },
      (error) => {
        switch (error.status) {
          case 400: {
            if (error.error.message === 'User email is already verified') {
              if (this.autoDismiss) {
                this.continueAfterVerification();
              } else {
                this.triggerEmailVerifySuccessActions();
              }
            }
            break;
          }
          default: {
            this.notificationService.showError(
              'Something went wrong. Please try again',
            );
            this.verified = false;
          }
        }
      },
    );
  }

  triggerEmailVerifySuccessActions() {
    this.verified = true;
    this.verifying = false;

    if (
      this.authService.isAuthenticated() &&
      this.authService.getUserId() === this.form.controls.userId.value
    ) {
      this.isLoggedIn = true;
    }
  }

  continueAfterVerification() {
    if (this.onVerificationComplete) {
      this.onVerificationComplete.emit(true);
    } else {
      this.router.navigate(['/', 'user', 'profile']);
    }
  }
}
