import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreService {
  constructor() {}

  /**
   * It takes a key and a value, converts the value to a string, and then saves it to localStorage
   * @param {string} key - The key to store the value under.
   * @param {any} value - any - This is the value that you want to store in the local storage.
   */
  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * It gets an item from localStorage, parses it, and returns it
   * @param {string} key - The key of the item to get from local storage.
   * @returns The value of the key in localStorage.
   */
  getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  /**
   * It removes an item from local storage
   * @param {string} key - The key of the item you want to remove.
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * It clears the local storage
   */
  clear(): void {
    localStorage.clear();
  }

  clearLocalStorage(excludedKeys: string[]) {
    // Convert the array to a Set for faster lookups
    const excludeSet = new Set(excludedKeys);

    // Get all keys from localStorage
    const keys = Object.keys(localStorage);

    // Loop through all keys in localStorage
    for (const key of keys) {
      // If the key is not in the excluded set, remove it
      if (!excludeSet.has(key)) {
        localStorage.removeItem(key);
      }
    }
  }
}
