import { Injectable } from '@angular/core';
import { SplitFactory } from '@splitsoftware/splitio-browserjs';
import * as SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SplitService {
  private splitClient: SplitIO.IClient;
  private splitSDK: SplitIO.ISDK;
  public sdkReady$: Subject<void> = new Subject<void>();
  private userID: string | null =
    localStorage.getItem('user_userId') ?? this.generateDummyUserId();

  public initSdk(): void {
    this.splitSDK = SplitFactory({
      core: {
        authorizationKey: environment.splitAPIKey,
        key: this.userID,
      },
    });

    this.initClient(this.userID);

    this.splitClient.on(this.splitClient.Event.SDK_READY, () => {
      this.sdkReady$.next();
    });
  }

  public initClient(userId?: string) {
    this.splitClient = this.splitSDK.client(userId);
  }

  public getTreatment(splitFeature: string): string {
    return this.splitClient.getTreatment(splitFeature);
  }

  public generateDummyUserId(): string {
    return Math.random().toString(36).substring(2);
  }
}
