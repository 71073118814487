import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AmplitudeTrackerService } from 'src/app/services/events/amplitude-tracker.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-social-signin',
  templateUrl: './social-signin.component.html',
  styleUrls: ['./social-signin.component.scss'],
})
export class SocialSigninComponent implements OnInit {
  @Output() emailClicked = new EventEmitter();

  googleClientId: string = environment.googleClientId;
  envUIDomain: string = environment.uiDomain;

  // Google's OAuth 2.0 endpoint for requesting an access token
  googleOauth2Endpoint: string = 'https://accounts.google.com/o/oauth2/v2/auth';

  constructor(public amplitudeTrackerService: AmplitudeTrackerService) {}

  ngOnInit(): void {}

  handleEmailSignIn() {
    this.amplitudeTrackerService.LoginEmailOptionSelected();
    this.emailClicked.emit();
  }

  // Parameters to pass to OAuth 2.0 endpoint.
  get getGoogleParams() {
    const hostname = window.location.hostname;

    const redirectUrl = ['localhost', '127.0.0.1'].includes(hostname)
      ? 'http://localhost:4200/'
      : `${this.envUIDomain}/`;

    return {
      client_id: this.googleClientId,
      redirect_uri: redirectUrl,
      response_type: 'token',
      scope: 'email profile',
      state: 'pass-through value',
    };
  }

  signInWithGoogle() {
    this.amplitudeTrackerService.LoginGoogleOptionSelected();

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', this.googleOauth2Endpoint);

    // Add form parameters as hidden input values.
    for (var p in this.getGoogleParams) {
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', this.getGoogleParams[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }
}
