<div fxLayoutAlign="center center">
  <div
    *ngIf="pageIndex === 1"
    class="wrapper"
    ngClass.lt-md="mobile"
    fxLayout="column"
    fxLayoutGap="24px"
  >
    <h3>Tell Us About Yourself</h3>
    <div fxLayout="column" fxLayoutGap="16px">
      <button
        mat-button
        mat-raised-button
        color="primary"
        class="pave-button"
        (click)="pageIndex = 2"
      >
        Get Started
      </button>
      <button
        mat-button
        color="primary"
        (click)="trackDismissButtonClick(); dialogRef.close()"
      >
        Skip and Continue Later
      </button>
    </div>
  </div>

  <div
    *ngIf="pageIndex === 2"
    class="wrapper"
    ngClass.lt-md="mobile"
    fxLayout="column"
    fxLayoutGap="24px"
  >
    <div>
      <div
        style="font-size: 32px; font-weight: 600; letter-spacing: -0.02em"
        ngStyle.lt-md="font-size: 26px"
      >
        Tell Us About Yourself
      </div>
      <label style="font-size: smaller"
        >This will allow us find better matches for you</label
      >
    </div>
    <form fxLayout="column" [formGroup]="form" fxLayoutGap="16px">
      <div
        fxLayout="column"
        fxLayoutGap="16px"
        fxLayout.gt-sm="row"
        fxLayoutGap.gt-sm="32px"
      >
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName" />
        </mat-form-field>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="16px"
        fxLayout.gt-sm="row"
        fxLayoutGap.gt-sm="32px"
      >
        <mat-form-field>
          <mat-label>Phone number</mat-label>
          <input matInput formControlName="phoneNumber" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Highest Degree Obtained</mat-label>
          <mat-select
            [disabled]="highestDegreeObtained"
            formControlName="highestDegreeObtained"
            placeholder="Degree Awarded"
          >
            <mat-option value="highSchool">High School</mat-option>
            <mat-option value="undergraduateDiploma"
              >Undergraduate Diploma</mat-option
            >
            <mat-option value="bachelors">Bachelors (or equivalent)</mat-option>
            <mat-option value="postgraduateDiploma"
              >Postgraduate Diploma</mat-option
            >
            <mat-option value="masters">Masters (or equivalent)</mat-option>
            <mat-option value="doctorate">Doctorate (or equivalent)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div
      fxLayout.lt-md="column"
      fxLayoutAlign.gt-sm="center"
      fxLayout.gt-sm="row-reverse"
      fxLayoutGap="16px"
    >
      <button
        mat-raised-button
        color="primary"
        class="pave-button"
        ngStyle.gt-sm="width: 160px"
        (click)="save()"
      >
        Save
      </button>
      <button
        mat-button
        color="primary"
        class="pave-button"
        ngStyle.gt-sm="width: 160px"
        (click)="trackRemindLaterButtonClick(); dialogRef.close()"
      >
        Remind Me Later
      </button>
    </div>
  </div>
</div>
