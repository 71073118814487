import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import {
  MatDrawer,
  MatDrawerContent,
  MatDrawerContainer,
} from '@angular/material/sidenav';
import { SearchBarComponent } from 'src/app/modules/shared/search-bar/search-bar.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BuildDetailsService } from 'src/app/services/background/build-details.service';
import { UserModule } from 'src/app/modules/user/user.module';
import { UserRole } from 'src/app/models/api/user';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('content') content: MatDrawerContent;
  @ViewChild(MatDrawerContainer) drawerContainer: MatDrawerContainer;

  drawerWidth: number = 92;
  contentMarginLeft: number = 92;
  label: string;
  customLabel: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private buildDetailsService: BuildDetailsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    const leaf = this.getLeafRoute(this.route.snapshot);
    this.label = leaf.data.label;

    // CHECK FOR AVAILABLE PAGE LABEL EXTENSIONS
    this.route.queryParams.subscribe((params) => {
      this.customLabel = params?.label ?? '';
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_) => {
        const leaf = this.getLeafRoute(this.route.snapshot);
        this.label = leaf.data.label;
      });
  }

  updateContentMargin($event) {
    this.drawerWidth = $event.width;

    if ($event.state) {
      this.contentMarginLeft = $event.width;
      $event.state && this.drawerContainer.updateContentMargins();
    }
  }

  isLanding() {
    return this.route.snapshot.url.length === 0;
  }

  isLoggedIn() {
    return this.authService.isAuthenticated();
  }

  isAgent() {
    return (
      this.isLoggedIn() && this.authService.getUserRole() === UserRole.Agent
    );
  }

  isAdmin() {
    return (
      this.isLoggedIn() && this.authService.getUserRole() === UserRole.Admin
    );
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  openSearch() {
    const dialogRef = this.dialog.open(SearchBarComponent);
  }

  getProfileName() {
    return this.authService.getUserFullName();
  }

  getProfileImage() {
    return this.authService.getUserImage();
  }

  getPageUrl() {
    return new URL(document.URL).pathname;
  }

  get buildVersion() {
    return this.buildDetailsService.getBuildVersion();
  }

  getLeafRoute(root: ActivatedRouteSnapshot) {
    if (root.children?.length > 0) {
      return this.getLeafRoute(root.children[0]);
    }

    return root;
  }
}
