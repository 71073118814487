import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { flatMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UploadService } from 'src/app/services/upload/upload.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  data;
  form: FormGroup;
  imgSrc = null;
  clickLoading = false;
  webhookUrl = "https://hooks.slack.com/services/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";
  // webhookUrl = "https://hooks.slack.com/services/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";
  
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private notificationService: NotificationService,
    private uploadService: UploadService,
    private matDialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) data: {blob?: any; isPositiveFeedback: boolean}
  ) {
    this.data = data;
    this.form = this.formBuilder.group({
      comment: ['', []]
    });
  }

  async ngOnInit() {
    this.clickLoading = false;
    const canvas = await html2canvas(document.body, {
      allowTaint: false,
      foreignObjectRendering: true,
      ignoreElements: function (element) {
        return element.classList.contains('mat-dialog-container')
      }
    });

    canvas.toBlob(blob => this.loadImage(blob),'image/jpeg', 0.1);
  }

  loadImage(blob) {
    const reader = new FileReader()
    reader.addEventListener('loadend', () => {
      this.imgSrc = reader.result;
      this.clickLoading = false;
    });

    reader.readAsDataURL(blob);
  }

  submit() {
    // console.log(this.form.controls.comment.value, this.imgSrc);
    // const data = {url: this.imgSrc, comment: this.form.controls.comment.value};

    const uploadData = {
      data: this.imgSrc
    }

    const data = {
      "attachments": [
        {
          "color": `${this.data.isPositiveFeedback ? '#00ff00' : '#00ff00'}`,
          "blocks": [
            {
              "type": "section",
              "text": {
                "type": "mrkdwn",
                "text": `You have new feedback:\n*${localStorage.getItem('user_email') || 'guest'}*`
              }
            },
            {
              "type": "section",
              "text": {
                "type": "mrkdwn",
                "text": `*Page:* ${document.URL}\n*Image:* ##image##\n*When:* ${new Date()}\n*Comments:* \"${this.form.controls.comment.value}\"`
              },
              "accessory": {
                "type": "image",
                "image_url": "https://d3igv919p7jfmu.cloudfront.net/bdf4b4b6-3ffc-4a4f-b59c-33c72342a38e.jpg",
                "alt_text": "cute cat"
              }
            }
          ]
        }
      ]
    }

    const options = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      ),
      responseType: 'text' as const,
    };
    
    this.clickLoading = false;
    this.uploadService.uploadBase64Image(uploadData).subscribe(response => {
      const imgUrl = response.url;
      data.attachments[0].blocks[1].accessory.image_url = imgUrl;
      data.attachments[0].blocks[1].text.text = data.attachments[0].blocks[1].text.text.replace('##image##', imgUrl);

      this.http.post(this.webhookUrl, data, options).subscribe(response => {
        this.notificationService.showSuccess('thank you!');
        this.matDialogRef.close();
      }).add(() => this.clickLoading = false);
    },
    err => {
      console.error(err);
      this.clickLoading = false;
    });    
  }

}
