<div class="referral-block-wrapper">
  <div class="referral-block rounded-12">
    <!-- REFERRAL TITLE -->
    <div class="referral-title pave-text mgb-18">
      Refer a friend and earn N500 bonus
    </div>

    <!-- REFERRAL INPUT TEXT -->
    <div class="referral-input rounded-8 border-secondary-grey mgb-16">
      <div class="input-text footer-fill">
        {{ referralLink }}

        <div class="copy-icon pointer smooth-transition">
          <mat-icon class="surface">file_copy</mat-icon>
        </div>
      </div>
    </div>

    <!-- SHARE ROW -->
    <div class="share-row">
      <div class="share-btn">
        <img
          src="../../../../../assets/images/whatsapp-fill.svg"
          alt="whatsapp-icon"
        />
        Share
      </div>

      <div class="share-btn">
        <img
          src="../../../../../assets/images/facebook-fill.svg"
          alt="whatsapp-icon"
        />
        Share
      </div>
    </div>
  </div>
</div>
