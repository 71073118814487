<div fxLayout="column" data-html2canvas-ignore>
  <div fxLayout="column">
    <div fxLayout="column" fxLayoutGap="4px" *ngIf="imgSrc; else loading">
      <div fxLayout="row" fxLayoutGap="16px">
        <img [src]="imgSrc" height="20px" />
        <div style="color: gray; font-size: small">included screenshot</div>
      </div>
      <hr />
      <mat-divider></mat-divider>
    </div>
    <form [formGroup]="form" style="margin-top: 24px;" id="dev-feedback">
      <mat-form-field>
        <textarea matInput formControlName="comment" 
          placeholder="{{data.isPositiveFeedback ? '😊' : '🙁'}} enter comment" rows="2" 
          ngStyle.gt-md="height: 200px; min-width: 360px; margin-top: 1.4em; margin-left: 16px; font-size: small">
        </textarea>
      </mat-form-field>
    </form>
  </div>
  <button [loading]="clickLoading" fxFlex mat-raised-button color="primary" class="studypath-button" style="height: 48px; min-height: 48px;" (click)="submit()">send</button>
</div>

<ng-template #loading>
  <div style="min-width: 120px">
    <mat-spinner color="primary" [diameter]="20" [strokeWidth]="2"></mat-spinner>
  </div>
</ng-template>