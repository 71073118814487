import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AmplitudeTrackerService } from 'src/app/services/events/amplitude-tracker.service';
import { EventBusService } from 'src/app/services/events/event-bus.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SearchService } from 'src/app/services/search/search.service';

@Component({
  selector: 'app-study-search-input',
  templateUrl: './study-search-input.component.html',
  styleUrls: ['./study-search-input.component.scss'],
})
export class StudySearchInputComponent implements OnInit {
  @Input() searchSource: string = '';
  @Input() isFocus: boolean = false;
  @Input() searchPosition: string = 'absolute';

  @ViewChild('searchField') searchField: ElementRef;

  searchValue: string = '';
  selectedFilterType: string = '';

  showSearchResult: boolean = false;
  loadingSearch: boolean = false;
  resultList: { name: string; type: string }[] = [];

  currentScreenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  constructor(
    private router: Router,
    private eventBusService: EventBusService,
    private searchService: SearchService,
    private notificationService: NotificationService,
    public amplitudeTrackerService: AmplitudeTrackerService
  ) {}

  ngOnInit(): void {
    this.isFocus &&
      setTimeout(() => this.searchField.nativeElement.focus(), 1000);

    this.eventBusService.subscribeToEvent().subscribe((data) => {
      if (data === 'focus') this.searchField.nativeElement.focus();
    });
  }

  get isMobileView() {
    return this.currentScreenWidth < 860 ? true : false;
  }

  inputFieldIsFocused() {
    if (this.isMobileView) {
      this.eventBusService.emitEvent('show-search-dialog');
    }
  }

  onSearchValueChange() {
    if (this.searchValue.length >= 3) {
      this.showSearchResult = true;
      this.fetchSearchResults();
    } else this.showSearchResult = false;
  }

  fetchSearchResults() {
    this.loadingSearch = true;
    this.resultList = [];

    const searchLocations = this.searchService.getLocations({
      name: this.searchValue.toLocaleLowerCase(),
    });

    const searchPrograms = this.searchService.getAutocompleteResults(
      this.searchValue.toLocaleLowerCase()
    );

    forkJoin([searchLocations, searchPrograms]).subscribe(
      ([locations, programs]) => {
        this.resultList = this.sortDataSetOnScore([
          ...this.processSearchResult(locations),
          ...this.processSearchResult(programs.data),
        ]);

        this.loadingSearch = false;
      },
      (error) => {
        this.notificationService.showError(
          'An error occured while fetching search results. Please try again'
        );
        this.loadingSearch = false;
        this.resultList = [];
        throw error;
      }
    );
  }

  sortDataSetOnScore(data: any) {
    // Sort the array by score for program types
    const sortedData = data.sort((a: any, b: any) => a.score - b.score);

    // Filter program types based on score conditions
    const programBelow20 = sortedData.filter(
      (item: any) => item.type === 'program' && item.score < 20
    );
    const programAbove20 = sortedData.filter(
      (item: any) => item.type === 'program' && item.score >= 20
    );

    // Filter school types
    const allSchoolsAndCountry = sortedData.filter((item: any) =>
      ['country', 'school'].includes(item.type)
    );

    // Concatenate the three filtered arrays
    return programAbove20.concat(allSchoolsAndCountry, programBelow20);
  }

  processSearchResult(rawSearchList: any = []) {
    let searchList = [];

    rawSearchList?.map((search: any) => {
      let searchData: any = {};
      searchData.name = search.type ? search.name : search._id;
      searchData.type = search?.type
        ? search?.type?.toLocaleLowerCase()
        : 'program';
      searchData.score = search?.boosted_score ?? 0;

      searchList.push(searchData);
    });

    return searchList;
  }

  getFilterQueryKey(type: string) {
    return type === 'school' ? 'institution' : type;
  }

  searchFilteredItems() {
    this.amplitudeTrackerService.ProgramSearchButtonClicked();

    if (this.selectedFilterType.length) {
      const queryParams = {
        [this.getFilterQueryKey(this.selectedFilterType)]: this.searchValue,
      };

      this.router.navigate(['/', 'search'], {
        queryParams: { ...queryParams, source: this.searchSource },
      });
    } else {
      if (this.searchValue.length) {
        this.router.navigate(['/', 'search'], {
          queryParams: { program: this.searchValue, source: this.searchSource },
        });
      } else {
        this.router.navigate(['/', 'search'], {
          queryParams: { source: this.searchSource },
        });
      }
    }
  }

  updateSearchValue(selectedFilter: { name: string; type: string }) {
    const queryParamKey = {
      program: 'program',
      school: 'institution',
      country: 'country',
    };

    this.router.navigate(['/', 'search'], {
      queryParams: {
        [queryParamKey[selectedFilter.type]]: selectedFilter.name,
        source: this.searchSource,
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.currentScreenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }
}
