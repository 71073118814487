import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { menuOptionsType } from 'src/app/models/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-base-sidebar-navitem',
  templateUrl: './base-sidebar-navitem.component.html',
  styleUrls: ['./base-sidebar-navitem.component.scss'],
})
export class BaseSidebarNavitemComponent implements OnInit {
  @Input() navTitle: string;
  @Input() navStyle: string;
  @Input() linkList: string = '';
  @Input() menuOptions: menuOptionsType[] = [];

  @Output() showMenuItem = new EventEmitter();
  @Output() menuClicked = new EventEmitter();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  menuItemTriggered() {
    if (this.menuOptions.length) {
      this.showMenuItem.emit();
    } else if (this.linkList.length) {
      this.router.navigate([this.linkList], {
        queryParams: {},
        queryParamsHandling: 'merge',
        relativeTo: this.route,
      });
    } else this.menuClicked.emit();
  }
}
