<div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center">
        <mat-icon *ngIf="data.type==='error'">error_outlined</mat-icon>
        <mat-icon *ngIf="data.type==='success'">check_circle_outlined</mat-icon>
        <mat-icon *ngIf="data.type==='info'">info_outlined</mat-icon>
        <div class="message">{{data.message}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign=" center">
        <div class="spacer"></div>
        <button *ngIf="data.actionLabel" class="action-button" mat-button (click)="doAction()">{{data.actionLabel}}</button>
        <button mat-icon-button (click)="close()">
            <mat-icon class="icon">close</mat-icon>
        </button>
    </div>
</div>