import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { Course } from 'src/app/models/courses';
import { Discipline, Location } from 'src/app/models/api/search';
import { environment } from 'src/environments/environment';
import { SearchResult } from './search-models';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getCourses(filter: any): Observable<SearchResult> {
    return this.http.get<SearchResult>(`${environment.institutionApiDomain}/v1/course`, { params: filter });
  }

  getLocations(filter: any): Observable<Location[]> {
    return this.http.get<Location[]>(`${environment.institutionApiDomain}/v1/location`, { params: filter });
  }

  getDisciplines(courseName = null): Observable<Discipline[]> {
    const params = courseName ? { name: courseName } : null;
    return this.http.get<Discipline[]>(`${environment.institutionApiDomain}/v1/disciplines/old`, { params });
  }

  getDepartments(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.institutionApiDomain}/v1/disciplines`);
  }

  getAutocompleteResults(query: string): Observable<any> {
    const params = {
      action: 'autocomplete', query
    }

    return this.http.get<any>(`https://h6nso6yyn3jjvz3mbcvs23fwse0eknyn.lambda-url.eu-west-2.on.aws/`, { params });
  }

  getProgramSearchResults(filters, userSearch = false): Observable<any> {
    const userId = this.authService.getUserId();

    const options = {
      params: {
        action: 'search',
        ...filters
      }
    }

    if (userId && userSearch) {
      options['headers'] = new HttpHeaders(
        {
          'x-user-id': userId,
          'x-email': this.authService.getUserEmail()
        }
      )
    }

    return this.http.get<any>(environment.programSearchServiceDomain, options);
  }

  getGoogleInstitutions(name: string): Observable<PlaceAutocompleteResponse> {
    const options = {
      params: { name },
      headers: new HttpHeaders(
        // { "Access-Control-Allow-Origin": "*" }
      )
    };
    return this.http.get<PlaceAutocompleteResponse>('https://y2oi2gwhel.execute-api.eu-west-2.amazonaws.com/default/google-places/suggest', options);
  }

  getGoogleInstitution(id: string): Observable<any> {
    const options = {
      params: { id },
      headers: new HttpHeaders(
        // { "Access-Control-Allow-Origin": "*" }
      )
    };
    return this.http.get<any>('https://y2oi2gwhel.execute-api.eu-west-2.amazonaws.com/default/google-places/info', options);
  }

  getGoogleInstitutionWiki(name: string): Observable<any> {
    const options = {
      params: { name },
      headers: new HttpHeaders(
        // { "Access-Control-Allow-Origin": "*" }
      )
    };
    return this.http.get<any>('https://y2oi2gwhel.execute-api.eu-west-2.amazonaws.com/default/google-places/wiki', options);
  }

  getWikiText(wikiStub: string) {
    const options = {
      // params: { 'Api-User-Agent': 'sama' },
      responseType: 'text'
    };
    return this.http.get(`https://en.wikipedia.org/api/rest_v1/page/html/${wikiStub}?origin=*`, { responseType: 'text' });
  }

  getEmblemSuggestions(name: string, start: number = 1): Observable<any> {
    const options = {
      params: { name, start }
    };
    return this.http.get<any>('https://y2oi2gwhel.execute-api.eu-west-2.amazonaws.com/default/google-places/emblem-search', options);
  }

  getImageSuggestions(name: string, start: number = 1): Observable<any> {
    const options = {
      params: { name, start }
    };
    return this.http.get<any>('https://y2oi2gwhel.execute-api.eu-west-2.amazonaws.com/default/google-places/image-search', options);
  }
}

export interface PlaceAutocompletePrediction {
  description: string;
  place_id: string;
  types: string[];
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  }
}
export interface PlaceAutocompleteResponse {
  predictions: [PlaceAutocompletePrediction]
}
