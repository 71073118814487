<div class="search-input-wrapper">
  <div class="study-search-input">
    <!-- SEARCH ICON -->
    <img src="../../../../assets/images/search-icon.png" alt="search-icon" />

    <!-- SEARCH INPUT -->
    <input
      #searchField
      type="search"
      [(ngModel)]="searchValue"
      (ngModelChange)="onSearchValueChange()"
      (focus)="inputFieldIsFocused()"
      placeholder="Program, School or Country"
      class="font-outfit"
    />

    <!-- FIND BUTTON -->
    <button
      class="pave-btn pave-btn-primary pointer"
      (click)="searchFilteredItems()"
    >
      <div class="desktop-text">Find</div>
      <mat-icon class="mobile-text">search</mat-icon>
    </button>
  </div>

  <!-- SEARCH PREVIEW RESULT -->
  <app-search-preview-card
    *ngIf="showSearchResult"
    [isLoading]="loadingSearch"
    [searchResult]="resultList"
    [searchPosition]="searchPosition"
    (filterSelected)="updateSearchValue($event)"
  ></app-search-preview-card>
</div>
