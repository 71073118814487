type CurrencyDataType = {
  country_code: string;
  currency_code: string;
  currency_name: string;
  rate: number;
};

const flutterwaveCurrencies: CurrencyDataType[] = [
  {
    country_code: 'uk',
    currency_code: 'GBP',
    currency_name: 'British Pound Sterling',
    rate: 0,
  },
  {
    country_code: 'ca',
    currency_code: 'CAD',
    currency_name: 'Canadian Dollar',
    rate: 0,
  },
  {
    country_code: 'cfa',
    currency_code: 'XAF',
    currency_name: 'Central African CFA Franc',
    rate: 0,
  },
  {
    country_code: 'ch',
    currency_code: 'CLP',
    currency_name: 'Chilean Peso',
    rate: 0,
  },
  {
    country_code: 'co',
    currency_code: 'COP',
    currency_name: 'Colombian Peso',
    rate: 0,
  },
  {
    country_code: 'eg',
    currency_code: 'EGP',
    currency_name: 'Egyptian Pound',
    rate: 0,
  },
  {
    country_code: 'eu',
    currency_code: 'EUR',
    currency_name: 'SEPA',
    rate: 0,
  },
  {
    country_code: 'gh',
    currency_code: 'GHS',
    currency_name: 'Ghanaian Cedi',
    rate: 0,
  },
  {
    country_code: 'gn',
    currency_code: 'GNF',
    currency_name: 'Guinean Franc',
    rate: 0,
  },
  {
    country_code: 'ke',
    currency_code: 'KES',
    currency_name: 'Kenyan Shilling',
    rate: 0,
  },
  {
    country_code: 'mw',
    currency_code: 'MWK',
    currency_name: 'Malawian Kwacha',
    rate: 0,
  },
  {
    country_code: 'mo',
    currency_code: 'MAD',
    currency_name: 'Moroccan Dirham',
    rate: 0,
  },
  {
    country_code: 'ng',
    currency_code: 'NGN',
    currency_name: 'Nigerian Naira',
    rate: 0,
  },
  {
    country_code: 'rw',
    currency_code: 'RWF',
    currency_name: 'Rwandan Franc',
    rate: 0,
  },
  {
    country_code: 'sl',
    currency_code: 'SLL',
    currency_name: 'Sierra Leonean Leone',
    rate: 0,
  },
  {
    country_code: 'st',
    currency_code: 'STD',
    currency_name: 'São Tomé and Príncipe dobra',
    rate: 0,
  },
  {
    country_code: 'za',
    currency_code: 'ZAR',
    currency_name: 'South African Rand',
    rate: 0,
  },
  {
    country_code: 'tz',
    currency_code: 'TZS',
    currency_name: 'Tanzanian Shilling',
    rate: 0,
  },
  {
    country_code: 'ug',
    currency_code: 'UGX',
    currency_name: 'Ugandan Shilling',
    rate: 0,
  },
  {
    country_code: 'us',
    currency_code: 'USD',
    currency_name: 'United States Dollar',
    rate: 0,
  },
  {
    country_code: 'xof',
    currency_code: 'XOF',
    currency_name: 'West African CFA Franc',
    rate: 0,
  },
  {
    country_code: 'zm',
    currency_code: 'ZMW',
    currency_name: 'Zambian Kwacha',
    rate: 0,
  },
];

const regenerateCurrencyListFromBEData = (currencyList: any) => {
  let plaformCurrencies = [];
  for (const [currency_code, rate] of Object.entries(currencyList)) {
    plaformCurrencies.push({ currency_code, rate });
  }

  // FILTER FLUTTERWAVE CURRENCIES BASED ON DATA FROM THE BACKEND
  let filteredCurrency = [];

  plaformCurrencies.map((currency: any) => {
    let availableCurrency = flutterwaveCurrencies.find(
      (item) => item.currency_code === currency.currency_code
    );

    if (availableCurrency) {
      let currencyItem = { ...availableCurrency, rate: currency.rate };
      filteredCurrency.push(currencyItem);
    }
  });

  return filteredCurrency;
};

const getAvailableSavedCurrencies = (): CurrencyDataType[] => {
  const currencies: string = localStorage.getItem('currencyRatios');
  const availableCurrecies: CurrencyDataType[] = JSON.parse(currencies);
  return availableCurrecies;
};

/**
 * Expected output => "country_code", "currency_code", "currency_name", "rate"
 */
const getCurrencyData = (
  currencyCode: string,
  output: string = null
): CurrencyDataType | string | number => {
  const availableCurrecies = getAvailableSavedCurrencies();

  const selectedCurrency = availableCurrecies.find(
    (currency: CurrencyDataType) => currency.currency_code === currencyCode
  );

  return output ? selectedCurrency[output] : selectedCurrency;
};

const isCurrencyAvailable = (currencyCode: string): boolean => {
  const availableCurrecies = getAvailableSavedCurrencies();

  const currencyFound = availableCurrecies.find(
    (currency: CurrencyDataType) => currency.currency_code === currencyCode
  );

  return currencyFound ? true : false;
};

export {
  flutterwaveCurrencies,
  getAvailableSavedCurrencies,
  regenerateCurrencyListFromBEData,
  getCurrencyData,
  isCurrencyAvailable,
  CurrencyDataType,
};
