<div [fxHide]="personalInformation" class="loader" style="min-height: 400px;"></div>
<form [fxHide]="!personalInformation" fxLayout="column" [formGroup]="personalInformationForm" fxLayoutGap="64px">
    <section fxLayout="column" fxLayoutGap="16px">
        <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['firstName','lastName','gender','dateOfBirth','nationality']">Biodata Information</mat-card-subtitle>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="space-between">
            <mat-form-field fxFlex.gt-sm="32">
                <input matInput placeholder="First Name" formControlName="firstName">
            </mat-form-field>

            <mat-form-field fxFlex.gt-sm="32">
                <input matInput placeholder="Middle Name" formControlName="middleName">
            </mat-form-field>
            
            <mat-form-field fxFlex.gt-sm="32">
                <input matInput placeholder="Last Name" formControlName="lastName">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="space-between">
            <div fxLayout="row" fxFlex.gt-sm="32" fxLayoutGap="16px">
                <mat-form-field class="shrink" fxFlex>
                    <mat-select formControlName="gender" placeholder="Gender">
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Other">Other</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field class="shrink" fxFlex>
                    <mat-select formControlName="maritalStatus" placeholder="Marital Status">
                        <mat-option value="single">Single</mat-option>
                        <mat-option value="married">Married</mat-option>
                        <mat-option value="divorced">Divorced</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field fxFlex.gt-sm="32">
                <input matInput placeholder="Date of Birth" [matDatepicker]="dobPicker" [matDatepickerFilter]="noFutureDateFilter" readonly formControlName="dateOfBirth" (click)="dobPicker.open()">
                <mat-icon matSuffix>event</mat-icon>
                <mat-datepicker #dobPicker></mat-datepicker>
            </mat-form-field>
            
            <mat-form-field fxFlex.gt-sm="32">
                <input type="text" matInput [matAutocomplete]="autoN" placeholder="Nationality" formControlName="nationality" />
                <mat-autocomplete #autoN="matAutocomplete" placeholder="Nationality" (optionSelected)="setPersonalNationality($event)" [displayWith]="displayFriendlyCountry">
                <mat-option *ngFor="let country of filteredNOptions | async" [value]="country">
                    {{country.name}}
                </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="16px">
        <mat-card-subtitle class="text-web-subtitle-m" #anchor  [attr.fields]="['passportNumber','passportIssueDate','passportExpiryDate']">Passport Information</mat-card-subtitle>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
            <mat-form-field fxFlex>
                <input matInput placeholder="Passport Number" formControlName="passportNumber">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Passport Issue Date" readonly [matDatepicker]="issuePicker" [matDatepickerFilter]="noFutureDateFilter" formControlName="passportIssueDate" (click)="issuePicker.open()">
                <mat-icon matSuffix>event</mat-icon>
                <mat-datepicker #issuePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Passport Expiry Date" readonly [matDatepicker]="expiryPicker" [matDatepickerFilter]="noPastDateFilter" formControlName="passportExpiryDate" (click)="expiryPicker.open()">
                <mat-icon matSuffix>event</mat-icon>
                <mat-datepicker #expiryPicker></mat-datepicker>
            </mat-form-field>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="16px">
        <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['email','phoneNumber']">Contact Information</mat-card-subtitle>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
            <mat-form-field fxFlex>
                <input matInput placeholder="Preferred Contact Email" type="email" formControlName="email">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Phone Number" type="phone" formControlName="phoneNumber">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Alternate Phone Number" type="phone" formControlName="alternatePhoneNumber">
            </mat-form-field>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="16px">
        <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['address','countryOfResidence','stateOrProvince','city']">Residential Information</mat-card-subtitle>
        <div>
            <mat-form-field fxFlex>
                <input matInput placeholder="Current Address" type="text" formControlName="address" />
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
            <mat-form-field fxFlex>
                <input type="text" matInput [matAutocomplete]="autoC" placeholder="Country" formControlName="countryOfResidence" />
                <mat-autocomplete #autoC="matAutocomplete" placeholder="Country"
                (optionSelected)="setPersonalCountry($event)" [displayWith]="displayFriendlyCountry">
                    <mat-option *ngFor="let country of filteredCOptions | async" [value]="country">
                        {{country.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput placeholder="Province / State" type="text" formControlName="stateOrProvince">
            </mat-form-field>
            
            <mat-form-field fxFlex>
                <input matInput placeholder="City / Town" type="phone" formControlName="city">
            </mat-form-field>
            
            <mat-form-field fxFlex>
                <input matInput placeholder="Postal Code" type="phone" formControlName="postalCode">
            </mat-form-field>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="16px">
        <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['sponsor']">Sponsor Information</mat-card-subtitle>
        <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="column" fxLayoutGap="16px">
                <div>Who is sponsoring your tuition?</div>
                <mat-form-field ngStyle.gt-sm="width: 160px">
                    <mat-select [(value)]="sponsor" (selectionChange)="sponsorSelectionChanged($event)">
                        <mat-option value="self">Self</mat-option>
                        <mat-option value="guardian">Guardian</mat-option>
                        <mat-option value="other">Other</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div [fxHide]="sponsor !== 'other'" fxLayout="column" fxLayoutGap="16px">
                <div>What is your relationship with your sponsor?</div>
                <mat-form-field ngStyle.gt-sm="width: 160px">
                    <input matInput type="text" formControlName="sponsor">
                </mat-form-field>
            </div>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="16px">
        <mat-card-subtitle class="text-web-subtitle-m" #anchor [attr.fields]="['visaRefusals']">Visa Refusals</mat-card-subtitle>
        <div fxLayout="column" fxLayoutGap="16px">
            <div [fxHide]="visaRefusals.controls.length > 0" fxLayout="column" fxLayoutGap="16px">
                <div>Have you ever been refused a visa?</div>
                <mat-radio-group fxLayout="row" fxLayoutGap="16px" [(ngModel)]="visaRefusal" [ngModelOptions]="{standalone: true}" (change)="visaRefusalSelectionChanged($event)">
                    <mat-radio-button class="pave-radio" [value]="true">Yes</mat-radio-button>
                    <mat-radio-button class="pave-radio" [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" fxLayoutGap="8px" *ngIf="visaRefusals.controls.length > 0">
                <!-- <mat-divider style="position: relative;"></mat-divider> -->
                <div fxLayout="column" fxLayoutGap="16px" ngStyle.gt-md="max-width: 540px">
                    <div fxLayout="column" fxLayoutGap="16px" *ngFor="let control of visaRefusals.controls; index as i">
                        <div #card class="pave-card pave-card--darker" fxLayout="column" fxLayoutGap="8px" ngClass.lt-lg="mobile" (click)="mobileTravelRowClick(i, control.value, card)">
                            <div fxLayoutAlign="space-between center">
                                <div class="text-mobile-medium-m">{{control.get('country').value}}</div>
                                <div fxHide.gt-md fxLayout="row" fxLayoutGap="16px">
                                    <div>{{control.get('stateOrProvince').value}}</div>
                                    <div>{{control.get('date').value | date}}</div>
                                    <mat-icon>more_vert</mat-icon>
                                </div>
                            </div>
                            <div fxHide.lt-lg fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxLayoutGap="32px">
                                    <div fxLayout="column">
                                        <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Region</div>
                                        <div class="text-mobile-smallest-r">{{control.get('stateOrProvince').value}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div class="text-mobile-smallest-r" style="font-size: 10px; color: #3F3F74">Date</div>
                                        <div class="text-mobile-smallest-r">{{control.get('date').value}}</div>
                                    </div>
                                </div>
                                <div>
                                    <button mat-icon-button (click)="openVisaRefusal(i, control.value)">
                                        <mat-icon style="font-size: 16px;">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn" (click)="deleteRefusal(i, control.value)">
                                        <mat-icon style="font-size: 16px;">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button mat-stroked-button class="pave-button" color="primary" (click)="openVisaRefusal()" *ngIf="visaRefusal && visaRefusals.length > 0" ngStyle.gt-md="max-width: 540px">
                    <mat-icon>add</mat-icon>
                    <span style="margin-left: 8px">Add More</span>
                </button>
            </div>
        </div>
    </section>

    <!-- <div fxLayoutAlign="center">
    <button class="studypath-button main" mat-raised-button color="primary" fxFlex.lt-md="100" [disabled]="savingPersonalButton"
        (click)="savePersonalInformation()">{{ savingPersonalButton ? 'saving' : 'save'}}</button>
    </div> -->
</form>