<div class="wrapper" ngClass.gt-sm="desktop">
  <div class="container" fxLayout="column" fxFlex>
    <app-base-topbar></app-base-topbar>

    <div
      fxLayout="row"
      fxLayoutAlign=" stretch"
      fxFlex
      style="overflow-x: hidden"
    >
      <app-root fxFlex></app-root>
    </div>
  </div>
</div>
