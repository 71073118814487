import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UserPersonalInformation } from 'src/app/models/api/user';
import { EditDeleteDialogComponent } from 'src/app/modules/shared/edit-delete-dialog/edit-delete-dialog.component';
import { Country, ExternalService } from 'src/app/services/external/external.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserService } from 'src/app/services/user/user.service';
import { EditVisaRefusalComponent } from './edit-visa-refusal/edit-visa-refusal.component';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {

  @Input() studentId: string;
  @Input() countryCodeMap: Map<string, Country>;
  @Input() countryList: Country[];
  @Input() personalInformation: UserPersonalInformation;
  @Output() reloaded = new EventEmitter();
  
  rejections = [];
  personalInformationForm: FormGroup;
  visaRefusalForm: FormGroup;
  visaRefusal = false;
  sponsor;
  visaRefusals: FormArray;

  savingPersonalButton = false;
  filteredCOptions: Observable<Country[]>;
  filteredNOptions: Observable<Country[]>;

  @ViewChildren('anchor') private _anchors: QueryList<ElementRef>;

  get anchors() {
    return this._anchors.map(a => {
      const fields: string[] = a.nativeElement.attributes.fields.value.split(',');      
      const completeMap = fields.map(f => !!this.personalInformationForm.get(f).value);
      
      return {
        name: a.nativeElement.innerHTML,
        complete: completeMap.every(v => v)
      }
    });
  }

  get anchorPositions() {
    return this._anchors.map(a => {
      return {
        parentStub: 'personalInformation',
        stub: a.nativeElement.innerHTML.replace(' ',''),
        pos: a.nativeElement.getBoundingClientRect().top
      }
    });
  }

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private externalService: ExternalService,
    private notificationService: NotificationService
  ) {
    this.personalInformationForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      middleName: [null, null],
      lastName: [null, Validators.required],
      gender: [null, null],
      email: [null, Validators.email],
      dateOfBirth: [null, null],
      maritalStatus: [null, null],
      nationality: [null, null],
      phoneNumber: [null, null],
      alternatePhoneNumber: [null, null],
      passportNumber: [null, null],
      passportIssueDate: [null, null],
      passportExpiryDate: [null, null],
      sponsor: [null, null],
      address: [null, null],
      countryOfResidence: [null, null],
      stateOrProvince: [null, null],
      city: [null, null],
      postalCode: [null, null],
      visaRefusals: this.formBuilder.array([])
    });

    this.visaRefusals = this.personalInformationForm.controls.visaRefusals as FormArray;

    this.filteredNOptions = this.personalInformationForm.get('nationality').valueChanges
      .pipe(
        map(value => typeof value === 'string' ? value : value?.name),
        map(value => this._nationalityFilter(value))
      );

    this.filteredCOptions = this.personalInformationForm.get('countryOfResidence').valueChanges
      .pipe(
        map(value => typeof value === 'string' ? value : value?.name),
        map(value => this._nationalityFilter(value))
      );
  }

  ngOnInit() {
    this.externalService.getCountries().subscribe(
      countries => {
        this.countryCodeMap = new Map(countries.map(c => [c.alpha3Code, c]));
        this.countryList = countries;
      }
    );

    this.getPersonalInformation();
  }

  getPersonalInformation() {
    this.userService.getPersonalInformation(this.studentId).subscribe(personalInformation => {
      this.personalInformation = personalInformation;
      
      this.personalInformationForm.patchValue({
        firstName: this.personalInformation.firstName || null,
        middleName: this.personalInformation.middleName || null,
        lastName: this.personalInformation.lastName || null,
        gender: this.personalInformation.gender || null,
        email: this.personalInformation.email || null,
        dateOfBirth: this.getLocalDate(this.personalInformation.dateOfBirth),
        maritalStatus: this.personalInformation.maritalStatus || null,
        nationality: this.personalInformation.nationality || null,
        phoneNumber: this.personalInformation.phoneNumber || null,
        alternatePhoneNumber: this.personalInformation.alternatePhoneNumber || null,
        passportNumber: this.personalInformation.passportNumber || null,
        passportIssueDate: this.getLocalDate(this.personalInformation.passportIssueDate),
        passportExpiryDate: this.getLocalDate(this.personalInformation.passportExpiryDate),
        sponsor: this.personalInformation.sponsor || null,
        address: this.personalInformation.address || null,
        countryOfResidence: this.personalInformation.countryOfResidence || null,
        stateOrProvince: this.personalInformation.stateOrProvince || null,
        city: this.personalInformation.city || null,
        postalCode: this.personalInformation.postalCode || null
      });
      
      if (this.personalInformation.sponsor) {
        if (['self','parents'].includes(this.personalInformation.sponsor)) {
          this.sponsor = this.personalInformation.sponsor;
        } else {
          this.sponsor = 'other';
        }
      }

      if (this.personalInformation.visaRefusals?.length > 0) {
        this.visaRefusal = true;
      }

      this.personalInformation.visaRefusals?.forEach(visaRefusal => {
        this.visaRefusals.push(this.formBuilder.group({
          id: [visaRefusal.id, []],
          country: [visaRefusal.country, Validators.required],
          stateOrProvince: [visaRefusal.stateOrProvince, Validators.required],
          reason: [visaRefusal.reason, Validators.required],
          date: [this.formatDate(this.getLocalDate(visaRefusal.date)), Validators.required]
        }));
      });

      this.reloaded.emit(true);

      this.personalInformationForm.valueChanges.pipe(
        debounceTime(1500)
      ).subscribe(_ => {
        this.savePersonalInformation();
      });
    });
  }

  visaRefusalSelectionChanged(event) {
    if (event.value === true) {
      if (this.visaRefusals.length === 0) {
        this.openVisaRefusal();
      }
    } else {
      this.visaRefusals.clear();
    }
  }

  sponsorSelectionChanged(event) {
    this.personalInformationForm.controls.sponsor.setValue(
      event.value === 'other' ? null : event.value
    );
  }

  openVisaRefusal(index=null, data=null) {
    this.dialog.open(EditVisaRefusalComponent, {
      data: {
        previousIndex: index,
        previousData: data,
        countryList: this.countryList
      },
      width: '88%',
      maxWidth: '540px',
    }).afterClosed().subscribe(data => {
      if (data) {
        this.addvisaRefusal(index, data);
      } else {
        if (this.visaRefusals.controls.length === 0) {
          this.visaRefusal = false;
        }
      }
    });
  }

  addvisaRefusal(index, data: any) {
    const control = this.formBuilder.group({
      id: [null, []],
      country: [data.country, Validators.required],
      stateOrProvince: [data.stateOrProvince, Validators.required],
      reason: [data.reason, Validators.required],
      date: [data.date, Validators.required]
    });

    console.log(index, control);
    
    if (index === null) {
      this.visaRefusals.push(control);
      console.log('inserted control', control, this.visaRefusals);
      
    } else {
      this.visaRefusals.removeAt(index, {emitEvent: false});
      this.visaRefusals.insert(index, control);
      console.log('modified control', this.visaRefusals);
    }
    // this.savePersonalInformation();
  }

  deleteRefusal(index: number, data: any) {
    console.log(index, data);
    
    this.userService.removeVisaRefusal(data.id, this.studentId).subscribe(_ => {
      this.visaRefusals.removeAt(index);

      if (this.visaRefusals.length === 0) {
        this.visaRefusal = false;
      }
    },
    error => {
      console.error('error removing refusal', error);
      this.notificationService.showError('There was an error removing this entry. Please try again');
    });
    
  }

  mobileTravelRowClick(index, data, target: HTMLElement) {
    if (!target.classList.contains('mobile')) {
      return;
    }
    
    this.dialog.open(EditDeleteDialogComponent, {
      width: '80vw',
      autoFocus: false,
      panelClass: 'edit-delete-dialog'
    }).afterClosed().subscribe(action => {
      if (action === 'edit') {
        this.openVisaRefusal(index, data);
      } else if (action === 'delete') {
        this.deleteRefusal(index, data);
      }
    });
  }

  savePersonalInformation() {
    console.log('saving', this.visaRefusals.value, this.personalInformationForm.value);
    
    if (!this.visaRefusal) {
      this.visaRefusals.clear();
    }

    if (!this.personalInformationForm.valid) {
      // return;
    }

    const dob = this.personalInformationForm.controls.dateOfBirth.value;
    const pid = this.personalInformationForm.controls.passportIssueDate.value;
    const ped = this.personalInformationForm.controls.passportExpiryDate.value;

    const data = {
      ...this.personalInformationForm.value,
      dateOfBirth: dob ? new Date(dob.getTime() - (dob.getTimezoneOffset() * 60 * 1000)) : dob,
      passportIssueDate: pid ? new Date(pid.getTime() - (pid.getTimezoneOffset() * 60 * 1000)) : pid,
      passportExpiryDate: ped ? new Date(ped.getTime() - (ped.getTimezoneOffset() * 60 * 1000)) : ped,
      nationality: this.personalInformationForm.controls.nationality.value?.name,
      countryOfResidence: this.personalInformationForm.controls.countryOfResidence.value?.name
    };

    this.savingPersonalButton = true;
    this.notificationService.broadcastSaving();
    
    this.userService.updateUserPersonalProfile(data, this.studentId).subscribe(
      _ => {
        this.savingPersonalButton = false;
        this.notificationService.broadcastSaved();
        setTimeout(() => this.reloaded.emit(true), 100);
      },
      error => {
        console.error(error);
        this.savingPersonalButton = false;        
      }
    );
  }

  private _nationalityFilter(value: string): Country[] {
    if (!value) {
      value = '';
    }

    const filterValue = value.toLowerCase();

    return this.countryList?.filter(country => country.name.toLowerCase().includes(filterValue));
  }

  setPersonalNationality($event: MatAutocompleteSelectedEvent) {
    const country: Country = $event.option.value;
    this.personalInformationForm.controls.nationality.setValue(country);
  }

  setPersonalCountry($event: MatAutocompleteSelectedEvent) {
    const country: Country = $event.option.value;
    this.personalInformationForm.controls.countryOfResidence.setValue(country);
  }

  displayFriendlyCountry(value: string | Country) {
    if (typeof value === 'string') {
      return value;
    }

    return value ? value.name : undefined;
  }

  noFutureDateFilter = (d: Date | null): boolean => {
    return d < new Date();
  }

  noPastDateFilter = (d: Date | null): boolean => {
    return !this.noFutureDateFilter(d);
  }

  getLocalDate(dateString: string) {
    if (!dateString) return null;
    
    const date = new Date(dateString);
    const localDate = date.getTime() + (60 * 1000 * date.getTimezoneOffset());
    
    return new Date(localDate);
  }

  formatDate(date: Date) {
    return date.toISOString().split('T')[0];
  }

  scrollToView(fragment: string) {
    const anchor = this._anchors.map(a => a.nativeElement).find(a => a.innerHTML.replace(' ', '').toLocaleLowerCase() === fragment);
    window.scrollTo({top: window.scrollY + anchor.getBoundingClientRect().top - 88, behavior: 'smooth'});
  }
}
