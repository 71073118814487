import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AcademicQualification } from 'src/app/models/api/user';
import { Country, ExternalService } from 'src/app/services/external/external.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-edit-qualification-row',
  templateUrl: './edit-qualification-row.component.html',
  styleUrls: ['./edit-qualification-row.component.scss']
})
export class EditQualificationRowComponent implements OnInit {

  studentId: string;
  qualification: AcademicQualification;
  countryCodeMap: Map<string, Country>;
  countryList: Country[];
  
  form: FormGroup;
  filteredCOptions: Observable<Country[]>;

  emptyGrade = {
    gradeAttained: null,
    gradeAttainable: null
  }

  isLetterGradeSystem = true;
  saving = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private externalService: ExternalService,
    public dialogRef: MatDialogRef<EditQualificationRowComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {studentId: string, qualification: AcademicQualification}
  ) {    
    this.studentId = data.studentId;
    this.qualification = {...data.qualification};
  }

  ngOnInit() {
    this.externalService.getCountries().subscribe(
      countries => {
        this.countryCodeMap = new Map(countries.map(c => [c.alpha3Code, c]));
        this.countryList = countries;
      }
    );

    this.form = this.formBuilder.group({
      id: [null, null],
      schoolName: [null, Validators.required],
      resumptionDate: [null, Validators.required],
      graduationDate: [null, Validators.required],
      degreeAwarded: [null, Validators.required],
      gradeType: [null, []],
      gradeAttained: [null, []],
      course: [null, Validators.required],
      country: [null, Validators.required],
      languageOfInstruction: [null, Validators.required]
    });

    if (this.qualification) {
      if (!['letter','4-point-number','5-point-number'].includes(this.qualification.gradeType)) {
        if (this.qualification.grade.gradeAttainable === '4') {
          this.qualification.gradeType = 'four-point-number';
        } else if (this.qualification.grade.gradeAttainable === '5') {
          this.qualification.gradeType = 'five-point-number';
        } else {
          this.qualification.gradeType = 'letter';
        }
      }

      this.qualification.gradeAttained = this.qualification.grade.gradeAttained;
      this.qualification.graduationDate = this.getLocalDate(String(this.qualification.graduationDate));
      this.qualification.resumptionDate = this.getLocalDate(String(this.qualification.resumptionDate));

      this.form.patchValue(this.qualification);
    }

    this.filteredCOptions = this.form.get('country').valueChanges
      .pipe(
        map(value => typeof value === 'string' ? value : value?.name),
        map(value => this._nationalityFilter(value))
      );
  }
  
  displayFriendlyCountry(value: string | Country) {
    if (typeof value === 'string') {
      return value;
    }

    return value ? value.name : undefined;
  }

  setAcademicCountry($event: MatAutocompleteSelectedEvent) {
    const country: Country = $event.option.value;
    this.form.controls.country.setValue(country);
  }
  
  private _nationalityFilter(value: string): Country[] {
    if (!value) {
      value = '';
    }

    const filterValue = value.toLowerCase();

    return this.countryList?.filter(country => country.name.toLowerCase().includes(filterValue));
  }

  saveAcademicInformation() {
    if (!this.form.valid) {      
      return;
    }

    const data = {
      ...this.form.getRawValue(),
      country: this.form.controls.country.value?.name,
      gradeType: this.isLetterGradeSystem ? 'letter' : 'number',
      resumptionDate: this.formatDate(this.form.controls.resumptionDate.value),
      graduationDate: this.formatDate(this.form.controls.graduationDate.value),
    };

    this.saving = true;

    this.userService.addOrEditUserAcademicQualification(data, this.studentId).subscribe(
      _ => {
        this.dialogRef.close(true);
      },
      error => {
        console.error(error);
        this.saving = false;
      }
    );
  }

  noFutureDateFilter = (d: Date | null): boolean => {
    return d < new Date();
  }

  formatDate(date: Date) {
    return date.toISOString().split('T')[0];
  }

  getLocalDate(dateString: string) {
    if (!dateString) return null;
    
    const date = new Date(dateString);
    const localDate = date.getTime() + (60 * 1000 * date.getTimezoneOffset());
    
    return new Date(localDate);
  }

}
