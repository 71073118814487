<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="0px" fxLayoutGap.gt-sm="10%">
    <!-- <div class="auth-bg" ngClass.lt-md="mobile" ngClass.md="tablet"></div> -->
    <div>
      <mat-card *ngIf="confirmed">
        <form [formGroup]="form" id="reset-password" (submit)="reset()">
          <mat-card-header fxLayout="column">
            <mat-card-title>
              <div style="font-weight: 600;">Account Setup</div>
              <p class="small-text">
                Set a password for your account so you can login and access all features
              </p>
            </mat-card-title>
          </mat-card-header>
  
          <mat-card-content fxLayout="column" fxLayoutGap="16px">
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input [type]="passwordFieldType" matInput formControlName="password" name="password">
              <mat-icon class="visibility-toggle" *ngIf="passwordFieldType === 'text'" matSuffix (click)="passwordFieldType='password'">visibility</mat-icon>
              <mat-icon class="visibility-toggle" *ngIf="passwordFieldType === 'password'" matSuffix (click)="passwordFieldType='text'">visibility_off</mat-icon>
              <mat-error>password required</mat-error>
            </mat-form-field>

            <mat-checkbox #agreed style="font-size: smaller">
              I agree to the 
              <a href="information/terms-and-conditions" target="_blank">Terms and Privacy Policy</a> 
              and the 
              <a href="information/agent-agreement" target="_blank">Agent's Agreement</a>
            </mat-checkbox>
            
            <button mat-raised-button color="primary" [loading]="isLoading" class="pave-button" [disabled]="!agreed.checked">
              Continue
            </button>
          </mat-card-content>
  
        </form>
      </mat-card>
      <mat-card *ngIf="!confirmed">
        <mat-card-content *ngIf="!error">
          <mat-progress-spinner diameter="32" strokeWidth="2" color="primary" [mode]="'indeterminate'"></mat-progress-spinner>
          Loading
        </mat-card-content>
        <mat-card-content *ngIf="error">
          <p>Something's wrong. Please contact support at <a href="mailto:support@pavehq.com">support@pavehq.com</a></p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>