import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-sidebar-referral-block',
  templateUrl: './base-sidebar-referral-block.component.html',
  styleUrls: ['./base-sidebar-referral-block.component.scss'],
})
export class BaseSidebarReferralBlockComponent implements OnInit {
  referralLink: string = 'https://pavehq.com/sandra-Ikeagwo-pave';

  constructor() {}

  ngOnInit(): void {}
}
