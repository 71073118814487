import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-caregiver-pathway-layout',
  templateUrl: './caregiver-pathway-layout.component.html',
  styleUrls: ['./caregiver-pathway-layout.component.scss']
})
export class CaregiverPathwayLayoutComponent implements OnInit {
  
  isSticky = false;
  opened = false;
  sectionName;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 80;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.url.subscribe(k => {      
      const parts = this.getPageUrl().split('/');

      for (let i in parts) {
        if (parts[i] === 'caregiver-pathway' || parts[i] === 'caregiver') {
          this.sectionName = parts[Number.parseInt(i)+1].replace('-', ' ');
        }
      }
    })
  }

  getPageUrl() {
    return new URL(document.URL).pathname;
  }

  hideSidebar() {
    return this.getPageUrl().includes('/landing') || this.getPageUrl().includes('/continue') || this.getPageUrl().includes('/finish');
  }

  hideMenu() {
    return this.isLanding() || this.getPageUrl().includes('caregiver-pathway/continue') || this.getPageUrl().includes('/finish');
  }

  isLanding() {
    return this.getPageUrl().includes('caregiver-pathway/landing') || this.getPageUrl().includes('caregiver/landing');
  }

  get saving() {
    return sessionStorage.getItem('ukcg-saving') !== null;
  }

  get errorSaving() {
    const errors =  JSON.parse(sessionStorage.getItem('ukcg-errors-saving')) || {};
    return Object.keys(errors).length > 0;
  }
}
