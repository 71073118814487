<mat-dialog-content>
  <div
    class="modal-dialog-wrapper modal-size"
    [ngClass]="'modal-size-' + modalWidth"
  >
    <!-- MODAL DIALOG HEADER -->
    <div class="modal-dialog-header">
      <!-- HEADER CONTENT -->
      <div class="header-content">
        <div *ngIf="modalHasBackState && !modalHasSteps">
          <!-- DIALOG BACK STATE -->
          <div class="dialog-steps">
            <mat-icon class="pointer mgr-2" (click)="backwardClick.emit()"
              >arrow_back</mat-icon
            >
            <div class="step-text primary-gray">Back</div>
          </div>

          <div class="dialog-title">
            {{ modalTitle }}
          </div>
        </div>

        <!-- DIALOG STEPS -->
        <div *ngIf="modalHasSteps">
          <div class="dialog-steps">
            <mat-icon
              class="pointer"
              *ngIf="modalActiveStep"
              (click)="backwardClick.emit()"
              >arrow_back</mat-icon
            >
            <div class="step-text primary-gray">
              Step {{ +modalActiveStep + 1 }} of {{ modalTotalSteps }}
            </div>
          </div>

          <div class="dialog-title">
            {{ modalActiveTitle }}
          </div>
        </div>

        <!-- DIALOG TITLE -->
        <div class="dialog-title" *ngIf="!modalHasBackState && !modalHasSteps">
          {{ modalTitle }}
        </div>
      </div>

      <!-- CLOSE DIALOG -->
      <div
        *ngIf="modalIsDismissible"
        class="close-dialog"
        title="Close dialog"
        (click)="closeTriggered.emit()"
      >
        <div class="close-wrapper">
          <mat-icon>clear</mat-icon>
        </div>
      </div>
    </div>

    <!-- MODAL DIALOG BODY -->
    <div class="modal-dialog-body">
      <ng-content select="[modal-dialog-body]"></ng-content>
    </div>

    <!-- MODAL DIALOG FOOTER -->
    <div class="modal-dialog-footer" *ngIf="modalHasActions">
      <ng-content select="[modal-dialog-footer]"></ng-content>
    </div>
  </div>
</mat-dialog-content>
