import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-edit-language-certification',
  templateUrl: './edit-language-certification.component.html',
  styleUrls: ['./edit-language-certification.component.scss']
})
export class EditLanguageCertificationComponent implements OnInit {

  saving = false;
  form: FormGroup;
  studentId: string;
  certification: { id?: any; type: any; date: any; score: any; };

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private diaglogRef: MatDialogRef<EditLanguageCertificationComponent>,
    @Inject(MAT_DIALOG_DATA) data: {studentId: string; certification: {id?: string, type: string, date: string, score: string}}
  ) {
    this.studentId = data.studentId;
    this.certification = data.certification;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [this.certification?.id, []],
      type: [this.certification?.type, Validators.required],
      date: [this.getLocalDate(this.certification?.date), Validators.required],
      score: [this.certification?.score, Validators.required]
    });
  }
  
  saveLanguageCertifications() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.saving = true;
    const data = this.form.getRawValue();
    data.date = this.formatDate(data.date);

    this.userService.addLanguageCertification(data, this.studentId).subscribe(
      _ => {
        this.diaglogRef.close(true);
      },
      error => {
        console.error(error);        
        this.saving = false;
      }
    );
  }

  noFutureDateFilter = (d: Date | null): boolean => {
    return d < new Date();
  }

  formatDate(date: Date) {
    return date.toISOString().split('T')[0];
  }

  getLocalDate(dateString: string) {
    if (!dateString) return null;
    
    const date = new Date(dateString);
    const localDate = date.getTime() + (60 * 1000 * date.getTimezoneOffset());
    
    return new Date(localDate);
  }
}
