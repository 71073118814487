import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../../../services/settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CurrencyPipe } from '@angular/common';
import { ExternalService } from 'src/app/services/external/external.service';
import { ApplicationService } from 'src/app/services/application/application.service';
import { getCurrencyData } from '../currency-selector/currencies';

@Pipe({
  name: 'currencyView',
  pure: true,
})
export class CurrencyViewPipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private applicationService: ApplicationService,
    private currencyPipe: CurrencyPipe
  ) {}

  transform(
    value: number,
    sourceCurrency: string = 'USD',
    decimals = 2,
    targetCurrency: string = null
  ): string {
    const preferredCurrency =
      targetCurrency || this.settingsService.getCurrency();

    if (sourceCurrency !== 'USD') {
      const sourceRatio =
        sourceCurrency === preferredCurrency
          ? 1
          : this.getRatio(sourceCurrency);

      if (sourceRatio === -1) {
        return this.currencyPipe.transform(
          value,
          sourceCurrency,
          'symbol-narrow',
          `1.${decimals}-2`
        );
      }

      value = value * sourceRatio;
    }

    const ratio =
      sourceCurrency === preferredCurrency
        ? 1
        : this.getRatio(preferredCurrency);
    const result = (value * ratio).toFixed(decimals);

    if (ratio === -1) {
      this.settingsService.setDefaultCurrency('USD', true);
      return this.currencyPipe.transform(
        value,
        'USD',
        'symbol-narrow',
        `1.${decimals}-2`
      );
    }

    return this.currencyPipe.transform(
      result,
      preferredCurrency,
      'symbol-narrow',
      `1.${decimals}-2`
    );
  }

  getValue(
    value: number,
    sourceCurrency: string = 'USD',
    decimals = 2,
    targetCurrency: string = null
  ): number {
    const preferredCurrency =
      targetCurrency || this.settingsService.getCurrency();

    if (sourceCurrency !== 'USD') {
      const sourceRatio =
        sourceCurrency === preferredCurrency
          ? 1
          : this.getRatio(sourceCurrency);

      if (sourceRatio === -1) {
        return value;
      }

      value = value * sourceRatio;
    }

    const ratio =
      sourceCurrency === preferredCurrency
        ? 1
        : this.getRatio(preferredCurrency);
    const result = value * ratio;

    if (ratio === -1) {
      this.settingsService.setDefaultCurrency('USD', true);
      return value;
    }

    return result;
  }

  getRatio(currency: string): number {
    try {
      return +getCurrencyData(currency, 'rate') || 1;
      // let ratios = localStorage.getItem('currencyRatios');
      // return JSON.parse(ratios)['rates'][currency] || -1;
    } catch (e) {
      console.log('error loading ratios', e);
      return -1;
    }
  }
}
