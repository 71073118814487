import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportingDocument } from 'src/app/models/api/user';
import { UserService } from 'src/app/services/user/user.service';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  @Input() studentId: string;
  @ViewChildren('anchor') private _anchors: ElementRef[];
  @Output() reloaded = new EventEmitter();

  loaded = false;
  supportingDocuments: SupportingDocument[] = [];

  get _filteredAnchors() {
    return this._anchors.filter(a => {
      return !a.nativeElement.classList.value.includes('hidden-anchor');
    });
  }

  get anchors() {
    // console.log('filtered anchors', this._filteredAnchors);
    
    return this._filteredAnchors.map(a => { return {
      name: a.nativeElement.innerHTML,
      complete: a.nativeElement.innerHTML.toLocaleLowerCase().includes('unuploaded') ? false : true
    }});
  }

  get anchorPositions() {
    return this._filteredAnchors.map(a => { return {
      parentStub: 'supportingDocuments',
      stub: a.nativeElement.innerHTML.replace(' ',''),
      pos: a.nativeElement.getBoundingClientRect().top
    }});
  }

  get uploadedDocTypes() {
    return this.documentTypes.filter(dt => this.getDocuments(dt.stub).length > 0);
  }

  get unUploadedDocTypes() {
    return this.documentTypes.filter(dt => this.getDocuments(dt.stub).length === 0);
  }

  documentTypes = [
    {'name': 'University Transcript', stub: 'universityTranscript', multi: true},
    {'name': 'University Certificate', stub: 'universityCertificate', multi: true},
    {'name': 'WAEC / NECO Certificate', stub: 'waecOrNeco', multi: true},
    {'name': 'High School Certificate', stub: 'highSchoolCertificate', multi: true},
    {'name': 'Passport Photograph', stub: 'passportPhotograph', multi: false},
    {'name': 'C.V.', stub: 'cv', multi: false},
    {'name': 'Research Proposal', stub: 'researchProposal', multi: true},
    {'name': 'International Passport', stub: 'internationalPassport', multi: false},
    {'name': 'Birth Certificate', stub: 'birthCertificate', multi: false},
    {'name': 'References', stub: 'referees', multi: true},
    {'name': 'Other Documents', stub: 'otherDocuments', multi: true},
  ]

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.fetchUserDocuments();
  }

  fetchUserDocuments() {
    this.userService.getSupportingDocuments(this.studentId).subscribe(supportingDocuments => {
      this.supportingDocuments = supportingDocuments;
      this.loaded = true;
      setTimeout(() => this.reloaded.emit(true), 100);
    });
  }

  getDocuments(type: string) {
    return this.supportingDocuments.filter(d => d.type === type);
  }

  openUploadDialog(type, docs) {
    this.dialog.open(UploadDialogComponent, {
      data: {userId: this.studentId, type, docs},
      width: '88%',
      maxWidth: '540px',
      panelClass: 'no-padding-dialog',
      disableClose: true,
      autoFocus: false
    }).afterClosed().subscribe(_ => {
      this.fetchUserDocuments();
    });
  }

  emitUploaded(document: SupportingDocument = null) {
    this.fetchUserDocuments();
  }

  emitDeleted(id: string) {    
    this.supportingDocuments = this.supportingDocuments.filter(d => d.id !== id);
    setTimeout(() => this.reloaded.emit(true), 100);
  }

  scrollToView(fragment: string) {
    const anchor = this._anchors.map(a => a.nativeElement).find(a => a.innerHTML.replace(' ', '').toLocaleLowerCase() === fragment);
    window.scrollTo({top: window.scrollY + anchor.getBoundingClientRect().top - 88, behavior: 'smooth'});
  }

}
