<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="center center"
  fxLayoutGap="0px"
  fxLayoutGap.gt-sm="10%"
  [ngClass]="inDialog ? 'in-dialog' : ''"
>
  <div
    class="auth-bg"
    ngClass.lt-md="mobile"
    [ngClass.md]="inDialog ? 'tablet in-dialog' : 'tablet'"
  ></div>
  <div class="auth-body" ngClass.lt-md="mobile">
    <mat-card [ngClass]="inDialog ? 'in-dialog' : ''">
      <div *ngIf="error" class="login-error-message">
        Incorrect password and email combination. Please click on forgot
        password below to reset your password
      </div>
      <mat-card-header fxLayout="column">
        <mat-card-title>
          <div class="fw-semibold">Hello,</div>
          <p class="fw-regular f-size-16">Please login to continue</p>
        </mat-card-title>
      </mat-card-header>

      <ng-container *ngIf="isSocialSignin; else emailSignin">
        <app-social-signin
          (emailClicked)="isSocialSignin = false"
        ></app-social-signin>
      </ng-container>
    </mat-card>

    <div
      class="actions"
      fxLayout="row"
      fxLayoutAlign="space-between"
      style="padding: 32px 0px"
    >
      <div>
        <a
          (click)="closeDialog()"
          [routerLink]="['/', 'session', 'request-reset-password']"
          style="text-decoration: none; color: inherit"
          >Forgot Password?</a
        >
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div>
        <a
          (click)="
            amplitudeTrackerService.RegisterButtonClicked(); closeDialog()
          "
          [routerLink]="['/', 'session', 'sign-up']"
          [queryParams]="{ returnUrl: getPageUrl() }"
          style="text-decoration: none"
          >Register Here</a
        >
      </div>
    </div>
  </div>
</div>

<ng-template #emailSignin>
  <form [formGroup]="form" id="login">
    <mat-card-content fxLayout="column" fxLayoutGap="16px">
      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="username" />
        <mat-error>email required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          [type]="passwordFieldType"
          matInput
          formControlName="password"
          name="password"
        />
        <mat-icon
          class="visibility-toggle"
          *ngIf="passwordFieldType === 'text'"
          matSuffix
          (click)="passwordFieldType = 'password'"
          >visibility</mat-icon
        >
        <mat-icon
          class="visibility-toggle"
          *ngIf="passwordFieldType === 'password'"
          matSuffix
          (click)="passwordFieldType = 'text'"
          >visibility_off</mat-icon
        >
        <mat-error>password required</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        [loading]="loading"
        (click)="login()"
        style="text-transform: uppercase; height: 50px"
      >
        Login
      </button>
    </mat-card-content>
  </form>
</ng-template>
