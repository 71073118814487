<div fxLayout="column" fxLayoutGap="32px">
    <div class="text-web-subtitle-m">Education History</div>
    <form class="academic-form" fxLayout="column" [formGroup]="form" fxLayoutGap="24px" id="profile-academic">
        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="16px">
            <mat-form-field fxFlex>
                <input matInput placeholder="School Name" formControlName="schoolName">
            </mat-form-field>

            <mat-form-field><!--/mat-form-field> fxFlex.gt-sm="30"-->
                <input type="text" matInput [matAutocomplete]="autoC" placeholder="Country" formControlName="country" />
                <mat-autocomplete #autoC="matAutocomplete" placeholder="Country"
                    (optionSelected)="setAcademicCountry($event)" [displayWith]="displayFriendlyCountry">
                    <mat-option *ngFor="let country of filteredCOptions | async" [value]="country">
                        {{country.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        
        <div fxLayout="column" fxLayoutGap="16px">
            <mat-form-field fxFlex>
                <input matInput placeholder="Program Name" formControlName="course">
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-select formControlName="degreeAwarded" placeholder="Degree Awarded">
                    <mat-option value="highSchool">High School</mat-option>
                    <mat-option value="undergraduateDiploma">Undergraduate Diploma</mat-option>
                    <mat-option value="bachelors">Bachelors (or equivalent)</mat-option>
                    <mat-option value="postgraduateDiploma">Postgraduate Diploma</mat-option>
                    <mat-option value="masters">Masters (or equivalent)</mat-option>
                    <mat-option value="doctorate">Doctorate (or equivalent)</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="16px">
            <mat-form-field class="shrink">
                <input matInput [matDatepicker]="resumePicker" [matDatepickerFilter]="noFutureDateFilter" readonly placeholder="Resumption Date" formControlName="resumptionDate" (click)="resumePicker.open()">
                <mat-icon matSuffix>event</mat-icon>
                <mat-datepicker #resumePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="shrink">
                <input matInput [matDatepicker]="gradPicker" readonly placeholder="Graduation Date" formControlName="graduationDate" (click)="gradPicker.open()">
                <mat-icon matSuffix>event</mat-icon>
                <mat-datepicker #gradPicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="16px">
            <mat-form-field><!-- fxFlex.gt-sm="30"-->
                <mat-select formControlName="languageOfInstruction" placeholder="Language of Instruction">
                    <mat-option value="english">English</mat-option>
                    <mat-option value="french">French</mat-option>
                    <mat-option value="other">Other</mat-option>
                </mat-select>
            </mat-form-field>
            
            <div fxLayout="row" fxLayoutGap="16px">
                <mat-form-field fxFlex class="shrink">
                    <mat-select formControlName="gradeType" placeholder="Grade Type">
                        <mat-option value="letter">Letter</mat-option>
                        <mat-option value="four-point-number">4 point Scale</mat-option>
                        <mat-option value="five-point-number">5 point Scale</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex class="shrink">
                    <input matInput formControlName="gradeAttained" placeholder="Grade">
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" fxFlexAlign="end" fxLayoutGap="16px">
            <button mat-dialog-close class="pave-button" mat-button color="warn">Cancel</button>
            <button [loading]="saving" [disabled]="!form.valid" class="pave-button" mat-raised-button color="primary" (click)="saveAcademicInformation()">
                Save
            </button>
        </div>
    </form>
</div>