import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NumberLiteralType } from 'typescript';

@Injectable({
  providedIn: 'root',
})
export class ExternalService {
  constructor(public http: HttpClient) {}

  getCountries(): Observable<Country[]> {
    const options = {
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' }),
    };
    return this.http.get<Country[]>(
      'https://qnhxdss5rd.execute-api.eu-west-2.amazonaws.com/default/countries',
      options
    );
  }

  getGeoInformationX(): Observable<GeoInformationX> {
    return this.http.get<GeoInformationX>('http://www.geoplugin.net/json.gp');
  }

  getGeoInformation(): Observable<GeoInformation> {
    /* Checking if there is a value in local storage called 'geo' and if there is it will parse it into
    a JSON object. If there is no value in local storage called 'geo' it will set it to an empty
    object. */
    let geo_details = JSON.parse(localStorage.getItem('geo')) ?? {};

    if (Object.keys(geo_details).length) {
      /* Creating a new observable and returning it. */
      return new Observable<GeoInformation>((observer) => {
        observer.next(geo_details);
        observer.complete();
      });
    } else
      return this.http
        .get<GeoInformation>('https://api.ipregistry.co/?key=kxsy1b4ryd5txack')
        .pipe(catchError((err) => throwError(err)));
  }
}
export interface Country {
  name: string;
  alpha2Code: any;
  alpha3Code: string;
}

export interface GeoInformationX {
  geoplugin_request: string;
  geoplugin_status: number;
  geoplugin_delay: string;
  geoplugin_credit: string;
  geoplugin_city: string;
  geoplugin_region: string;
  geoplugin_regionCode: string;
  geoplugin_regionName: string;
  geoplugin_areaCode: string;
  geoplugin_dmaCode: string;
  geoplugin_countryCode: string;
  geoplugin_countryName: string;
  geoplugin_inEU: number;
  geoplugin_euVATrate: boolean;
  geoplugin_continentCode: string;
  geoplugin_continentName: string;
  geoplugin_latitude: string;
  geoplugin_longitude: string;
  geoplugin_locationAccuracyRadius: number;
  geoplugin_timezone: string;
  geoplugin_currencyCode: string;
  geoplugin_currencySymbol: string;
  geoplugin_currencySymbolUTF8: string;
  geoplugin_currencyConverter: number;
}

export interface GeoInformation {
  currency?: {
    code: string;
    name: string;
    symbol_native: string;
  };
  location?: {
    country: {
      code: string;
    };
  };
}
