import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserPersonalInformation } from 'src/app/models/api/user';
import { AmplitudeTrackerService } from 'src/app/services/events/amplitude-tracker.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-user-info-capture',
  templateUrl: './user-info-capture.component.html',
  styleUrls: ['./user-info-capture.component.scss'],
})
export class UserInfoCaptureComponent implements OnInit {
  pageIndex = 2;
  form: FormGroup;
  highestDegreeObtained;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<UserInfoCaptureComponent, any>,
    public amplitudeTrackerService: AmplitudeTrackerService,
    @Inject(MAT_DIALOG_DATA) data: UserPersonalInformation
  ) {
    const educationHistory = data.educationHistory;

    if (educationHistory && educationHistory[0]) {
      this.highestDegreeObtained = educationHistory[0].degreeAwarded;
    }

    this.form = this.formBuilder.group({
      firstName: [data.firstName, []],
      lastName: [data.lastName, []],
      phoneNumber: [data.phoneNumber, []],
      highestDegreeObtained: [this.highestDegreeObtained, []],
    });
  }

  ngOnInit() {
    this.amplitudeTrackerService.AboutYourselfModalShown(
      this.trackingPayloadData()
    );
  }

  trackingPayloadData() {
    const { firstName, lastName, phoneNumber, highestDegreeObtained } =
      this.form.value;

    return {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      highest_degree: highestDegreeObtained,
    };
  }

  trackRemindLaterButtonClick() {
    this.amplitudeTrackerService.AboutYourselfModalLaterClicked();
  }

  trackDismissButtonClick() {
    this.amplitudeTrackerService.AboutYourselfModalDismissed();
  }

  save() {
    this.amplitudeTrackerService.AboutYourselfModalSaveClicked(
      this.trackingPayloadData()
    );

    if (!this.form.valid) {
      return;
    }

    let { highestDegreeObtained, ...data } = this.form.value;

    this.userService.updateUserPersonalProfile(data).subscribe((profile) => {
      if (highestDegreeObtained) {
        const data = {
          course: ' ',
          degreeAwarded: highestDegreeObtained,
        };
        this.userService
          .addOrEditUserAcademicQualification(data)
          .subscribe((response) => {
            this.notificationService.showSuccess(
              'Information saved to profile. Thanks!'
            );
            this.dialogRef.close();
          });
      } else {
        this.notificationService.showSuccess(
          'Information saved to profile. Thanks!'
        );
        this.dialogRef.close();
      }
    });
  }
}
