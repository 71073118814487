<div fxLayout="column" fxLayoutGap="32px">
    <div class="text-web-subtitle-m">Visa Refusal</div>
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="16px">
        <mat-form-field fxFlex.gt-sm="25">
            <mat-label>Country of refusal</mat-label>
            <input type="text" matInput [matAutocomplete]="auto" placeholder="Country" formControlName="country" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setCountry($event)"  [displayWith]="displayFriendlyCountry">
            <mat-option *ngFor="let country of filteredCOptions | async" [value]="country">
                {{country.name}}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        
        <mat-form-field fxFlex.gt-sm="25">
            <mat-label>State / Region / Province</mat-label>
            <input matInput type="text" formControlName="stateOrProvince">
        </mat-form-field>

        <mat-form-field fxFlex (click)="picker.open()">
            <mat-label>Date of refusal</mat-label>
            <input matInput readonly [matDatepicker]="picker" [matDatepickerFilter]="noFutureDateFilter" formControlName="date" (focus)="picker.open()">
            <mat-icon matSuffix>event</mat-icon>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex class="grow-height">
            <mat-label>Reason for refusal</mat-label>
            <textarea matInput rows="5" formControlName="reason"></textarea>
        </mat-form-field>
    </form>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
        <button mat-button mat-dialog-close color="warn" class="pave-button">Cancel</button>
        <button mat-raised-button color="primary" class="pave-button" (click)="save()">Save</button>
    </div>
</div>
