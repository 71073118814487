<form
  [ngClass.gt-sm]="'search-form desktop'"
  [ngClass.lt-md]="'search-form'"
  [formGroup]="form"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="20px"
  fxLayoutGap.lt-md="8px"
  fxLayoutAlign=" center"
  id="basic-course-search"
>
  <mat-form-field
    ngStyle.lt-md="width: 100%;"
    ngStyle.md="width: 18em"
    appearance="outline"
  >
    <input
      matInput
      formControlName="program"
      name="program"
      placeholder="Program"
      [matAutocomplete]="autoProgram"
    />
    <mat-autocomplete #autoProgram="matAutocomplete" [panelWidth]="'30em'">
      <!-- <mat-optgroup *ngFor="let group of filteredPrograms" [label]="group.type | titlecase"> -->
      <mat-option
        *ngFor="let option of filteredAutocomplete"
        [value]="option"
        (onSelectionChange)="programSelectionChanged()"
      >
        {{ option }}
      </mat-option>
      <!-- </mat-optgroup> -->
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field
    ngStyle.lt-md="width: 100%;"
    ngStyle.md="width: 12em"
    appearance="outline"
    *ngIf="showLocationInput"
  >
    <input
      matInput
      formControlName="location"
      placeholder="Location"
      [matAutocomplete]="autoLocation"
    />
    <mat-autocomplete #autoLocation="matAutocomplete" [panelWidth]="'30em'">
      <mat-optgroup
        *ngFor="let group of filteredLocations"
        [label]="group.type | titlecase"
      >
        <mat-option
          *ngFor="let option of group.results"
          [value]="option"
          (onSelectionChange)="locationSelectionChanged(group.type)"
        >
          {{ option }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field
    ngStyle.lt-md="width: 100%;"
    ngStyle.md="width: 10em"
    appearance="outline"
  >
    <mat-select
      formControlName="level"
      placeholder="Level"
      [disableOptionCentering]="true"
      panelClass="lower-panel"
    >
      <mat-option value="undergraduate">Undergraduate</mat-option>
      <mat-option value="postgraduate">Postgraduate</mat-option>
    </mat-select>
    <mat-error>please select a level</mat-error>
  </mat-form-field>

  <!-- <mat-form-field ngStyle.lt-md="width: 100%;" ngStyle.md="width: 10em" appearance="outline">
    <mat-select formControlName="budget" placeholder="Budget (USD)" [disableOptionCentering]="true" panelClass="lower-panel">
      <mat-option value="0-5000">Under 5,000</mat-option>
      <mat-option value="0-10000">Under 10,000</mat-option>
      <mat-option value="0-15000">Under 15,000</mat-option>
      <mat-option value="0-20000">Under 20,000</mat-option>
      <mat-option>No limit</mat-option>
    </mat-select>
  </mat-form-field> -->

  <button
    fxHide.lt-md
    class="studypath-button"
    ngStyle.lt-md="width: 100%;"
    mat-icon-button
    color="primary"
    [disabled]="form.invalid ? false : false"
    (click)="doSearch()"
  >
    <mat-icon>search_outlined</mat-icon>
  </button>

  <div fxHide.gt-sm style="width: 100%">
    <button
      class="pave-button"
      style="
        width: 100%;
        height: 56px;
        margin-top: 8px;
        border-radius: 28px !important;
      "
      mat-raised-button
      color="primary"
      [disabled]="form.invalid ? false : false"
      (click)="doSearch()"
    >
      <!-- <mat-icon style="margin-right: 8px; height: 16px; width: 16px; font-size: 16px;">search_outlined</mat-icon> -->
      <span>Search</span>
    </button>
  </div>
</form>
