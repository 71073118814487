import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UploadService } from 'src/app/services/upload/upload.service';

@Pipe({
  name: 'presignedView'
})
export class PresignedViewPipe implements PipeTransform {
  USER_DOCS_PATH = '/UserDocs/';

  constructor(
    private uploadService: UploadService
  ) {}

  transform(url: string): Observable<string> {
    if (!url) {
      return;
    }
    
    const fileName = url.substring(url.lastIndexOf('/')+1, url.length);
    const userId = url.substring(url.lastIndexOf(this.USER_DOCS_PATH) + this.USER_DOCS_PATH.length, url.lastIndexOf('/'));

    return new Observable<string>(subscriber => {
      subscriber.next('/assets/images/empty.png');

      if (url && url !== 'undefined') {
        this.uploadService.getPreSignedUrl('get', fileName, userId).subscribe(response => {
          subscriber.next(response.url);
        });
      }
    });

    // return of('loading.gif');
    // return this.uploadService.getPreSignedUrl('get', fileName)
    // .pipe(
    //   map(response => response.url)
    // )    
  }

}
