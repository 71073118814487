import {
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UserLoginRequest,
  UserRole,
  UserRouteAccountType,
} from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AmplitudeTrackerService } from 'src/app/services/events/amplitude-tracker.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
})
export class LogInComponent implements OnInit {
  @Input() inDialog = false;

  form: FormGroup;
  authService: AuthService;
  passwordFieldType = 'password';
  error = false;
  loading = false;
  isSocialSignin: boolean = true;

  constructor(
    private router: Router,
    private injector: Injector,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    public amplitudeTrackerService: AmplitudeTrackerService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<LogInComponent>
  ) {
    setTimeout(() => (this.authService = this.injector.get(AuthService)));

    if (data) {
      if (data.inDialog) {
        this.inDialog = true;
      }
    }

    this.form = this.formBuilder.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  ngOnInit() {}

  login() {
    if (this.form.invalid) {
      return;
    }

    this.error = false;
    this.loading = true;

    const loginRequest: UserLoginRequest = { ...this.form.value };
    this.amplitudeTrackerService.LoginEmailButtonClicked(loginRequest.username);

    this.authService.login(loginRequest).subscribe(
      (response) => {
        const returnUrl = this.route.snapshot.queryParams.returnUrl;
        const redirectUserList: string[] = [
          UserRole.Agent,
          UserRole.Affiliate,
          UserRole.Admin,
        ];

        // CLOSED LOGIN DIALOG IF OPENED
        if (this.inDialog) {
          this.amplitudeTrackerService.LoginModalDismissed({
            status: 'success',
          });
          this.dialogRef.close();
        }

        // CHECK IF USER ROLE IS IN REDIRECT LIST
        else if (redirectUserList.includes(this.authService.getUserRole())) {
          // HANDLE OPERATIVE AGENT REDIRECT
          if (this.authService.getUserRole() === UserRole.Agent) {
            this.handleAgentLogin(
              this.authService.getUserRole(),
              response?.status ?? 'UNREGISTERED'
            );
          }

          // HANDLE ADIMN USER AND AFFILIATE AGENT REDIRECT
          else {
            this.redirectUser(['dashboard'], this.authService.getUserRole());
          }
        } else if (returnUrl) {
          this.router.navigateByUrl(returnUrl);
        } else {
          this.router.navigateByUrl('/');
        }
      },
      (error) => {
        const { message } = error.error;
        const userEmail = this.form.value.username;

        if (message.includes('requires email validation')) {
          this.authService.handleUserEmailValidation(message, userEmail);
        } else {
          this.error = true;
          this.loading = false;
        }
      }
    );
  }

  handleAgentLogin(agentType: string, status: string = '') {
    if (status === 'INVITED')
      this.redirectUser(['agent-onboarding', 'account-info'], agentType);
    else if (status === 'PENDING')
      this.redirectUser(['agent-onboarding', 'complete'], agentType);
    else this.redirectUser(['dashboard'], agentType);
  }

  redirectUser(url: string[], userType: string) {
    this.router.navigate(['/', UserRouteAccountType[userType], ...url]);
  }

  closeDialog() {
    if (this.inDialog) {
      this.amplitudeTrackerService.LoginModalDismissed({
        status: 'canceled',
      });
      this.dialogRef.close();
    }
  }

  getPageUrl() {
    return new URL(document.URL).pathname;
  }
}
