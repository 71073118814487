import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as changeCaseKeys from 'change-case-keys';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  flatMap,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api/common';
import {
  UserAccountInfo,
  UserLoginRequest,
  UserLoginResponse,
  UserProfile,
  UserRegistrationRequest,
  UserRegistrationResponse,
  UserRole,
} from 'src/app/models/api/user';
import { LogInComponent } from 'src/app/modules/session/log-in/log-in.component';
import { UserInfoCaptureComponent } from 'src/app/modules/shared/user-info-capture/user-info-capture.component';
import { AmplitudeTrackerService } from 'src/app/services/events/amplitude-tracker.service';
import { environment } from 'src/environments/environment';
import { LocalStoreService } from '../local-store/local-store.service';
import { NotificationService } from '../notification/notification.service';
declare var hsq: Function;
declare var _hsp: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private fetchUserProfileInProgress = false;
  private userProfileSubject = new BehaviorSubject<UserProfile | null>(
    undefined
  );

  constructor(
    private dialog: MatDialog,
    public http: HttpClient,
    public router: Router,
    public notificationService: NotificationService,
    private localStoreService: LocalStoreService,
    private amplitudeTrackerService: AmplitudeTrackerService
  ) {}

  public isAuthenticated(): boolean {
    this.validateAccessToken();

    const accessToken = this.getAccessToken();
    return accessToken ? true : false;
  }

  public logout() {
    // EXCLUDE CURRENCY, GEO AND CURRENCY RATIOS BEFORE CLEARING LOCAL STORAGE
    this.localStoreService.clearLocalStorage([
      'currency',
      'geo',
      'currencyRatios',
    ]);

    hsq(['identify', {}]);

    document.cookie =
      'hubspotutk=;Path=/;Domain=.localhost;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      '__hstc=;Path=/;Domain=.localhost;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      'hubspotutk=;Path=/;Domain=.hubspot.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      '__hstc=;Path=/;Domain=.hubspot.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    _hsp.push(['revokeCookieConsent']);
  }

  public promptLogin(classNames: string = ''): MatDialogRef<LogInComponent> {
    this.amplitudeTrackerService.LoginModalShown();

    const dialogRef = this.dialog.open(LogInComponent, {
      data: {
        inDialog: true,
      },
      panelClass: classNames,
    });

    return dialogRef;
  }

  public promptUserInfo(
    data: UserProfile
  ): MatDialogRef<UserInfoCaptureComponent> {
    if (data.phoneNumber || data.role === 'studypath_admin') return;

    this.dialog.open(UserInfoCaptureComponent, {
      data,
      width: '96%',
      maxWidth: '640px',
      panelClass: 'fullscreen-modal',
    });
  }

  public getUsername(): string {
    return this.getUserId();
  }

  public getUserId(): string {
    return localStorage.getItem('user_userId');
  }

  public getUserIdFromString(inputText: string): string {
    const userIdPattern =
      /\b[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\b/;

    const match = inputText.match(userIdPattern);
    return match ? match[0] : null;
  }

  public getUserEmail(): string {
    return localStorage.getItem('user_email');
  }

  public getUserImage(): string {
    const userImage = localStorage.getItem('user_imageUrl');

    if (!userImage || userImage === 'undefined') {
      return null;
    }

    return userImage;
  }

  public getUserFirstName(): string {
    return localStorage.getItem('user_firstName');
  }

  public getUserFullName(): string {
    return (
      localStorage.getItem('user_firstName') +
      ' ' +
      localStorage.getItem('user_lastName')
    );
  }

  public getUserPhone(): string {
    return localStorage.getItem('user_phone');
  }

  public getUserRole(): string {
    return localStorage.getItem('user_role');
  }

  public getBusinessStatus(): string {
    return localStorage.getItem('user_businessStatus');
  }

  public getAgentStatus(): string {
    return localStorage.getItem('user_status');
  }

  public async getUserReferralCode(): Promise<string> {
    let referralCode = localStorage.getItem('user_referralCode');

    // referral codes are now stored in ls
    // if a user was logged in before this change, they might not have this stored
    if (!referralCode) {
      referralCode = (await this.refreshAccessTokenForUser().toPromise())
        .referralCode;
    }

    return referralCode;
  }

  public getAccessToken(): string {
    return localStorage.getItem('user_accessToken');
  }

  public handleUserEmailValidation(message: string, userEmail: string) {
    const userId = this.getUserIdFromString(message);

    if (userId) {
      localStorage.setItem('user_userId', userId);
      localStorage.setItem('user_email', userEmail);
      this.router.navigateByUrl('/session/registration-confirmation');
    } else
      this.notificationService.showError(
        'An error occurred while trying to send user verifications'
      );
  }

  public registerUser(
    registration: UserRegistrationRequest
  ): Observable<UserRegistrationResponse> {
    // registration.role = 'studypath_student';
    return this.http.post<UserRegistrationResponse>(
      `${environment.authApiDomain}/v1/register`,
      registration
    );
  }

  // @ts-ignore
  public requestCode(userId: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${userId}/send-verify-code-email`,
      {}
    );
  }

  public validateCode(object: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${environment.userApiDomain}/v1/user/confirm-email`,
      {},
      { params: object }
    );
  }

  public requestResetPassword(username: string) {
    return this.http.put(
      `${environment.authApiDomain}/v1/${username}/reset-password`,
      null,
      { responseType: 'text' }
    );
  }

  public confirmResetToken(userId: string, token: string) {
    const params = { userId, token };
    return this.http.get(
      `${environment.authApiDomain}/v1/reset-password/validate`,
      { params, responseType: 'text' }
    );
  }

  public resetPassword(userId: string, newPassword: string) {
    const params = { userId, newPassword };
    return this.http.put(
      `${environment.authApiDomain}/v1/reset-password/confirm`,
      params,
      { responseType: 'text' }
    );
  }

  public login(loginRequest: UserLoginRequest): Observable<UserProfile> {
    loginRequest = {
      username: loginRequest.username.trim(),
      password: loginRequest.password.trim(),
    };

    return this.generateAccessTokenForUser(loginRequest).pipe(
      flatMap<UserLoginResponse, Observable<UserAccountInfo>>((_) =>
        this.fetchAccountInfo()
      ),
      flatMap<any, Observable<UserProfile>>((_) => this.fetchUserProfile()),
      tap<UserProfile>((response) => {
        this.storeUserProfile(response);

        if (response.role === UserRole.Student) {
          this.promptUserInfo(response);
        }
        // RE-INITIALIZE SPLIT
        // this.splitService.initClient(response.id);
      })
    );
  }

  public googleClientSignIn(authPayload: any) {
    return this.http
      .post<ApiResponse>(
        `${environment.authApiDomain}/v1/google-sign-in`,
        authPayload
      )
      .pipe(
        map((response) => changeCaseKeys(response, 'camelize', 0)),
        tap<UserLoginResponse>((response) => {
          localStorage.setItem('user_userId', response.userId);
          localStorage.setItem('user_accessToken', response.accessToken);
          localStorage.setItem('user_refreshToken', response.refreshToken);
        }),
        flatMap<UserLoginResponse, Observable<UserAccountInfo>>((_) =>
          this.fetchAccountInfo()
        ),
        flatMap<any, Observable<UserProfile>>((_) => this.fetchUserProfile())
      );
  }

  private generateAccessTokenForUser(
    loginRequest: UserLoginRequest
  ): Observable<UserLoginResponse> {
    return this.http
      .post<UserLoginResponse>(
        `${environment.authApiDomain}/v1/sign-in`,
        loginRequest
      )
      .pipe(
        map((response) => changeCaseKeys(response, 'camelize', 0)),
        tap<UserLoginResponse>((response) => {
          localStorage.setItem('user_userId', response.userId);
          localStorage.setItem('user_accessToken', response.accessToken);
          localStorage.setItem('user_refreshToken', response.refreshToken);
        })
      );
  }

  public refreshAccessTokenForUser(): Observable<UserProfile> {
    const refreshToken = localStorage.getItem('user_refreshToken');
    if (!refreshToken) return of(null);

    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1)
      );
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.http
        .post<UserProfile>(`${environment.authApiDomain}/v1/refresh-token`, {
          refreshToken,
        })
        .pipe(
          map((response) => changeCaseKeys(response, 'camelize', 0)),
          tap<UserLoginResponse>((response) => {
            localStorage.setItem('user_accessToken', response.accessToken);
            localStorage.setItem('user_refreshToken', response.refreshToken);

            // this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(response.accessToken);
          }),
          flatMap<any, Observable<UserProfile>>((_) => this.fetchUserProfile()),
          // tap<UserProfile>(response => this.storeUserProfile(response)),
          catchError((error) => {
            console.log('=============> refresh error');

            this.refreshTokenInProgress = false;
            return throwError(error);
          }),
          finalize(() => (this.refreshTokenInProgress = false))
        );
    }
  }

  private validateAccessToken(): Observable<any> {
    const accessToken = this.getAccessToken();
    return of(accessToken);
  }

  private fetchAccountInfo(): Observable<UserAccountInfo> {
    const userId = this.getUserId();
    return this.http.get<UserProfile>(
      `${environment.userApiDomain}/v1/user/${userId}/account-info`
    );
  }

  // [os] this is a student's personal info, not actually the "user profile"
  // TODO: fix this to fetch an account's profile, and it's references
  private fetchUserProfile(): Observable<UserProfile> {
    if (this.fetchUserProfileInProgress) {
      return EMPTY;
    }

    this.fetchUserProfileInProgress = true;

    const userId = this.getUserId();
    return this.http
      .get<UserProfile>(
        `${environment.userApiDomain}/v1/user/${userId}/personal-info`
      )
      .pipe(
        tap((userProfile) => this.storeUserProfile(userProfile)),
        finalize(() => (this.fetchUserProfileInProgress = false))
      );
  }

  // [os] not necessarily safe
  // TODO: route all calls through getUserProfile
  private storeUserProfile(userProfile: UserProfile): void {
    this.userProfileSubject.next(userProfile);
    localStorage.setItem('user_email', userProfile.email);
    localStorage.setItem('user_imageUrl', userProfile.imageUrl);
    localStorage.setItem('user_firstName', userProfile.firstName);
    localStorage.setItem('user_lastName', userProfile.lastName);
    localStorage.setItem('user_phone', userProfile.phoneNumber);
    localStorage.setItem('user_role', userProfile.role);
    localStorage.setItem('user_referralCode', userProfile.referralCode);
    localStorage.setItem('user_businessStatus', userProfile?.businessStatus);
    localStorage.setItem('user_status', userProfile?.status);
  }

  public getUserProfile(): Observable<UserProfile> {
    return this.userProfileSubject.pipe(
      switchMap((up) => (up === undefined ? this.fetchUserProfile() : of(up))),
      // tap(up => console.log('tapped', up)),
      // filter(up => up !== undefined),
      // tap(up => console.log('double tapped', up)),
      take(1)
    );
  }

  public setUserImageUrl(imageUrl: string) {
    localStorage.setItem('user_imageUrl', imageUrl);
  }

  public async getAdminType() {
    return (await this.getUserProfile().toPromise())?.type;
  }

  public async getAdminTeam() {
    return (await this.getUserProfile().toPromise())?.team;
  }

  public async getAdminInstitutions() {
    return (await this.getUserProfile().toPromise())?.institutionIds || [];
  }
}
