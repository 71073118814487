<div class="base-footer footer-fill-bg">
  <!-- BASE FOOTER TOP -->
  <div class="pave-container base-footer-top">
    <!-- FOOTER PANE LEFT -->
    <div class="footer-pane--left">
      <div class="footer-brand-copy font-avenir">
        We are enabling people to get quality education seamlessly, all over the
        world.
      </div>

      <div class="footer-brand-socials">
        <a
          href="https://www.twitter.com/pavehqafrica"
          target="_blank"
          class="social-icon twitter"
        ></a>

        <a
          href="https://web.facebook.com/pavehqafrica"
          target="_blank"
          class="social-icon facebook"
        ></a>

        <a
          href="https://www.linkedin.com/company/pavehqafrica"
          target="_blank"
          class="social-icon linkedin"
        ></a>

        <a
          href="https://www.instagram.com/pavehqafrica"
          target="_blank"
          class="social-icon instagram"
        ></a>

        <!-- <a
          href="https://blog.pavehq.com"
          target="_blank"
          class="social-icon blogger"
        ></a> -->
      </div>

      <div class="footer-brand-logo">
        <img
          src=".././../../../assets/images/pave-logo-light.png"
          alt="pave-logo"
          class="wt-100 ht-auto"
        />
      </div>
    </div>

    <!-- FOOTER PANE MID -->
    <div class="footer-pane--mid">
      <!-- <div class="footer-links mobile-display">
        <div class="footer-links--header">About Us</div>
      </div> -->

      <div class="footer-links">
        <div
          class="footer-links--header"
          [ngClass]="isMobileView && 'pointer'"
          (click)="triggerModalView('serviceLinks')"
        >
          Services
        </div>

        <a
          [href]="link.link ? link.link : 'javascript:void(0)'"
          class="footer-links--item"
          *ngFor="let link of serviceLinks"
          >{{ link.title }}</a
        >
      </div>

      <div class="footer-links">
        <div
          class="footer-links--header"
          [ngClass]="isMobileView && 'pointer'"
          (click)="triggerModalView('quickLinks')"
        >
          Quicklinks
        </div>

        <a
          [href]="link.link"
          class="footer-links--item"
          *ngFor="let link of quickLinks"
          >{{ link.title }}</a
        >
      </div>

      <div class="footer-links">
        <div
          class="footer-links--header"
          [ngClass]="isMobileView && 'pointer'"
          (click)="triggerModalView('supportLinks')"
        >
          Support
        </div>

        <a
          [href]="link.link"
          class="footer-links--item"
          *ngFor="let link of supportLinks"
          >{{ link.title }}</a
        >
      </div>
    </div>

    <!-- FOOTER PANE RIGHT -->
    <div class="footer-pane--right">
      <div class="footer-select-title mgb-20">Default Currency</div>

      <app-currency-selector></app-currency-selector>
    </div>
  </div>

  <!-- BASE FOOTER BOTTOM -->
  <div class="base-footer-bottom">
    © {{ currentYear.getFullYear() }} Pave. All Rights Reserved
  </div>
</div>
