import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserRole } from 'src/app/models/api/user';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/', 'session', 'log-in'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    // Calculate permissions
    const leaf = this.getLeafRoute(route);

    if (
      leaf.data.roles &&
      !leaf.data.roles.includes(this.authService.getUserRole())
    ) {
      this.router.navigate(['/']);
      return false;
    }

    const adminType = await this.authService.getAdminType();
    if (
      leaf.data.adminTypes &&
      adminType &&
      !leaf.data.adminTypes.includes(adminType)
    ) {
      this.router.navigate(['/', 'admin']);
      return false;
    }

    const adminTeam = this.authService.getAdminTeam();
    if (
      leaf.data.adminTeams &&
      adminTeam &&
      !leaf.data.adminTeams.includes(adminTeam)
    ) {
      this.router.navigate(['/', 'admin']);
      return false;
    }

    // Operative agent checks
    if (this.authService.getUserRole() === UserRole.Agent) {
      const isApproved = this.isOperativeAgentApproved();

      if (!isApproved) {
        const baseAllowedRoute = '/operative-agent/agent-onboarding/complete';
        const alternateAllowedRoute =
          '/operative-agent/agent-onboarding/account-info';

        if (![baseAllowedRoute, alternateAllowedRoute].includes(state.url)) {
          if (this.authService.getAgentStatus() === 'INVITED') {
            this.router.navigate([alternateAllowedRoute]);
            return false;
          }

          this.router.navigate([baseAllowedRoute]);
          return false;
        }
        return true;
      }
    }

    return true;
  }

  private isOperativeAgentApproved(): boolean {
    const agentBusinessStatus =
      this.authService?.getAgentStatus() ??
      this.authService.getBusinessStatus();
    return agentBusinessStatus?.toLowerCase() === 'active';
  }

  private getLeafRoute(root: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (root.children?.length > 0) {
      return this.getLeafRoute(root.children[0]);
    }
    return root;
  }
}
