import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  constructor() {}
  private eventSubject = new Subject<any>();

  emitEvent(event: any = null) {
    this.eventSubject.next(event);
  }

  subscribeToEvent(): Observable<any> {
    return this.eventSubject.asObservable();
  }
}
