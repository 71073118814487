import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-registration-confirmation',
  templateUrl: './registration-confirmation.component.html',
  styleUrls: ['./registration-confirmation.component.scss']
})
export class RegistrationConfirmationComponent {
  code: string;
  email: string;
  userId: string;
  
  constructor(private route: ActivatedRoute) {
    this.code = this.route.snapshot.queryParams.code;
    this.email = this.route.snapshot.queryParams.email;
    this.userId = this.route.snapshot.queryParams.id;
  }
}
