import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CourseApplication,
  NewCourseApplication,
} from 'src/app/models/api/application';
import { ApiResponse } from 'src/app/models/api/common';
import {
  AcademicCertification,
  AcademicInformation,
  SupportingDocument,
  UserPersonalInformation,
  UserProfile,
} from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  removeVisaRefusal(
    visaRefusalId: number,
    overrideUserId?: string,
  ): Observable<any> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.delete<any>(
      `${environment.userApiDomain}/v1/user/${userId}/visa-refusal/${visaRefusalId}/remove`,
    );
  }

  addOrEditUserAcademicQualification(
    object: any,
    overrideUserId?: string,
  ): Observable<ApiResponse> {
    if (true || !object.id) {
      return this.addUserAcademicQualification(object, overrideUserId);
    }

    // return this.http.delete<ApiResponse>(`${environment.userApiDomain}/v1/user/${userId}/academic-info/${object.id}/remove`)
    //   .pipe(switchMap(_ => this.addUserAcademicQualification(object)));
  }

  addUserAcademicQualification(
    object: any,
    overrideUserId?: string,
  ): Observable<ApiResponse> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${userId}/academic-info/add`,
      object,
    );
  }

  removeUserAcademicQualification(
    id: string,
    overrideUserId?: string,
  ): Observable<ApiResponse> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.delete<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${userId}/academic-info/${id}/remove`,
    );
  }

  updateUserAcademicEnglishInformation(
    data,
    overrideUserId?: string,
  ): Observable<ApiResponse> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${userId}/academic-info`,
      { englishExamination: data },
    );
  }

  updateRequirementFulfilment(
    applicationId: string,
    fulfilmentId: number,
    url: string,
  ): Observable<ApiResponse> {
    const username = this.authService.getUsername();
    const object = { value: url };
    return this.http.put<ApiResponse>(
      `${environment.apiDomain}/user/${username}/applications/${applicationId}/requirement_fulfilments/${fulfilmentId}/fulfilment`,
      object,
    );
  }

  getUserProfile(): Observable<UserProfile> {
    const username = this.authService.getUsername();
    return this.http.get<UserProfile>(
      `${environment.apiDomain}/user/me/profile`,
    );
  }

  // personal information
  getPersonalInformation(
    overrideUserId?: string,
  ): Observable<UserPersonalInformation> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.get<UserPersonalInformation>(
      `${environment.userApiDomain}/v1/user/${userId}/personal-info`,
    );
  }

  updateUser(
    id: string,
    object: any,
    overrideUserId?: string,
  ): Observable<ApiResponse> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.patch<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${id}`,
      object,
    );
  }

  updateUserPersonalProfile(
    object: any,
    overrideUserId?: string,
  ): Observable<ApiResponse> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${userId}/personal-info`,
      object,
    );
  }

  // academic information
  getAcademicInformation(
    overrideUserId?: string,
  ): Observable<AcademicInformation> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.get<AcademicInformation>(
      `${environment.userApiDomain}/v1/user/${userId}/academic-info`,
    );
  }

  // language certifications
  getLanguageCertifications(
    overrideUserId?: string,
  ): Observable<AcademicCertification[]> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.get<AcademicCertification[]>(
      `${environment.userApiDomain}/v1/user/${userId}/language-certification`,
    );
  }

  addLanguageCertification(
    data,
    overrideUserId?: string,
  ): Observable<AcademicCertification[]> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<AcademicCertification[]>(
      `${environment.userApiDomain}/v1/user/${userId}/language-certification/add`,
      data,
    );
  }

  saveLanguageCertifications(
    data,
    overrideUserId?: string,
  ): Observable<AcademicCertification[]> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<AcademicCertification[]>(
      `${environment.userApiDomain}/v1/user/${userId}/language-certification`,
      data,
    );
  }

  // other certifications
  getOtherCertifications(
    overrideUserId?: string,
  ): Observable<AcademicCertification[]> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.get<AcademicCertification[]>(
      `${environment.userApiDomain}/v1/user/${userId}/other-certification`,
    );
  }

  addOtherCertification(
    data,
    overrideUserId?: string,
  ): Observable<AcademicCertification[]> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<AcademicCertification[]>(
      `${environment.userApiDomain}/v1/user/${userId}/other-certification/add`,
      data,
    );
  }

  saveOtherCertifications(
    data,
    overrideUserId?: string,
  ): Observable<AcademicCertification[]> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<AcademicCertification[]>(
      `${environment.userApiDomain}/v1/user/${userId}/other-certification`,
      data,
    );
  }

  // document information
  getSupportingDocuments(
    overrideUserId?: string,
  ): Observable<SupportingDocument[]> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.get<SupportingDocument[]>(
      `${environment.userApiDomain}/v1/user/${userId}/support-document`,
    );
  }

  updateUserDocument(
    type: string,
    fileName: string,
    sourceUri: string,
    overrideUserId?: string,
  ): Observable<any> {
    const userId = overrideUserId ?? this.authService.getUserId();
    const object = { type, fileName, sourceUri };
    return this.http.post<any>(
      `${environment.userApiDomain}/v1/user/${userId}/support-document/add`,
      object,
    );
  }

  removeUserDocument(
    documentId: string,
    overrideUserId?: string,
  ): Observable<ApiResponse> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.delete<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${userId}/support-document/${documentId}/remove`,
    );
  }

  // users
  getUsers(
    types: string[] = [],
    filter: any = {},
    display: any = {},
  ): Observable<any> {
    let params = {};

    if (types) {
      params['type'] = types;
    }

    Object.assign(params, filter, display);

    return this.http.get<any>(`${environment.userApiDomain}/v1/user`, {
      params: params,
    });
  }

  getUser(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.userApiDomain}/v1/user/${id}`);
  }

  deleteUser(userId: string): Observable<any[]> {
    return this.http.delete<any>(
      `${environment.authApiDomain}/v1/delete-account`,
      { params: { userId } },
    );
  }

  // applications
  getApplications(): Observable<CourseApplication[]> {
    const username = this.authService.getUsername();
    return this.http.get<CourseApplication[]>(
      `${environment.apiDomain}/user/${username}/applications`,
    );
  }

  getApplication(id: string): Observable<CourseApplication> {
    const username = this.authService.getUsername();
    return this.http.get<CourseApplication>(
      `${environment.apiDomain}/user/${username}/applications/${id}`,
    );
  }

  createApplication(
    application: NewCourseApplication,
  ): Observable<CourseApplication> {
    const username = this.authService.getUsername();
    return this.http.post<CourseApplication>(
      `${environment.apiDomain}/user/${username}/applications`,
      application,
    );
  }

  completeApplication(id: string) {
    const username = this.authService.getUsername();
    return this.http.put<CourseApplication>(
      `${environment.apiDomain}/user/${username}/applications/${id}/status`,
      { status: 'COMPLETE' },
    );
  }

  // AGENTS SERVICES
  getAgents(type: string, query: any = {}, display: any = {}): Observable<any> {
    let params = {};
    Object.assign(params, query, display);

    return this.http.get<any>(
      `${environment.userApiDomain}/v1/user?type=studypath_${type}`,
      { params: params },
    );
  }

  // ADMIN REFERRALS
  getUsersReferrals(
    types: any,
    query: any = {},
    display: any = {},
  ): Observable<any> {
    let params = {};

    Object.assign(params, query, types, display);

    return this.http.get<any>(`${environment.userApiDomain}/v1/user`, {
      params,
    });
  }

  getUsersReferralsConversions(
    referredByUserId: any = {},
    status: any = {},
    paging: any = {},
  ): Observable<any> {
    let params = {};
    Object.assign(params, referredByUserId, status, paging);

    return this.http.get<any>(
      `${environment.applicationApiDomain}/v1/applications`,
      { params },
    );
  }

  createAgent(data): Observable<any> {
    return this.http.post<any>(
      `${environment.authApiDomain}/v1/register`,
      data,
    );
  }

  updateAgentInfo(agentId: string, agentPayload: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      `${environment.userApiDomain}/v1/user/${agentId}`,
      { ...agentPayload },
    );
  }

  createAgentBusiness(data, overrideUserId?: string): Observable<any> {
    const userId = overrideUserId ?? this.authService.getUserId();
    return this.http.post<any>(
      `${environment.userApiDomain}/v1/user/${userId}/agent-business`,
      data,
    );
  }

  getAgentBusiness(userId: string) {
    return this.http.get<any>(
      `${environment.userApiDomain}/v1/user/${userId}/agent-business`,
    );
  }

  updateAgentBusiness(agentBusinessId: string, agentBusinessPayload: any) {
    return this.http.put<any>(
      `${environment.userApiDomain}/v1/user/agent-business/${agentBusinessId}`,
      { ...agentBusinessPayload },
    );
  }

  updateAgentBusinessStatus(userId: string, status: string) {
    return this.http.put<any>(
      `${environment.userApiDomain}/v1/user/${userId}/agent-business/status`,
      { status },
    );
  }

  getAgentContracts(userId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.userApiDomain}/v1/user/${userId}/agent-contracts`,
    );
  }

  updateAgentContracts(
    userId: string,
    agentContractRules: any,
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.userApiDomain}/v1/user/${userId}/agent-contracts`,
      { agentContractRules },
    );
  }
}
