import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-base-sidebar',
  templateUrl: './base-sidebar.component.html',
  styleUrls: ['./base-sidebar.component.scss'],
})
export class BaseSidebarComponent implements OnInit {
  @Input() isLoggedIn: boolean = false;
  @Input() userFullName: string;
  @Input() userEmail: string;
  @Input() userAvatar: string;

  @Input() productOptions = [];
  @Input() partnerOptions = [];

  @Output() closeTriggered = new EventEmitter();

  currentScreenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  selectedSubMenu: string = '';
  selectedCurrency: any;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {}

  get getMenuTitle() {
    if (this.selectedSubMenu === 'product') return 'Products';
    else if (this.selectedSubMenu === 'partner') return 'Partner with us';
    else return '';
  }

  get getMenuOptions() {
    if (this.selectedSubMenu === 'product') return this.productOptions;
    else if (this.selectedSubMenu === 'partner') return this.partnerOptions;
    else return [];
  }

  get isMobileView() {
    return this.currentScreenWidth < 860 ? true : false;
  }

  get isOperativeAgent() {
    return this.authService.getUserRole() === UserRole.Agent;
  }

  closeSidePanel() {
    this.closeTriggered.emit();
  }

  changeMenuView(menuItem: string = '') {
    this.selectedSubMenu = menuItem;
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  getPageUrl() {
    return new URL(document.URL).pathname;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.currentScreenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }
}
