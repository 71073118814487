<div class="base-sidebar-wrapper">
  <div class="sidebar-space ht-100" (click)="closeSidePanel()"></div>

  <div class="base-sidebar">
    <!-- CLOSE TRIGGER -->
    <div
      class="close-trigger smooth-transition pointer rounded-45"
      (click)="closeSidePanel()"
    >
      <mat-icon class="pave-text">clear</mat-icon>
    </div>

    <ng-container *ngIf="!selectedSubMenu; else baseMenuOptions">
      <!-- USER INFO -->
      <div class="user-info-row mgb-16">
        <div class="user-image-wrapper">
          <img
            *ngIf="userAvatar !== 'null'"
            [src]="userAvatar | presignedView | async"
            [alt]="userFullName"
          />
          <mat-icon *ngIf="userAvatar === 'null'">person</mat-icon>
        </div>

        <div class="user-info" *ngIf="isLoggedIn">
          <div class="user-name pave-text">{{ userFullName }}</div>
          <div class="user-email footer-fil">{{ userEmail }}</div>
        </div>

        <div class="pave-text f-size-15-5" *ngIf="!isLoggedIn">
          Hello, there!
        </div>
      </div>

      <!-- NAV ITEMS -->
      <div class="nav-item-list mgb-24">
        <ng-container *ngIf="false">
          <app-base-sidebar-navitem
            *ngIf="isMobileView"
            navTitle="Home"
          ></app-base-sidebar-navitem>
        </ng-container>

        <app-base-sidebar-navitem
          *ngIf="isLoggedIn && isOperativeAgent"
          navTitle="Dashboard"
          linkList="/operative-agent/dashboard"
        ></app-base-sidebar-navitem>

        <app-base-sidebar-navitem
          *ngIf="isLoggedIn"
          navTitle="My Applications"
          linkList="/user/applications"
        ></app-base-sidebar-navitem>

        <app-base-sidebar-navitem
          *ngIf="isLoggedIn"
          navTitle="Profile"
          linkList="/user/profile/personal"
        ></app-base-sidebar-navitem>

        <ng-container *ngIf="false">
          <app-base-sidebar-navitem
            *ngIf="isMobileView"
            navTitle="Products"
            [menuOptions]="productOptions"
            (showMenuItem)="changeMenuView('product')"
          ></app-base-sidebar-navitem>
        </ng-container>

        <ng-container *ngIf="false">
          <app-base-sidebar-navitem
            *ngIf="isMobileView"
            navTitle="About"
          ></app-base-sidebar-navitem>
        </ng-container>

        <ng-container *ngIf="false">
          <app-base-sidebar-navitem
            *ngIf="isMobileView"
            navTitle="Community"
          ></app-base-sidebar-navitem>
        </ng-container>

        <ng-container *ngIf="false">
          <app-base-sidebar-navitem
            *ngIf="isMobileView"
            navTitle="Partner with us"
            [menuOptions]="partnerOptions"
            (showMenuItem)="changeMenuView('partner')"
          ></app-base-sidebar-navitem>
        </ng-container>

        <ng-container *ngIf="false">
          <app-base-sidebar-navitem
            *ngIf="isLoggedIn"
            navTitle="Referrals and Earnings"
          ></app-base-sidebar-navitem>
        </ng-container>
      </div>

      <!-- REFERRAL BLOCK -->
      <ng-container *ngIf="false">
        <app-base-sidebar-referral-block
          *ngIf="isLoggedIn"
        ></app-base-sidebar-referral-block>
      </ng-container>

      <ng-container *ngIf="!isLoggedIn">
        <div class="mgt-24 pdl-20 pdr-20">
          <button
            class="pave-btn pave-btn-primary-outline mgb-16 wt-100"
            [routerLink]="['/', 'session', 'log-in']"
            [queryParams]="{ returnUrl: getPageUrl() }"
          >
            Login
          </button>

          <button
            class="pave-btn pave-btn-primary wt-100"
            [routerLink]="['/', 'session', 'sign-up']"
            [queryParams]="{ returnUrl: getPageUrl() }"
          >
            Register
          </button>
        </div>
      </ng-container>

      <!-- CHANGE USER LOCATION -->
      <div
        class="country-select-wrapper mgt-24 mgb-12"
        [ngClass]="!isLoggedIn && 'place-bottom'"
      >
        <app-currency-selector [dark]="true"></app-currency-selector>
      </div>

      <div class="nav-item-list mgb-12" *ngIf="isLoggedIn">
        <app-base-sidebar-navitem
          navTitle="Logout"
          navStyle="error"
          (menuClicked)="logout()"
        ></app-base-sidebar-navitem>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #baseMenuOptions>
  <app-base-sidebar-menu
    [optionTitle]="getMenuTitle"
    [optionData]="getMenuOptions"
    (onBackBtnClick)="changeMenuView()"
  ></app-base-sidebar-menu>
</ng-template>
