<div class="band" fxLayout="row">
  <div fxFlex *ngIf="hasSetStudentScope">
    <div class="welcome-banner" fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center" ngClass.gt-md="desktop">
      <div class="welcome-banner-content" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px" style="position: relative">
          <mat-progress-spinner class="with-track" fxHide.lt-md mode="determinate" [value]="percentageComplete" [diameter]="64" [strokeWidth]="4" color="primary"></mat-progress-spinner>
          <div class="success" fxHide.lt-md style="font-size: 14px; font-weight: 600; position: absolute; text-align: center; width: 64px">{{percentageComplete | number:'0.0-0'}}%</div>
          <div fxLayout="column" fxLayoutGap="8px">
            <div ngClass.gt-sm="text-web-title-1-m" ngClass.lt-lg="text-mobile-title-1-m">
              {{
                studentId ? 
                'Editing ' + personalInformation?.firstName + ' ' +  personalInformation?.lastName: 
                'Welcome, ' + personalInformation?.firstName + '!'
              }}
            </div>
            <div class="text-mobile-smallest-r" style="max-width: 480px">A complete profile helps us process your applications faster, and allows us make better program and career recommendations for you</div>
          </div>
        </div>
      </div>
      <button mat-flat-button style="background: transparent; color: inherit" fxLayoutAlign="start" fxHide.gt-md (click)="openSidebarDialog()">
        <mat-icon style="margin-right: 8px">menu</mat-icon>
        {{ getPageName(activeFragment | async) }}
      </button>
    </div>

    <div fxHide.gt-md fxLayout="row" fxLayoutAlign="end center" style="height: 24px; top: 64px; position: sticky; z-index: 90;">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center"  style=" padding: 0px 16px; background: #fafaff;">
        <mat-spinner *ngIf="saving" diameter="16" strokeWidth="2" color="primary"></mat-spinner>
        <div *ngIf="saving" class="text-mobile-smallest-r">Saving</div>
        <mat-icon *ngIf="saved" class="success" style="font-size: 16px; width: 16px; height: 16px">check_circle</mat-icon>
        <div *ngIf="saved" class="text-mobile-smallest-r">Saved</div>
      </div>
    </div>

    <div class="information-banner" fxLayout="row" fxLayoutGap="64px" ngStyle.gt-md="padding-top: 32px">
      <div fxFlex="20" class="sidebar" fxHide.lt-lg *ngIf="personal && academic && documents">
        <app-profile-sidebar #sidebar (nodeClicked)="scrollToFragment($event)"></app-profile-sidebar>
      </div>

      <div fxFlex>
        <mat-card fxHide.gt-md class="no-card">
          <mat-card-content>
            <router-outlet (activate)="subscribeToReload($event)" (deactivate)="unsubscribe()"></router-outlet>
          </mat-card-content>
        </mat-card>

        <div fxHide.lt-lg fxLayout="column" fxLayoutGap="64px">
          <app-personal [studentId]="studentId" #personal id="personalInformation" (reloaded)="sectionReloaded('Personal Information','personal')"></app-personal>
          <app-academic [studentId]="studentId" #academic id="academicInformation" (reloaded)="sectionReloaded('Academic Information','academic')"></app-academic>
          <app-documents [studentId]="studentId" #documents id="supportingDocuments" (reloaded)="sectionReloaded('Supporting Documents','documents')"></app-documents>
        </div>
      </div>
    </div>
  </div>
</div>

<br />
<br />
<br />
<br />
<br />

<ng-template #loading>
  <div class="loader" style="min-height: 500px;"></div>
</ng-template>