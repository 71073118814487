import { HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { last, map, tap } from 'rxjs/operators';
import { UserRole } from 'src/app/models/api/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UploadService } from 'src/app/services/upload/upload.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  type: any;
  docs: any[];
  userId: string;
  
  uploading = false;
  changed = false;

  get canUpload() {
    return this.type.multi || this.docs?.length === 0;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private uploadService: UploadService,
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.userId = data.userId;
    this.type = data.type;
    this.docs = data.docs;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.docs.length === 0) {
      this.fileInput.nativeElement.click();
    }
  }  

  fileChange(files: File[]) {
    if (files.length === 0) {
      return;
    }

    for(const file of files) {
      this.uploading = true;
      const tempId = Math.round(Math.random() * 100_000);
      this.docs.push({tempId, fileName: file.name, type: this.type.stub, uploading: true, progress: 0});

      this.uploadService.uploadFileV2(file, tempId, this.userId)
        .pipe(
          tap(status => {
            this.docs.find(d => d.tempId === tempId).progress = status.progress;
          }),
          last()
        )
        .subscribe(
          response => {          
            this.userService.updateUserDocument(this.type.stub, file.name, response.uri, this.userId).subscribe(
              d => {
                this.uploading = false;
                const doc = this.docs.find(d => d.tempId === tempId);
                doc.uploading = false;
                doc.progress = 100;
                doc.id = d.id;
                doc.sourceUri = d.sourceUri;
              },
              error => {
                this.uploading = false;
              });
          },
          error => {
            // file.inProgress = false;
            // file.canRetry = true;
            console.error(error);
            this.uploading = false;
          }
        );
    }
  }

  deleteFile(documentId) {
    this.userService.removeUserDocument(documentId, this.userId).subscribe(_ => {
      this.docs = this.docs.filter(d => d.id !== documentId);      
      this.changed = true;
    });
  }

  previewFile(doc) {
    if (doc.sourceUri) {
      const privilegedUserRoles = [UserRole.Admin.toString(), UserRole.Agent.toString()];
      const userId = privilegedUserRoles.includes(this.authService.getUserRole()) ? this.userId : undefined;

      this.uploadService.getPreSignedUrl('get', doc.fileName, userId).subscribe(response => {
        window.open(response.url, '_blank');
      });
    }
  }

  close() {
    const anyUploading = this.docs.some(d => d.uploading === true);
    if (anyUploading) {
      console.log('something is uploading!');
    } else {
      this.dialogRef.close();
    }
  }

}
