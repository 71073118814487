import { Component, EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { CustomNotificationSnackbarComponent } from 'src/app/modules/shared/custom-notification-snackbar/custom-notification-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public applicationAdded: EventEmitter<any>;
  public saving: EventEmitter<any>;

  constructor(private snackBar: MatSnackBar) {
    this.applicationAdded = new EventEmitter();
    this.saving = new EventEmitter();
  }

  showError(message: string, duration: number=5000, actionLabel: string=null): Observable<void> {
    return this.snackBar.openFromComponent(CustomNotificationSnackbarComponent, {
      data: {
        message,
        actionLabel,
        type: 'error'
      },
      panelClass: 'panel-error',
      duration,
    }).onAction();
  }

  showSuccess(message: string, duration: number=5000, actionLabel: string=null): Observable<void> {
    return this.snackBar.openFromComponent(CustomNotificationSnackbarComponent, {
      data: {
        message,
        actionLabel,
        type: 'success'
      },
      panelClass: 'panel-success',
      duration,
    }).onAction();
  }

  showInfo(message: string, duration: number=5000, actionLabel: string=null): Observable<void> {
    return this.snackBar.openFromComponent(CustomNotificationSnackbarComponent, {
      data: {
        message,
        actionLabel,
        type: 'info'
      },
      panelClass: 'panel-info',
      duration,
    }).onAction();
  }

  broadcastApplicationAdded() {
    this.applicationAdded.emit();
  }

  broadcastSaving() {
    this.saving.emit(true);
  }

  broadcastSaved() {
    this.saving.emit(false);
  }
}
