import { Component, OnInit, Input } from '@angular/core';

type menuStackType = {
  navTitle: string;
  navDescription: string;
  navLink: string;
};

type menuOptionsType = {
  title?: string;
  options: menuStackType[];
};

@Component({
  selector: 'app-base-topbar-menu',
  templateUrl: './base-topbar-menu.component.html',
  styleUrls: ['./base-topbar-menu.component.scss'],
})
export class BaseTopbarMenuComponent implements OnInit {
  @Input() menuPayload: menuOptionsType;

  constructor() {}

  ngOnInit(): void {}
}
