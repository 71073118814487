<div [fxHide]="loaded" class="loader" style="min-height: 400px;"></div>
<div [fxHide]="!loaded" fxLayout="column" fxLayoutGap="64px">
    <section fxLayout="column" fxLayoutGap="16px" [ngClass]="{'hidden-anchor': unUploadedDocTypes.length === 0}">
        <mat-card-subtitle class="text-web-subtitle-m" [ngClass]="{'hidden-anchor': unUploadedDocTypes.length === 0}" #anchor>Unuploaded Documents</mat-card-subtitle>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="0px" fxLayoutGap.lt-md="16px" ngClass.gt-sm="grid">
            <ng-container *ngFor="let d of unUploadedDocTypes">
                <!-- <div *ngIf="docs.length === 0" #anchor>{{d.name}}</div> -->
                <div mat-ripple class="pave-card pave-card--lighter pave-card--dashed" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign=" start" (click)="openUploadDialog(d, [])">
                    <div class="row-one text-mobile-medium-m">{{d.name}}</div>
                    <button mat-button color="primary" class="pave-button" style="padding: 0px">
                        <mat-icon>add_circle_outlined</mat-icon>
                        <span style="margin-left: 8px">Add Document</span>
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="16px" [ngClass]="{'hidden-anchor': uploadedDocTypes.length === 0}">
        <mat-card-subtitle class="text-web-subtitle-m" [ngClass]="{'hidden-anchor': uploadedDocTypes.length === 0}" #anchor>Uploaded Documents</mat-card-subtitle>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="0px" fxLayoutGap.lt-md="16px" ngClass.gt-sm="grid">
            <ng-container *ngFor="let d of uploadedDocTypes">
                <ng-container *ngIf="getDocuments(d.stub) as docs">
                    <!-- <div *ngIf="docs.length > 0" #anchor>{{d.name}}</div> -->
                    <div mat-ripple class="pave-card pave-card--darker" fxLayout="column" fxLayoutGap="16px" (click)="openUploadDialog(d, docs)">
                        <div class="row-one text-mobile-medium-m" fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{d.name}}</div>
                            <mat-icon>more_vert</mat-icon>
                        </div>
                        <div class="row-two text-mobile-smallest-r">
                            {{docs.length}} file{{docs.length > 1 ? 's' : ''}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </section>
</div>