import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-notification-snackbar',
  templateUrl: './custom-notification-snackbar.component.html',
  styleUrls: ['./custom-notification-snackbar.component.scss']
})
export class CustomNotificationSnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {message: string, actionLabel: string, type: string},
    @Inject(MatSnackBarRef) private snackBarRef: MatSnackBarRef<any>
  ) { }

  ngOnInit() { }

  doAction() {
    this.snackBarRef.dismissWithAction();
  }

  close() {
    this.snackBarRef.dismiss();
  }

}
